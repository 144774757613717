import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { Location } from '@angular/common'

//services 
import {CategoriesService} from '../../services/categories.service';
import {pipeService} from '../../services/pipe.service';
import {ClientService} from '../../services/client.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-activities-new-categories',
  templateUrl: './activities-new-categories.component.html',
  styleUrls: ['./activities-new-categories.component.scss']
})
export class ActivitiesNewCategoriesComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  customers: {};
  dataActivitiesType :{};
  dataCountries :{};
  dataModules :{};
  dataCustomers :{};
  verSeleccion: string = '';
  verSeleccion1: string = ''; 
  idActivity:string; 
  selectedFile:File = null;
  selectedFiles:{}; 
    
  
    constructor(private router : Router,private activRouter: ActivatedRoute ,private formBuilder : FormBuilder, private getServicesActivity :  CategoriesService , 
      private servPipe : pipeService,private pipeServ : pipeService, private  location: Location, private servClient : ClientService) { 
  
      this.activRouter.params.subscribe(res =>{
        this.idActivity = res['id'];
      })
     }

    ngOnInit() {
  
      this.pipeServ.getContry()
        .subscribe(res => {
        this.dataCountries = res;
        console.log(res)
        },
        err => console.log(err)
      )
      this.pipeServ.getActivityType()
        .subscribe(res => {
        this.dataActivitiesType = res;
        console.log(res)
        },
        err => console.log(err)
      )
      this.servClient.getClients()
        .subscribe(res => {
        this.dataCustomers = res;
        console.log(res)
        },
        err => console.log(err)
      )
      this.pipeServ.getModule(this.idActivity)
        .subscribe(res => {
        this.dataModules = res;
        console.log(res)
        },
        err => console.log(err)
      )
      this.registerForm = this.formBuilder.group({
        name: ['', Validators.required],//name        
        //client_id: ['', Validators.required],//cliente
        type_activity_id: ['', Validators.required],//tipo pregunta
        url_resource: [''],//url recurso        
        module_id: ['', Validators.required], 
        pilar_id: ['', Validators.required],//pilar
        description: ['', Validators.required], //description
        client_id: ['', Validators.required],
    });
    
}
  //habilitar campos según tipo de actividad
  onOptionsSelected(valorseleccionado:string){
    this.verSeleccion1 = '';
    this.verSeleccion = valorseleccionado;
    console.log("the selected value is " + valorseleccionado);
  } 

  onOptionsSelected1(valorseleccionado1:string){
    this.verSeleccion = '';
    this.verSeleccion1 = valorseleccionado1;
    console.log("the selected value is " + valorseleccionado1);
  }
  //Enviar por POST los dato del usuario
  postRegisterActivity(dataActivity:{}, file){
    console.log('Datos', dataActivity);
    this.getServicesActivity.postActivity(this.idActivity,dataActivity,file)
    .subscribe(
      res => {
        console.log(res)
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate(['/home-default/category/activities/',this.idActivity]));
      },
      err => console.log('Que paso ',err)
    )
  }

  get f() { return this.registerForm.controls;}

  onFileSelected(files: FileList){
    this.selectedFile = files.item(0);
  }

  onSubmit() {
    this.submitted = true;

    //deténgase aquí si el formulario no es válido
    if (this.registerForm.invalid) {
        return;
    }

    
    var formdata = new FormData();
    formdata.append("name", this.registerForm.value.name);
    formdata.append("description", this.registerForm.value.description);
    formdata.append("client_id", this.registerForm.value.client_id);
    formdata.append("type_activity_id", this.registerForm.value.type_activity_id);
    formdata.append("url_resource", this.selectedFile, this.registerForm.value.url_resource);
    formdata.append("pilar_id", this.registerForm.value.pilar_id);
    formdata.append("module_id", this.registerForm.value.module_id);

    this.postRegisterActivity(formdata, formdata)
    console.log('datos enviados',this.registerForm.value)
    //this.location.back();     
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.location.back();
  }
}
