import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CourseService} from '../../services/course.service';
import {course} from '../../models/course.model';

@Component({
  selector: 'app-course-category',
  templateUrl: './course-category.component.html'
})
export class CourseCategoryComponent implements OnInit {
  idCategory : string;
  dataCourse:{};

  constructor(private servCourse : CourseService, private actvRouter: ActivatedRoute) {
    this.actvRouter.params.subscribe(res =>{ this.idCategory = res['id']})
   }

  ngOnInit() {
    
    this.servCourse.getCoursesCotegory(this.idCategory)
    .subscribe(res=>{
      this.dataCourse = res;
      console.log(res)
    })
  }
  
  Delete(course:course){
      //this.servCourse.deleteCourse(course.id)
  }

}
