import {NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {LoginComponent } from './content/auth/login/login.component';
import {IndexComponent } from './content/index/index.component';
import {HomeDefaultComponent } from './content/components/home-default/home-default.component';
import {ClientComponent } from './content/components/client/client.component';
import {ListComponent } from "./content/components/client/list/list.component";
import {NewComponent } from './content/components/client/new/new.component';
import {ClientCategorieComponent } from './content/components/client/client-categorie/client-categorie.component';
import {CategoriesComponent} from './content/components/categories/categories.component';
import {NewCategoriesComponent } from './content/components/categories/new-categories/new-categories.component';
import {EditComponent } from './content/components/client/edit/edit.component';
import {UserListComponent } from './content/components/user/user-list/user-list.component';
import {UserNewComponent } from "./content/components/user/user-new/user-new.component";
import {UserEditComponent } from './content/components/user/user-edit/user-edit.component';
import {SettingComponent} from './content/components/client/setting/setting.component';
import {EditCategoryComponent} from './content/components/categories/edit-category/edit-category.component';
import {UserCategoriesComponent} from './content/components/categories/user-categories/user-categories.component';
import {CourseCategoryComponent} from './content/components/categories/course-category/course-category.component';
import {CourseNewCategoryComponent} from './content/components/categories/course-category/course-new-category/course-new-category.component'
import {CourseEditCategoryComponent } from './content/components/categories/course-category/course-edit-category/course-edit-category.component'
import {EnrollmentNewUserComponent } from './content/components/enrollments/enrollment-new-user/enrollment-new-user.component';
import {EnrollmentUserComponent } from './content/components/enrollments/enrollment-user/enrollment-user.component';
import {ActivitiesNewCategoriesComponent} from './content/components/categories/activities-new-categories/activities-new-categories.component';
import {ActivitiesEditCategoriesComponent} from './content/components/categories/activities-edit-categories/activities-edit-categories.component';
import {ModuleCategoriesComponent} from './content/components/categories/module-categories/module-categories.component';
import {ModuleNewCategoriesComponent} from './content/components/categories/module-new-categories/module-new-categories.component';
import {ModuleEditCategoriesComponent} from './content/components/categories/module-edit-categories/module-edit-categories.component';
import {ActivitiesCategoriesComponent} from './content/components/categories/activities-categories/activities-categories.component';
import {ActivitiesRouteCategoriesComponent} from './content/components/categories/activities-route-categories/activities-route-categories.component';
import {RouteDynamicComponent} from './content/components/categories/route-dynamic/route-dynamic.component';
import {BancoActividadesListComponent} from './content/components/banco-actividades/banco-actividades-list/banco-actividades-list.component';
import {BancoActividadesNewComponent} from './content/components/banco-actividades/banco-actividades-new/banco-actividades-new.component';
import {BancoActividadesEditComponent} from './content/components/banco-actividades/banco-actividades-edit/banco-actividades-edit.component';
import {BancoActividadesAssignmentComponent} from './content/components/banco-actividades/banco-actividades-assignment/banco-actividades-assignment.component';
import {BankListComponent} from './content/components/bank-question/bank-list/bank-list.component';
import {BankEditComponent} from './content/components/bank-question/bank-edit/bank-edit.component';
import {BankNewComponent} from './content/components/bank-question/bank-new/bank-new.component';
import {ActivitiesEvaluateListComponent} from './content/components/categories/activities-evaluate-list/activities-evaluate-list.component';
import {ActivitiesEvaluateComponent} from './content/components/categories/activities-evaluate/activities-evaluate.component';
import {QuestionListComponent} from './content/components/bank-question/question-list/question-list.component';
import {QuestionEditComponent} from './content/components/bank-question/question-edit/question-edit.component';
import {QuestionNewComponent} from './content/components/bank-question/question-new/question-new.component';
import {EvaluationConfigComponent} from './content/components/categories/evaluation-config/evaluation-config.component';


// LayoutsQuestionListComponent

import { PresentationLayoutComponent } from './layout-blueprints/presentation-layout/presentation-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
    {
    path: '',
    component: PresentationLayoutComponent,

    children: [
      { path: '', component: LoginComponent, pathMatch: 'full'  },
    ]
    },
    { path: 'login', component: LoginComponent },
    { path: 'home-default', component: HomeDefaultComponent, children:[
      //client
      { path: '', component: ListComponent },
      { path: 'list/new', component: NewComponent },
      { path: 'new', component: NewComponent },
      { path: 'list', component: ListComponent },
      { path: 'edit/:id', component: EditComponent },
      { path: 'list/edit/:id', component: EditComponent },
      { path: 'setting/:id', component: SettingComponent },
      { path: 'list/setting/:id', component: SettingComponent },
      

      //user
      { path: 'user-new', component: UserNewComponent },
      { path: 'user-list', component: UserListComponent },
      { path: 'user-list/user-new', component: UserNewComponent },
      { path: 'user-edit/:id', component: UserEditComponent },
      { path: 'user-list/user-edit/:id', component: UserEditComponent },


      { path: 'client', component: ClientComponent },

      //category
      { path: 'category', component: CategoriesComponent },
      { path: 'new-category', component: NewCategoriesComponent },
      { path: 'category/new-category', component: NewCategoriesComponent },
      { path: 'edit-category/:id', component: EditCategoryComponent},
      { path: 'category/edit-category/:id', component: EditCategoryComponent },
      { path: 'category/activities/:id/new', component: ActivitiesNewCategoriesComponent },
      { path: 'category/activities/edit/:id', component: ActivitiesEditCategoriesComponent },
      { path: 'category/modules/:id', component: ModuleCategoriesComponent },
      { path: 'category/modules/:id/new', component: ModuleNewCategoriesComponent },
      { path: 'category/modules/:id/edit', component: ModuleEditCategoriesComponent },
      { path: 'category/activities/:id', component: ActivitiesCategoriesComponent },
      { path: 'category/route/:id', component: ActivitiesRouteCategoriesComponent },
      { path: 'category/route-dynamic/:id', component: RouteDynamicComponent },
      { path: 'evaluate/:id', component: ActivitiesEvaluateListComponent },
      { path: 'evaluate/check/:id', component: ActivitiesEvaluateComponent },

      //Banco actividades

      { path: 'banco-actividades', component: BancoActividadesListComponent },
      { path: 'banco-actividades/new', component: BancoActividadesNewComponent },
      { path: 'banco-actividades/edit/:id', component: BancoActividadesEditComponent },
      { path: 'banco-actividades/assignment/:id', component: BancoActividadesAssignmentComponent },

      //Banco preguntas

      { path: 'bank', component: BankListComponent },
      { path: 'bank/new', component: BankNewComponent }, 
      { path: 'bank/edit/:id', component: BankEditComponent },
      { path: 'bank/question/:id', component: QuestionListComponent },
      { path: 'bank/question/new/:id', component: QuestionNewComponent },
      { path: 'bank/question/edit/:id', component: QuestionEditComponent },

      //category user
      { path: 'category/user-categories/:id', component: UserCategoriesComponent },
      { path: 'category/course-categories/:id', component: CourseCategoryComponent },
      { path: 'category/course-categories/:id/new-course', component: CourseNewCategoryComponent },
      { path: 'category/course-categories/:id/edit-course/:id', component: CourseEditCategoryComponent },

      //configuración evaluación
      { path: 'evaluation/:id', component: EvaluationConfigComponent },

      //enrrollments
      { path: 'new-enrollments', component: EnrollmentNewUserComponent },
      { path: 'enrollments', component: EnrollmentUserComponent },

      //Dashboard
      { path: 'dashboard', component: DashboardComponent },

    ],
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, 
    { 
      useHash : true, //para subir  ng build --prod
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
