<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-plugin"  titleHeading='Módulos de la ruta'></app-page-title>
                </div>
                <div class="card-header--actions">
                    <a routerLink="new" class="btn btn-sm btn-primary" tooltip="Crear módulo">
                        <fa-icon [icon]="['fas', 'plus']"></fa-icon>  Crear módulo
                    </a>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table table-hover table-striped mb-0" *ngIf="hayRegistros(); else sinregistros">
                        <thead class="thead-light">
                        <tr>
                          <th style="width: 15vw;">Módulo</th> 
                          <th style="width: 50vw;">Descripción</th>
                          <th class="text-center">Opciones</th>
                        </tr>
                        </thead>
                        <tbody>

                            <tr *ngFor="let module of dataModules;">
                                <td class="pl-4">
                                    <div class="d-flex align-items-center">
                                        <!--<div class="avatar-icon-wrapper mr-2">
                                            <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/>
                                            </div>
                                        </div>-->
                                        <div>
                                            <a [routerLink]="" class="font-weight-bold text-black" title="...">{{module.name}}</a>
                                            <!--<span class="text-black-50 d-block">Frontend Developer</span>-->
                                        </div>
                                    </div>
                                </td>                                
                                <td>
                                    <span class="h-auto py-0" style="width: 60vw;">{{module.description }}</span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group btn-group-sm" dropdown placement="bottom right" container="body">
                                        <button class="btn btn-sm px-2 py-0 dropdown-toggle no-caret btn-primary"
                                                dropdownToggle type="button">
                                            <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
                                        </button>
                                        <div *dropdownMenu class="dropdown-menu dropdown-menu-xl p-0 dropdown-menu-right">
                                            <ul class="nav nav-neutral-primary nav-pills flex-column pt-2 pb-3">
                                                <li class="nav-item nav-item--header px-3">
                                                    <span class="text-capitalize text-black font-size-md font-weight-bold">Mis acciones</span>
                                                </li>
                                                <li class="dropdown-divider"></li>
                                                <li class="nav-item px-3">
                                                    <a [routerLink]="['../',module.id,'edit']" class="nav-link active">
                                                        <div class="nav-link-icon">
                                                            <!--<i class="ion-ios-user-edit font-size-xxl"></i>-->
                                                            <i-feather name="edit-3"></i-feather>
                                                        </div>
                                                        <span>Editar módulo</span>
                                                    </a>
                                                </li>                                                
                                                <li class="dropdown-divider"></li>
                                                <li class="nav-item">
                                                    <a type="submit"  (click)="Delet(module)" class="nav-link text-danger mx-3">
                                                        <div class="nav-link-icon">
                                                            <fa-icon [icon]="['fas', 'times']"></fa-icon>
                                                        </div>
                                                        <span>Eliminar módulo</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <ng-template #sinregistros><h5 class="m-5">No hay módulos para esta ruta</h5></ng-template>
                </div>
                <div class="divider"></div>
                <div class="divider"></div>
                <div class="p-3 d-flex justify-content-center" *ngIf="hayRegistros()">
                    <pagination  [totalItems]="30"
                                class="pagination-icons pagination-primary"></pagination>
                </div>
            </div>
        </div>
    </div>
</div>
