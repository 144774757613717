import { Component, OnInit } from '@angular/core';
import {BankService} from '../../services/bank-questions.service'
import { Location } from '@angular/common'
import {Router} from '@angular/router';

@Component({
  selector: 'app-bank-list',
  templateUrl: './bank-list.component.html',
  styleUrls: ['./bank-list.component.scss']
})
export class BankListComponent implements OnInit {

  Banks:{};  
  total : any[];

  constructor(private servicesBank : BankService, private  location: Location,private router : Router) { }

  ngOnInit() { 
    this.servicesBank.getBanks()
    .subscribe((res: any[]) =>{
      this.Banks = res,
      this.total = res; 
      console.log(res);
    })
  }

  hayRegistros() {
    return this.total.length>0;              
  }

  Delet(bank){ 
    this.servicesBank.deletBank(bank.id)
    .subscribe(res=>{
      console.log(res);
      alert('Se ha eliminado con exito');
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(['/home-default/bank'])); 
    },
    err=>console.log(err));
  }
}
