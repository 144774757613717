import { Component, OnInit } from '@angular/core';
import {CategoriesService} from '../services/categories.service'

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html'
})
export class CategoriesComponent implements OnInit {
  Categories:{};

  constructor( private servicesCategory : CategoriesService) { }

  ngOnInit() {
    this.servicesCategory.getCategories()
    .subscribe(res =>{
      this.Categories = res,
      console.log(res);
    })
  }

  Delet(category){
    this.servicesCategory.deletcategory(category.id)
    .subscribe(res=>{
      console.log(res);
      alert('Se ha eliminado con exito.');
      location. reload(true);
    },
    err=>console.log(err));
  }

}
