<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-note2"  titleHeading='Evaluar actividad'></app-page-title>
                </div>
                <div class="card-header--actions">
                    <a (click)="onReset()" class="btn btn-sm btn-primary text-white" tooltip="Volver">
                        <div class="nav-link-icon pe-7s-back"></div>  Volver
                    </a>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table table-hover table-striped mb-0" *ngIf="hayRegistros(); else sinregistros">
                        <thead class="thead-light">
                        <tr>
                          <th style="width: 80vw;">Estudiante</th>
                          <th style="width: 20vw;"></th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let activity of Activities;">
                                <td class="pl-4">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <span>{{activity.name}}</span>
                                        </div>
                                    </div>
                                </td>                                
                                <td class="text-center">
                                    <a [routerLink]="['../check' , activity.id]" class="btn btn-sm btn-primary" tooltip="Evaluar actividad">
                                        <div class="nav-link-icon pe-7s-note"></div>  Evaluar
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ng-template #sinregistros><h5 class="m-5">No hay registros para evaluar</h5></ng-template>
                </div>
                <div class="divider"></div>
                <div class="divider"></div>
                <div class="p-3 d-flex justify-content-center">
                    <pagination  [totalItems]="30"
                                class="pagination-icons pagination-primary"></pagination>
                </div>
            </div>
        </div>
    </div>
</div>
