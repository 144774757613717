<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-note" titleHeading='Crear módulo'></app-page-title>
                </div>                    
            </div>
            <div class="row ml-0 mr-0">
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="p-5 col-xl-12">
                    <div class="form-row">
                        <!--name activity-->
                        <div class="form-group col-6">
                            <label>Nombre módulo</label>
                            <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Este campo esta vacio</div>
                            </div>
                        </div>  
                        <!--descripcion-->
                        <div class="form-group col-12">
                            <label>Descripción</label>
                            <textarea formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                <div *ngIf="f.description.errors.required">Este campo esta vacio</div>
                            </div>
                        </div>
                    </div>        
                    <div class="text-center mt-6">
                        <button class="btn btn-primary mr-1">Crear módulo</button>
                        <button class="btn btn-secondary" type="reset" (click)="onReset()">Volver</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
