<div class="user-box position-relative ml-2" dropdown>
  <a dropdownToggle
          [routerLink]=""
          class="p-0 d-flex align-items-center"
  >
    <div class="d-block p-0 avatar-icon-wrapper">
      <span class="badge badge-circle badge-success p-top-a">Online</span>
      <div class="avatar-icon rounded">
        <img src="assets/images/avatars/avatar7.jpg" alt="..." />
      </div>
    </div>
    <div class="d-none d-xl-block pl-2">
      <div class="font-weight-bold">
        Nombre usuario
      </div>
      <span class="text-black-50">
        Cargo usuario
      </span>
    </div>
    <span class="pl-1 pl-xl-3"
    ><fa-icon [icon]="['fas', 'angle-down']" class="opacity-5"></fa-icon
    ></span>
  </a>
  <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg p-0">
    <ul class="list-group list-group-flush text-left bg-transparent">
      <li class="list-group-item rounded-top">
        <ul class="nav nav-neutral-primary nav-pills flex-column">
          <li class="nav-header d-flex text-primary pt-1 pb-2 font-weight-bold align-items-center">
            <div>
              Opciones de perfil
            </div>
            <div class="ml-auto font-size-xs">
              <a [routerLink]="" tooltip="Configuración de perfil">
                <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
              </a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="">
              Mi cuenta
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="">
              Configuración
            </a>
          </li>
          <!--<li class="nav-item">
            <a class="nav-link" [routerLink]="">
              Active tasks
            </a>
          </li>-->
          <li class="nav-item">
            <a class="nav-link" [routerLink]="">
              Cerrar sesión
            </a>
          </li>
        </ul>
      </li>
      <!--<li class="list-group-item bg-transparent p-0">
        <div class="grid-menu grid-menu-2col">
          <div class="py-3">
            <div class="d-flex justify-content-center">
              <div class="d-flex align-items-center">
                <div>
                  <fa-icon
                          [icon]="['far', 'chart-bar']"
                          class="font-size-xxl text-info"
                  ></fa-icon>
                </div>
                <div class="pl-3 line-height-sm">
                  <b class="font-size-lg">$9,693</b>
                  <span class="text-black-50 d-block">revenue</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>-->
      <!--<li class="list-group-item rounded-bottom p-3 text-center">
        <a [routerLink]="" class="btn btn-facebook" tooltip="Facebook">
        <span class="btn-wrapper--icon">
          <fa-icon [icon]="['fab', 'facebook']"></fa-icon>
        </span>
        </a>
        <a [routerLink]="" class="btn btn-dribbble mr-2 ml-2" tooltip="Dribbble">
        <span class="btn-wrapper--icon">
          <fa-icon [icon]="['fab', 'dribbble']"></fa-icon>
        </span>
        </a>
        <a [routerLink]="" class="btn btn-twitter" tooltip="Twitter">
        <span class="btn-wrapper--icon">
          <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
        </span>
        </a>
      </li>-->
    </ul>
  </div>
</div>
