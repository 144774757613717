<app-page-title titleDescription='This is a dashboard page example built using this template.' titleHeading='Cursos'></app-page-title>

<app-example-wrapper-simple sectionHeading="Crear un curso">

  <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">

    <div class="col-xl-12">
        <div class="text-center mb-5">
            <div class="avatar-icon-wrapper rounded-circle mx-auto">
                <div class="d-block p-0 avatar-icon-wrapper rounded-circle m-0 border-3 border-first">
                    <div class="rounded-circle border-3 border-white overflow-hidden">
                        <img alt="..." style="width: 120px;" class="img-fluid" src="assets/images/avatars/unnamed.png"/>
                    </div>
                </div>
            </div>
            <p>Subir una imagen</p>
                <div class="image-upload">
                <label for="file-input" class="btn d-40 p-0 btn-pill m-2 btn btn-second">
                    <span class="btn-wrapper--icon">
                        <fa-icon [icon]="['fas','plus']"></fa-icon>
                    </span>
                </label>     
                <input id="file-input" formControlName="url_image" (change)="onFileSelected($event)" type="file" style="display: none;" [ngClass]="{ 'is-invalid': submitted && f.url_image.errors }"/>
                <div *ngIf="submitted && f.url_image.errors" class="invalid-feedback">
                    <div *ngIf="f.url_image.errors.required">Esta cambo esta vacio</div>
                </div>
                
            </div>
        </div>
    </div>

      <div class="form-row">
          <!--name-->
          <div class="form-group col-12">
              <label>Nombre del curso</label>
              <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                  <div *ngIf="f.name.errors.required">Esta cambo esta vacio</div>
              </div>
          </div>
          
          <!--client_id-->
          <div class="form-group col-6">
              <label>Cliente</label>
              <select class="form-control" formControlName="client_id" [ngClass]="{ 'is-invalid': submitted && f.client_id.errors }">
                  <option value="">Seleccionar</option>
                  <option [value]="client.id" *ngFor="let client of dataClient">{{client.Client}}</option>
                </select>
                <div *ngIf="submitted && f.client_id.errors" class="invalid-feedback">
                    <div *ngIf="f.client_id.errors.required">Esta cambo esta vacio</div>
                </div>
            </div> 
            
            <!--modules_order_enabled-->
            <div class="form-group col-6">
                <label>Habilitar Cursos según Orden</label>
                <select class="form-control" formControlName="modules_order_enabled" [ngClass]="{ 'is-invalid': submitted && f.modules_order_enabled.errors }">
                    <option value="1" >Activo</option>
                    <option value="0" >Inactivo</option>
                </select>
                <div *ngIf="submitted && f.modules_order_enabled.errors" class="invalid-feedback">
                    <div *ngIf="f.modules_order_enabled.errors.required">Esta cambo esta vacio</div>
                </div>
            </div>
            
            <!--description-->
            <div class="form-group col-12">
                <label>Descripcion</label>
                <!---->
                <textarea class="form-control" formControlName="description" id="exampleText" name="text"[ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                    <div *ngIf="f.description.errors.required">Esta cambo esta vacio</div>
                </div>
            </div>
            
        </div>
        
        
        <div class="form-group form-check">
            <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />
            <label for="acceptTerms" class="form-check-label">Accept Terms & Conditions</label>
            <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Accept Ts & Cs is required</div>
        </div>
        <div class="text-center">
            <button class="btn btn-primary mr-1">Register</button>
          <button class="btn btn-secondary" type="reset" (click)="onReset()">Cancel</button>
      </div>
  </form>

</app-example-wrapper-simple>
