import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiURL = environment.apiURL;
  postAuth : string;

  constructor(private httpAuth : HttpClient ) { 
    this.postAuth = `${this.apiURL}/api/auth/login`;
  }

  authLogin(dataLogin:{}){
    return this.httpAuth.post(`${this.postAuth}`, dataLogin)
  }

  getToken(){
    return localStorage.getItem('');
  }

  settokendataCliente(dataLogin:{}):void{
    let user_tring = JSON.stringify(dataLogin)
    localStorage.setItem("current",user_tring);
  }

  LogountCliente(){
    localStorage.removeItem("current");
    localStorage.removeItem("accessToken");
  }

  setTokenClient(token):void{
    localStorage.setItem("accessToken", token);
  }



}
