import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import {environment} from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class enrollService {

  baseUrl = environment.apiURL;
  urlEnroll : string;
  country : string;
  urlDeletEnrroll : string;
  urlEnrollModule : string;

  constructor(private http : HttpClient) {
    this.urlEnroll = `${this.baseUrl}/api/categorie/users`;
    this.urlDeletEnrroll = `${this.baseUrl}/api/user/categories/delete`;
    this.urlEnrollModule = `${this.baseUrl}/api/user/categories`;
  }

  getEnrollUserCategory(id){
    return this.http.get(`${this.urlEnroll}/${id}`);
  }

  deleteEnroll(id){
    return this.http.get(`${this.urlDeletEnrroll}/${id}`);
  }

  postEnrollModule(iduser , idCategory){
    return this.http.post(`${this.urlEnrollModule}/${idCategory}`, iduser)
  }

}
