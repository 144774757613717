<div >
    <div class="d-flex justify-content-between px-4 py-3">
      <div class="d-flex align-items-center">
        <span>ver</span>
        <select class="mx-1 form-control form-control-sm" id="" name="">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
        </select>
        <span>Estudiantes</span>
      </div>
      <div class="search-wrapper">
            <span class="icon-wrapper text-black">
              <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </span>
        <input class="form-control form-control-sm rounded-pill" placeholder="Search terms..." type="search">
      </div>
    </div>
    <div class="divider"></div>
    <div class="table-responsive">
      <table class="table table-hover text-nowrap mb-0">
        <thead>
        <tr>
          <th class="bg-white text-left">ID</th>
          <th class="bg-white">Nombre</th>
          <th class="bg-white text-left">Correo</th>
          <th class="bg-white text-center">Status</th>
          <th class="bg-white text-center">Opciones</th>
        </thead>
        <tbody>
        <tr *ngFor="let user of dataUser">
          <td class="font-weight-bold">
            {{user.id}}
          </td>
          <td>
            <div class="d-flex align-items-center">
              <div class="avatar-icon-wrapper avatar-icon-sm mr-2">
                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
              </div>
              <div>
                {{user.firstname}} {{user.lastname}} 
              </div>
            </div>
          </td>
          <td>
            {{user.email}}
          </td>
          <td class="text-center">
            <button class="btn btn-sm btn-link" tooltip="Status usuario" type="button">
                <span class="badge  h-auto py-0 px-3 {{user.status == 0 ? 'badge-danger' : 'badge-success'}}">{{user.status == 0 ? 'INACTIVO' : 'ACTIVO'}}</span>
            </button>
          </td>
          <td class="text-center">
            <button class="btn m-2 btn-pill btn-success" type="button" (click)="enrollModule(user.id)">
                Agregar a la ruta
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer py-3 d-flex justify-content-between">
      
      <div class="d-flex align-items-center">
        <span>Show</span>
        <select class="mx-1 form-control form-control-sm" id="" name="">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
        </select>
        <span>entries</span>
      </div>
    </div>
  </div>