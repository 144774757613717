<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-way" titleHeading='Configurar ruta dinámica'></app-page-title>
                </div>                    
            </div>
            <div class="row ml-0 mr-0">
                <h4 class="ml-4 mt-5 col-xl-12">Actividades evaluativas de la ruta</h4>
                <div class="card-body p-0 col-xl-6">
                    <div class="example-list col-sm-12">
                        <div *ngFor="let activity of dataList">
                            <div class="box-route" *ngIf="activity.type_activity == 'Cuestionario' || activity.type_activity == 'Tarea'">
                                <div class="example-box">
                                    {{activity.type_activity}} - {{activity.name}} 
                                    <a (click)="exampleModal.show();activityCategory(activity.category_activity_id)">
                                        <span class="pe-7s-config font-weight-bold s-i"></span>
                                    </a>
                                </div>
                                <!--Modal-->
                                <div bsModal #exampleModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h6 class="modal-title">Configuración actividad en ruta dinámica</h6>
                                                <button type="button" class="close" aria-label="Close" (click)="exampleModal.hide();activityCategoryF()">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <!--Form create-->
                                            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="pt-5 pl-5 pr-5 col-xl-12" *ngIf="formDinamic">
                                                <div class="modal-body">                                                                    
                                                    <div class="col-12 form-group form-row"> 
                                                        <h5 class="col-12">Actividad: <b>{{activity.name}}</b></h5>
                                                        <div class="col-1 form-group form-row">  
                                                            <button containerClass="tooltip-secondary" class="btn mt-3 ml-2 btn-secondary" type="button" tooltip="En este campo ingrese la calificación mínima"><i class="ion-md-help"></i></button>
                                                        </div>
                                                        <div class="col-5 input-group mt-3 mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">Calificación mínima</span>
                                                            </div>
                                                            <input formControlName="min_average_score" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.min_average_score.errors }" type="number">
                                                            <div *ngIf="submitted && f.min_average_score.errors" class="invalid-feedback">
                                                                <div *ngIf="f.min_average_score.errors.required">Este campo esta vacio</div>
                                                            </div>
                                                        </div> 
                                                        <div class="col-1 form-group form-row">  
                                                            <button containerClass="tooltip-secondary" class="btn mt-3 ml-2 btn-secondary" type="button" tooltip="En este campo ingrese la calificación alta"><i class="ion-md-help"></i></button>
                                                        </div> 
                                                        <div class="col-5 input-group mt-3 mb-3">
                                                            <div class="input-group-prepend"><span class="input-group-text">Calificación alta</span></div>
                                                            <input formControlName="max_average_score" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.max_average_score.errors }" type="number"> 
                                                            <div *ngIf="submitted && f.max_average_score.errors" class="invalid-feedback">
                                                                <div *ngIf="f.max_average_score.errors.required">Este campo esta vacio</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 form-group form-row">
                                                        <label class="col-sm-3 col-form-label font-weight-bold" for="low_activity_id">Calificación baja</label>
                                                        <div class="col-sm-9 form-row">
                                                            <button containerClass="tooltip-secondary" class="col-sm-1 ml-3 mr-3 btn btn-secondary" type="button" tooltip="Seleccione la actividad a la que accederia el usuario en caso que obtenga una calificación menor a la mínima"><i class="ion-md-help"></i></button>
                                                            <select class="form-control col-sm-10" id="low_activity_id" formControlName="low_activity_id" [ngClass]="{ 'is-invalid': submitted && f.low_activity_id.errors }">
                                                                <option value="">Seleccione actividad</option>
                                                                <option [value]="activity.category_activity_id" *ngFor="let activity of dataList">{{activity.name}}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="submitted && f.low_activity_id.errors" class="invalid-feedback">
                                                            <div *ngIf="f.low_activity_id.errors.required">Este campo esta vacio</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 form-group form-row">
                                                        <label class="col-sm-3 col-form-label font-weight-bold" for="medium_activity_id">Calificación media</label>
                                                        <div class="col-sm-9 form-row">                                                        
                                                            <button containerClass="tooltip-secondary" class="col-sm-1 ml-3 mr-3 btn btn-secondary" type="button" tooltip="Seleccione la actividad a la que accederia el usuario en caso que obtenga una calificación igual o mayor a la mínima y menor a la alta"><i class="ion-md-help"></i></button>
                                                            <select class="form-control col-sm-10" id="medium_activity_id" formControlName="medium_activity_id" [ngClass]="{ 'is-invalid': submitted && f.medium_activity_id.errors }">
                                                                <option value="">Seleccione actividad</option>
                                                                <option [value]="activity.category_activity_id" *ngFor="let activity of dataList">{{activity.name}}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="submitted && f.medium_activity_id.errors" class="invalid-feedback">
                                                            <div *ngIf="f.medium_activity_id.errors.required">Este campo esta vacio</div>
                                                        </div>
                                                    </div> 
                                                    <div class="col-12 form-group form-row">
                                                        <label class="col-sm-3 col-form-label font-weight-bold" for="high_activity_id">Calificación alta</label> 
                                                        <div class="col-sm-9 form-row">                                                         
                                                            <button containerClass="tooltip-secondary" class="col-sm-1 ml-3 mr-3 btn btn-secondary" type="button" tooltip="Seleccione la actividad a la que accederia el usuario en caso que obtenga una calificación igual o mayor a la alta"><i class="ion-md-help"></i></button>
                                                            <select class="form-control col-sm-10" id="high_activity_id" formControlName="high_activity_id" [ngClass]="{ 'is-invalid': submitted && f.high_activity_id.errors }">
                                                                <option value="">Seleccione actividad</option>
                                                                <option [value]="activity.category_activity_id" *ngFor="let activity of dataList">{{activity.name}}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="submitted && f.high_activity_id.errors" class="invalid-feedback">
                                                            <div *ngIf="f.high_activity_id.errors.required">Este campo esta vacio</div>
                                                        </div>
                                                    </div>                                                
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-link btn-link-dark" (click)="exampleModal.hide();activityCategoryF()">Cerrar</button>
                                                    <button class="btn btn-primary ml-auto">Guardar configuración</button>
                                                </div>
                                            </form>
                                            <!--End form create-->
                                            <!--Form edit-->
                                            <form [formGroup]="registerFormEdit" (ngSubmit)="onSubmitEdit()" class="pt-5 pl-5 pr-5 col-xl-12" *ngIf="formEdit">
                                                <div class="modal-body">                                                                    
                                                    <div class="col-12 form-group form-row"> 
                                                        <h5 class="col-12">Editar actividad: <b>{{activity.name}}</b></h5>
                                                        <div class="col-1 form-group form-row">  
                                                            <button containerClass="tooltip-secondary" class="btn mt-3 ml-2 btn-secondary" type="button" tooltip="En este campo ingrese la calificación mínima"><i class="ion-md-help"></i></button>
                                                        </div>
                                                        <div class="col-5 input-group mt-3 mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text">Calificación mínima</span>
                                                            </div>
                                                            <input formControlName="min_average_score" class="form-control" [ngClass]="{ 'is-invalid': submittedEdit && fe.min_average_score.errors }" type="number">
                                                            <div *ngIf="submittedEdit && fe.min_average_score.errors" class="invalid-feedback">
                                                                <div *ngIf="fe.min_average_score.errors.required">Este campo esta vacio</div>
                                                            </div>
                                                        </div> 
                                                        <div class="col-1 form-group form-row">  
                                                            <button containerClass="tooltip-secondary" class="btn mt-3 ml-2 btn-secondary" type="button" tooltip="En este campo ingrese la calificación alta"><i class="ion-md-help"></i></button>
                                                        </div> 
                                                        <div class="col-5 input-group mt-3 mb-3">
                                                            <div class="input-group-prepend"><span class="input-group-text">Calificación alta</span></div>
                                                            <input formControlName="max_average_score" class="form-control" [ngClass]="{ 'is-invalid': submittedEdit && fe.max_average_score.errors }" type="number"> 
                                                            <div *ngIf="submittedEdit && fe.max_average_score.errors" class="invalid-feedback">
                                                                <div *ngIf="fe.max_average_score.errors.required">Este campo esta vacio</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 form-group form-row">
                                                        <label class="col-sm-3 col-form-label font-weight-bold" for="low_activity_id">Calificación baja</label>
                                                        <div class="col-sm-9 form-row">
                                                            <button containerClass="tooltip-secondary" class="col-sm-1 ml-3 mr-3 btn btn-secondary" type="button" tooltip="Seleccione la actividad a la que accederia el usuario en caso que obtenga una calificación menor a la mínima"><i class="ion-md-help"></i></button>
                                                            <select class="form-control col-sm-10" id="low_activity_id" formControlName="low_activity_id" [ngClass]="{ 'is-invalid': submittedEdit && fe.low_activity_id.errors }">
                                                                <option value="">Seleccione actividad</option>
                                                                <option [value]="activity.category_activity_id" *ngFor="let activity of dataList">{{activity.name}}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="submittedEdit && fe.low_activity_id.errors" class="invalid-feedback">
                                                            <div *ngIf="fe.low_activity_id.errors.required">Este campo esta vacio</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 form-group form-row">
                                                        <label class="col-sm-3 col-form-label font-weight-bold" for="medium_activity_id">Calificación media</label>
                                                        <div class="col-sm-9 form-row">                                                        
                                                            <button containerClass="tooltip-secondary" class="col-sm-1 ml-3 mr-3 btn btn-secondary" type="button" tooltip="Seleccione la actividad a la que accederia el usuario en caso que obtenga una calificación igual o mayor a la mínima y menor a la alta"><i class="ion-md-help"></i></button>
                                                            <select class="form-control col-sm-10" id="medium_activity_id" formControlName="medium_activity_id" [ngClass]="{ 'is-invalid': submittedEdit && fe.medium_activity_id.errors }">
                                                                <option value="">Seleccione actividad</option>
                                                                <option [value]="activity.category_activity_id" *ngFor="let activity of dataList">{{activity.name}}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="submittedEdit && fe.medium_activity_id.errors" class="invalid-feedback">
                                                            <div *ngIf="fe.medium_activity_id.errors.required">Este campo esta vacio</div>
                                                        </div>
                                                    </div> 
                                                    <div class="col-12 form-group form-row">
                                                        <label class="col-sm-3 col-form-label font-weight-bold" for="high_activity_id">Calificación alta</label> 
                                                        <div class="col-sm-9 form-row">                                                         
                                                            <button containerClass="tooltip-secondary" class="col-sm-1 ml-3 mr-3 btn btn-secondary" type="button" tooltip="Seleccione la actividad a la que accederia el usuario en caso que obtenga una calificación igual o mayor a la alta"><i class="ion-md-help"></i></button>
                                                            <select class="form-control col-sm-10" id="high_activity_id" formControlName="high_activity_id" [ngClass]="{ 'is-invalid': submittedEdit && fe.high_activity_id.errors }">
                                                                <option value="">Seleccione actividad</option>
                                                                <option [value]="activity.category_activity_id" *ngFor="let activity of dataList">{{activity.name}}</option>
                                                            </select>
                                                        </div>
                                                        <div *ngIf="submittedEdit && fe.high_activity_id.errors" class="invalid-feedback">
                                                            <div *ngIf="fe.high_activity_id.errors.required">Este campo esta vacio</div>
                                                        </div>
                                                    </div>                                                
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-link btn-link-dark" (click)="exampleModal.hide();activityCategoryF()">Cerrar</button>
                                                    <button class="btn btn-primary ml-auto">Guardar configuración</button>
                                                </div>
                                            </form>
                                            <!--End form edit-->
                                            <div class="card rounded-sm card-box shadow-none p-3 m-3 text-center" *ngIf="loader">
                                                <div style="width: 100%">
                                                    <sk-cube-grid [color]="'#3c44b1'"></sk-cube-grid>
                                                    <p class="mb-0 pt-3 text-black-50">Cargando...</p>
                                                </div>
                                            </div>
                                            <alert class="alerts-alternate ml-5 mr-5 mt-5 mb-3" dismissible="true" type="success" *ngIf="notice">
                                                <div class="d-flex align-items-center align-content-start">
                                                    <span class="font-size-lg d-block d-40 mr-3 text-center bg-success text-white rounded-sm">
                                                        <fa-icon [icon]="['far', 'object-group']"></fa-icon>
                                                    </span>
                                                    <span>
                                                        <strong class="d-block">Success!</strong> Configuración de actividad guardada correctamente!
                                                    </span>
                                                </div>
                                            </alert>
                                            <alert class="alerts-alternate ml-5 mr-5 mt-5 mb-3" dismissible="true" type="success" *ngIf="delete">
                                                <div class="d-flex align-items-center align-content-start">
                                                    <span class="font-size-lg d-block d-40 mr-3 text-center bg-success text-white rounded-sm">
                                                        <fa-icon [icon]="['far', 'object-group']"></fa-icon>
                                                    </span>
                                                    <span>
                                                        <strong class="d-block">Success!</strong> Configuración de actividad eliminada correctamente!
                                                    </span>
                                                </div>
                                            </alert>
                                            <div class="ml-5 mr-5" *ngIf="notice">
                                                <h3 class="mb-3">Actividad {{activity.name}}</h3>
                                                <p><strong>Calificación mínima: </strong>{{min}}</p>  
                                                <p><strong>Calificación alta: </strong>{{max}}</p>
                                                <div *ngFor="let activity of dataList"> 
                                                    <p *ngIf="activity.category_activity_id == low"><strong>Actividad calificación baja: </strong>{{activity.name}}</p>
                                                </div>
                                                <div *ngFor="let activity of dataList">
                                                    <p *ngIf="activity.category_activity_id == medium"><strong>Actividad calificación media: </strong>{{activity.name}}</p> 
                                                </div>
                                                <div *ngFor="let activity of dataList">
                                                    <p *ngIf="activity.category_activity_id == high"><strong>Actividad calificación alta: </strong>{{activity.name}}</p>
                                                </div>
                                                <button (click)="dynamicEdit()" class="mt-3 mb-3 mr-3 btn btn-primary ml-auto">Editar configuración</button>
                                                <button (click)="dynamicDelete(dynamic_id)" class="mt-3 ml-3 mb-3 btn btn-danger ml-auto">Eliminar configuración</button>
                                            </div>
                                            <alert class="alerts-alternate m-5" dismissible="true" type="danger" *ngIf="warning">
                                                <div class="d-flex align-items-center align-content-start">
                                                    <span class="font-size-lg d-block d-40 mr-3 text-center bg-danger text-white rounded-sm">
                                                        <fa-icon [icon]="['far', 'keyboard']"></fa-icon>
                                                    </span>
                                                    <span>
                                                        <strong class="d-block">Error {{statuserror}} - {{warningerror}}</strong> No se pudo configurar la actividad!
                                                    </span>
                                                </div>
                                            </alert>
                                            <div class="modal-footer" *ngIf="notice || warning || delete">
                                                <button type="button" class="btn btn-link btn-link-dark" (click)="exampleModal.hide();activityCategoryF()">Cerrar</button>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                   
                </div>
                <div class="col-xl-4 offset-xl-1 mt-3 text-center">
                    <div class="card card-box-alt card-border-top border-first mb-5 pb-4">
                        <h3 class="font-size-lg font-weight-bold mt-5 mb-4">Importante!</h3>
                        <p class="card-text px-4 mb-4">
                            Configure cada actividad con la calificación mínima y la calificación alta.
                        </p> 
                        <p class="card-text px-4 mb-4">
                            Recuerde que también puede configurar la actividad a la que el usuario accedería según el resultado de la actividad evaluativa. 
                        </p>                       
                    </div>
                    <button class="btn btn-primary mr-1 mb-5" (click)="onReset()">Volver</button>
                </div>                
            </div>
        </div>
    </div>
</div>