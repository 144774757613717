
<app-page-title titleHeading='Cursos'></app-page-title>

<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <small>Tables</small>
                </div>
                <div class="card-header--actions">
                    <a routerLink="new-course" class="btn btn-sm btn-primary" tooltip="Dummy button">
                        <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                    </a>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table table-hover table-striped mb-0">
                        <thead class="thead-light">
                        <tr>
                          <th style="width: 40%;">Cursos</th>
                          <th class="text-center">Descripcion</th>
                          <th class="text-center">Opciones</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let course of dataCourse;">
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-icon-wrapper mr-2">
                                            <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/>
                                            </div>
                                        </div>
                                        <div>
                                            <a [routerLink]="" class="font-weight-bold text-black" title="...">{{course.name}}</a>
                                            <span class="text-black-50 d-block">Frontend Developer</span>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <span class="h-auto py-0 px-3" style=" white-space: unset;  width: 60vw;">{{course.description }}</span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group btn-group-sm" dropdown placement="bottom right" container="body">
                                        <button class="btn btn-sm px-2 py-0 dropdown-toggle no-caret btn-primary"
                                                dropdownToggle type="button">
                                            <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
                                        </button>
                                        <div *dropdownMenu class="dropdown-menu dropdown-menu-xl p-0 dropdown-menu-right">
                                            <ul class="nav nav-neutral-primary nav-pills flex-column pt-2 pb-3">
                                                <li class="nav-item nav-item--header px-3">
                                                    <span class="text-capitalize text-black font-size-md font-weight-bold"> Menu</span>
                                                </li>
                                                <li class="dropdown-divider"></li>
                                                <li class="nav-item px-3">
                                                    <a [routerLink]="['edit-course',course.id]" class="nav-link active">
                                                        <div class="nav-link-icon">
                                                            <!--<i class="ion-ios-user-edit font-size-xxl"></i>-->
                                                            <i-feather name="edit-3"></i-feather>
                                                        </div>
                                                        <span>Editar</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="divider"></div>
                <div class="divider"></div>
                <div class="p-3 d-flex justify-content-center">
                    <pagination  [totalItems]="30"
                                class="pagination-icons pagination-primary"></pagination>
                </div>
            </div>
        </div>
    </div>
</div>
