import {Component, OnInit} from '@angular/core';

import {ClientService} from "../../services/client.service";

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

import * as moment from 'moment'

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html'
})
export class SettingComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  bsInlineValue = new Date();
  maxDate = new Date();
  bsRangeValue: Date[];
  bsValue = new Date();
  typeOfLicenses:{};
  dataCliente:{};
  idClient: string;
  DateStartLicenses:any;
  dataTypeDocuments : {};
  dataCountries : {};

  constructor( private actvRouter : ActivatedRoute ,private getServicesClient : ClientService, private formBuilder: FormBuilder ) {
    this.actvRouter.params.subscribe(res =>{ this.idClient = res['id']})
  }

  ngOnInit() {

    //api type linceses
    this.getServicesClient.getTypeLicences()
    .subscribe(
      res => {
        this.typeOfLicenses = res;
      },
      err => console.log(err)
    )

    this.registerForm = this.formBuilder.group({   
        licence_type_id: ['', Validators.required],
        startLicense: ['', Validators.required],
        acceptTerms: [false, Validators.requiredTrue]
    });
}

get f() { return this.registerForm.controls; }

//Enviar por POST los datos del Clienete
renoverLicence(dataLicence:{}){
  this.getServicesClient.renovateLicence(this.idClient, dataLicence)
  .subscribe(res=>{console.log(res)},
  err => console.log(err))
}

onSubmit() {
  this.submitted = true;

  //deténgase aquí si el formulario no es válido
  if (this.registerForm.invalid) {
    return;
  }

  this.dataCliente = this.registerForm.value;
  
  //Body de formulario
  interface Datacliente{
    name : string,
    licence_type_id :  number,
    startLicense : Date
  }
  
  let dateCliente : Datacliente = this.registerForm.value;
  let sumMonth = dateCliente.licence_type_id * 3;
  
  let datesa = moment(dateCliente.startLicense).format("YYYY-MM-DD")
  let datesa2 = moment(dateCliente.startLicense).add(sumMonth,"month").format("YYYY-MM-DD")
  dateCliente['start_date'] = datesa;
  dateCliente['end_date'] = datesa2;
  
  console.log(this.registerForm.value);
  this.renoverLicence(this.registerForm.value);
}

onReset() {
      this.submitted = false;
      this.registerForm.reset();
  }

}
