import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'

import {ClientService} from '../../services/client.service';
import {UserService} from '../../../components/services/user.service';
import {pipeService} from '../../services/pipe.service';
import {Router } from '@angular/router'
import {CategoriesService} from '../../services/categories.service'

@Component({
  selector: 'app-evaluation-config',
  templateUrl: './evaluation-config.component.html',
  styleUrls: ['./evaluation-config.component.scss']
})
export class EvaluationConfigComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  opcion:boolean=false;
  multiple:boolean=false;
  fv:boolean=false;
  texto:boolean=false;
  toggle = true;
  toggle1 = true;
  toggle2 = true;
  toggle3 = true;
  toggleN = false;
  toggleN1 = false;
  toggleN2 = false;
  toggleN3 = false;
  idActivity:string;
  dataActivities :{};

  constructor(private router : Router,private servClient : ClientService ,private formBuilder : FormBuilder, private getServicesUser :  UserService , 
      private servPipe : pipeService,private pipeServ : pipeService,private servicesCategory : CategoriesService) { }

  ngOnInit() {
    this.servicesCategory.getActivities(this.idActivity)
      .subscribe(res => {
      this.dataActivities = res;
      console.log(res)
      },
      err => console.log(err)
    )
  }

  opcionFunction(){
    this.opcion=true;
    this.multiple=false;
    this.fv=false;
    this.texto=false; 
    this.toggle = false; 
    this.toggle1 = true; 
    this.toggle2 = true; 
    this.toggle3 = true; 
    this.toggleN = true;
    this.toggleN1 = false;
    this.toggleN2 = false;
    this.toggleN3 = false;   
  }

  multipleFunction(){  
    this.opcion=false;
    this.multiple=true;
    this.fv=false;
    this.texto=false;
    this.toggle = true;
    this.toggle1 = false; 
    this.toggle2 = true; 
    this.toggle3 = true; 
    this.toggleN = false;
    this.toggleN1 = true;
    this.toggleN2 = false;
    this.toggleN3 = false;
  }

  fvFunction(){
    this.opcion=false;
    this.multiple=false;
    this.fv=true;
    this.texto=false;
    this.toggle = true; 
    this.toggle1 = true; 
    this.toggle2 = false; 
    this.toggle3 = true;
    this.toggleN = false;
    this.toggleN1 = false;
    this.toggleN2 = true;
    this.toggleN3 = false;
  }

  textoFunction(){
    this.opcion=false;
    this.multiple=false;
    this.fv=false;
    this.texto=true;
    this.toggle = true; 
    this.toggle1 = true; 
    this.toggle2 = true; 
    this.toggle3 = false;
    this.toggleN = false;
    this.toggleN1 = false;
    this.toggleN2 = false;
    this.toggleN3 = true;
  }

  limpiarFunction(){
    this.opcion=false;
    this.multiple=false;
    this.fv=false;
    this.texto=false;
  }

  //Enviar por POST los dato del usuario
  postRegisterUser(datauser:{}){
    this.getServicesUser.postCliente(datauser)
    .subscribe(
      res => {
        console.log(res)
      },
      err => console.log(err)
    )
  }

  get f() { return this.registerForm.controls; }
  
  onSubmit() {
    this.submitted = true;
  
    //deténgase aquí si el formulario no es válido
    if (this.registerForm.invalid) {
        return;
    }
  
    this.postRegisterUser(this.registerForm.value)
    console.log(this.registerForm.value)
    this.router.navigate(['/home-default/']);
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.router.navigate(['/home-default']);
  }

  Delet(activity){
    this.servicesCategory.deletactivity(activity.id)
    .subscribe(res=>{
      console.log(res);
      alert('Se ha eliminado con exit..');
      location. reload(true);
    },
    err=>console.log(err));
  }

}
