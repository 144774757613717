<app-page-title iIconDescription="pe-7s-note" titleHeading='Matriculas'></app-page-title>

<app-example-wrapper-simple sectionHeading="Matricular usuario a ruta"> 

    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-row">

            <!--Ruta-->
            <div class="form-group col-6">
              <label>Ruta</label>
              <select class="form-control" formControlName="client_id" [ngClass]="{ 'is-invalid': submitted && f.client_id.errors }">
                <option value="" >Seleccionar</option>
                <option value="1" *ngFor="let client of customers">{{client.Client}}</option>
              </select>
              <div *ngIf="submitted && f.client_id.errors" class="invalid-feedback">
                  <div *ngIf="f.client_id.errors.required">Este campo esta vacio</div>
              </div>
            </div>

            <!--typeIdenficationCumber-->
            <div class="form-group col-6">
              <label>Usuario</label>
              <select class="form-control" formControlName="typeIdenficationCumber" [ngClass]="{ 'is-invalid': submitted && f.typeIdenficationCumber.errors }">
                <option value="" >Seleccionar</option>
                <option [value]="document.id" *ngFor="let document of dataTypeDocument">{{document.name}}</option>
              </select>
              <div *ngIf="submitted && f.typeIdenficationCumber.errors" class="invalid-feedback">
                  <div *ngIf="f.typeIdenficationCumber.errors.required">Este campo esta vacio</div>
              </div>
            </div>         
        </div>
        <div class="text-center">
            <button class="btn btn-primary mr-1">Matricular</button>
            <button class="btn btn-secondary" type="reset" (click)="onReset()">Volver</button>
        </div>
    </form>

</app-example-wrapper-simple>
