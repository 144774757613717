import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ClientService} from '../../../services/client.service';
import {CourseService} from '../../../services/course.service';
import {course} from'../../../models/course.model';

@Component({
  selector: 'app-course-edit-category',
  templateUrl: './course-edit-category.component.html'
})
export class CourseEditCategoryComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  selectedFile:File = null;
  dataClient:{};
  idCategory : string;


  constructor(private actvRouter : ActivatedRoute,private formBuilder : FormBuilder, private servClient :ClientService, 
    private  servCourse: CourseService) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      url_image: ['', Validators.required], 
      name: ['', Validators.required],
      description: ['', Validators.required],
      client_id: ['', Validators.required],
      modules_order_enabled: ['', Validators.required],  
      acceptTerms: [false, Validators.requiredTrue],
      status: 0
  });
  }

  get f() {return this.registerForm.controls;}

  onFileSelected(event){
    this.selectedFile = <File>event.target.files[0];
  }


  sendDataCourse(idCategory, dataCourse:{}, file:any){
    this.servCourse.postDataCourse(idCategory, dataCourse , file)
    .subscribe(res=>{
      console.log(res)
    },
    err => console.log(err)
  )};
  
  onSubmit() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    const file = new FormData();
    file.append('url_image', this.selectedFile, this.selectedFile.name);
    console.log('file content', this.selectedFile);
    console.log(this.selectedFile.name);
    this.sendDataCourse(this.idCategory ,this.registerForm.value, file)
    console.log(this.registerForm.value)
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }
}
