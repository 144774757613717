<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-note2"  titleHeading='Configuración cuestionario'></app-page-title>
                </div>
                <div class="card-header--actions">
                    <a (click)="exampleModal.show()" class="btn btn-sm btn-primary" tooltip="Agregar pregunta">
                        <fa-icon [icon]="['fas', 'plus']"></fa-icon> Agregar pregunta
                    </a>
                </div>
            </div>
            <div class="card-body p-0">
                <h5 class="ml-3 mt-3 mb-3 t-1">Preguntas del cuestionario</h5>
                <div class="table-responsive">
                    <table class="table table-hover table-striped mb-0">
                        <thead class="thead-light">
                        <tr>
                          <th style="width: 50vw;">Pregunta</th>
                          <th style="width: 20vw;">Tipo</th>
                          <th>Opciones</th>
                        </tr>
                        </thead>
                        <tbody>

                            <tr *ngFor="let activity of dataActivities;">
                                <td class="pl-4">
                                    <div class="d-flex align-items-center">
                                        <!--<div class="avatar-icon-wrapper mr-2">
                                            <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/>
                                            </div>
                                        </div>-->
                                        <div>
                                            <a [routerLink]="" class="font-weight-bold text-black" title="...">{{activity.name}}</a>
                                            <!--<span class="text-black-50 d-block">Frontend Developer</span>-->
                                        </div>
                                    </div>
                                </td>                                
                                <td>
                                    <span class="h-auto py-0" style="width: 60vw;">{{activity.type_activity}}</span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group btn-group-sm" dropdown placement="bottom right" container="body">
                                        <button class="btn btn-sm px-2 py-0 dropdown-toggle no-caret btn-primary"
                                                dropdownToggle type="button">
                                            <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
                                        </button>
                                        <div *dropdownMenu class="dropdown-menu dropdown-menu-xl p-0 dropdown-menu-right">
                                            <ul class="nav nav-neutral-primary nav-pills flex-column pt-2 pb-3">
                                                <li class="nav-item nav-item--header px-3">
                                                    <span class="text-capitalize text-black font-size-md font-weight-bold">Mis acciones</span>
                                                </li>
                                                <li class="dropdown-divider"></li>
                                                <li class="nav-item px-3">
                                                    <a [routerLink]="['edit',activity.id]" class="nav-link active">
                                                        <div class="nav-link-icon">
                                                            <!--<i class="ion-ios-user-edit font-size-xxl"></i>-->
                                                            <i-feather name="edit-3"></i-feather>
                                                        </div>
                                                        <span>Editar</span>
                                                    </a>
                                                </li>                                                
                                                <li class="dropdown-divider"></li>
                                                <li class="nav-item">
                                                    <a type="submit" (click)="Delet(activity)" class="nav-link text-danger mx-3">
                                                        <div class="nav-link-icon">
                                                            <fa-icon [icon]="['fas', 'times']"></fa-icon>
                                                        </div>
                                                        <span>Eliminar</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="divider"></div>
                <div class="divider"></div>
                <div class="p-3 d-flex justify-content-center">
                    <pagination  [totalItems]="30"
                                class="pagination-icons pagination-primary"></pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal-->
<div bsModal #exampleModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Crear pregunta</h6>
                <button type="button" class="close" aria-label="Close" (click)="exampleModal.hide();limpiarFunction()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body m-3">
                <h4 class="mt-3 mb-4">Seleccione tipo de pregunta</h4>
                <button (click)="opcionFunction()" [ngClass]="{'black-b' : toggle, 'gray-b': toggleN}" class="btn m-2 gray-b" type="button">
                    <span class="btn-wrapper--icon"> 
                        <i class="ion-md-options"></i>
                    </span>
                    <span class="btn-wrapper--label">Opción única</span>                    
                </button>
                <button (click)="multipleFunction()" [ngClass]="{'black-b' : toggle1, 'gray-b': toggleN1}" class="btn m-2 gray-b" type="button">
                    <span class="btn-wrapper--icon">
                        <i class="ion-md-options"></i>
                    </span>
                    <span class="btn-wrapper--label">Opción Multiple</span>                    
                </button>
                <button (click)="fvFunction()" [ngClass]="{'black-b' : toggle2, 'gray-b': toggleN2}" class="btn m-2 gray-b" type="button">
                    <span class="btn-wrapper--icon">
                        <i class="ion-md-options"></i>
                    </span>
                    <span class="btn-wrapper--label">Falso y verdadero</span>                    
                </button>
                <button (click)="textoFunction()" [ngClass]="{'black-b' : toggle3, 'gray-b': toggleN3}" class="btn m-2 gray-b" type="button">
                    <span class="btn-wrapper--icon">
                        <i class="ion-md-options"></i>
                    </span>
                    <span class="btn-wrapper--label">Texto</span>
                </button>
                <!--formulario opción-->
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="pt-3 pb-5 pl-5 pr-5" *ngIf="opcion">
                    <p class="app-page-title--description">Pregunta de única opción</p>
                    <div class="divider my-3"></div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Texto de la pregunta</label>
                            <textarea class="form-control" id="exampleText" name="text"></textarea>
                            <div *ngIf="submitted && f.pregunta.errors" class="invalid-feedback">
                                <div *ngIf="f.pregunta.errors.required">Este campo esta vacio</div>
                            </div>
                        </div> 
                        <div class="col-12 form-group"> 
                            <div class="input-group mt-3 mb-3">
                                <div class="input-group-prepend"><span class="input-group-text">Opción 1</span></div>
                                <input class="form-control" type="text">
                            </div>  
                            <div class="input-group mt-3 mb-3">
                                <div class="input-group-prepend"><span class="input-group-text">Opción 2</span></div>
                                <input class="form-control" type="text">
                            </div>  
                            <div class="input-group mt-3 mb-3">
                                <div class="input-group-prepend"><span class="input-group-text">Opción 3</span></div>
                                <input class="form-control" type="text">
                            </div>  
                            <div class="input-group mt-3 mb-3">
                                <div class="input-group-prepend"><span class="input-group-text">Opción 4</span></div>
                                <input class="form-control" type="text">
                            </div>
                        </div>   
                        <div class="col-12 form-group mb-5">
                            <label for="exampleCustomSelectDisabled">Seleccione la opción correcta</label>
                            <select class="custom-select" id="exampleCustomSelectDisabled" name="customSelect" type="select">
                                <option value="">Seleccione</option>
                                <option>Opción 1</option>
                                <option>Opción 2</option>
                                <option>Opción 3</option>
                                <option>Opción 4</option>
                            </select>
                        </div>
                        <div class="text-center mt-6">
                            <button class="btn btn-primary mr-1">Guardar pregunta</button>
                        </div>
                    </div>
                </form>
                <!--fin formulario opción-->
                <!--Formulario opción multiple-->
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="pt-3 pb-5 pl-5 pr-5" *ngIf="multiple">
                    <p class="app-page-title--description">Pregunta de varias opciónes de respuesta</p>
                    <div class="divider my-3"></div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Texto de la pregunta</label>
                            <textarea class="form-control" id="exampleText" name="text"></textarea>
                            <div *ngIf="submitted && f.pregunta.errors" class="invalid-feedback">
                                <div *ngIf="f.pregunta.errors.required">Este campo esta vacio</div>
                            </div>
                        </div> 
                        <div class="col-12 form-group"> 
                            <div class="input-group mt-3 mb-3">
                                <div class="input-group-prepend"><span class="input-group-text">Opción 1</span></div>
                                <input class="form-control" type="text">
                            </div>  
                            <div class="input-group mt-3 mb-3">
                                <div class="input-group-prepend"><span class="input-group-text">Opción 2</span></div>
                                <input class="form-control" type="text">
                            </div>  
                            <div class="input-group mt-3 mb-3">
                                <div class="input-group-prepend"><span class="input-group-text">Opción 3</span></div>
                                <input class="form-control" type="text">
                            </div>  
                            <div class="input-group mt-3 mb-3">
                                <div class="input-group-prepend"><span class="input-group-text">Opción 4</span></div>
                                <input class="form-control" type="text">
                            </div>
                        </div>   
                        <div class="position-relative form-group col-12 mb-5">
                            <h6 class="mb-4">Seleccione las respuestas correctas</h6>
                            <div>
                                <div class="custom-checkbox custom-control mb-3 custom-control-inline">
                                    <input class="custom-control-input" id="exampleCustomInline" type="checkbox">
                                    <label class="custom-control-label" for="exampleCustomInline">Opción 1</label>
                                </div>
                                <div class="custom-checkbox custom-control mb-3 custom-control-inline">
                                    <input class="custom-control-input" id="exampleCustomInline2" type="checkbox">
                                    <label class="custom-control-label" for="exampleCustomInline2">Opción 2</label>
                                </div>
                                <div class="custom-checkbox custom-control mb-3 custom-control-inline">
                                    <input class="custom-control-input" id="exampleCustomInline3" type="checkbox">
                                    <label class="custom-control-label" for="exampleCustomInline3">Opción 3</label>
                                </div>
                                <div class="custom-checkbox custom-control mb-3 custom-control-inline">
                                    <input class="custom-control-input" id="exampleCustomInline4" type="checkbox">
                                    <label class="custom-control-label" for="exampleCustomInline4">Opción 4</label>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-6">
                            <button class="btn btn-primary mr-1">Guardar pregunta</button>
                        </div>
                    </div>
                </form>
                <!--Formulario opción multiple-->
                <!--Formulario pregunta abierta-->
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="pt-3 pb-5 pl-5 pr-5" *ngIf="texto">
                    <p class="app-page-title--description">Pregunta de respuesta abierta</p>
                    <div class="divider my-3"></div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Texto de la pregunta</label>
                            <textarea class="form-control" id="exampleText" name="text"></textarea>
                            <div *ngIf="submitted && f.pregunta.errors" class="invalid-feedback">
                                <div *ngIf="f.pregunta.errors.required">Este campo esta vacio</div>
                            </div>
                        </div>                        
                        <div class="text-center mt-6">
                            <button class="btn btn-primary mr-1">Guardar pregunta</button>
                        </div>
                    </div>
                </form>
                <!--Formulario pregunta abierta-->
                <!--Formulario verdadero falso-->
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="pt-3 pb-5 pl-5 pr-5" *ngIf="fv">
                    <p class="app-page-title--description">Pregunta de respuesta verdadero / falso</p>
                    <div class="divider my-3"></div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Texto de la pregunta</label>
                            <textarea class="form-control" id="exampleText" name="text"></textarea>
                            <div *ngIf="submitted && f.pregunta.errors" class="invalid-feedback">
                                <div *ngIf="f.pregunta.errors.required">Este campo esta vacio</div>
                            </div>
                        </div>
                        <div class="col-12 form-group mb-5">
                            <label for="exampleCustomSelectDisabled">Seleccione la opción correcta</label>
                            <select class="custom-select" id="exampleCustomSelectDisabled" name="customSelect" type="select">
                                <option value="">Seleccione</option>
                                <option>Vedadero</option>
                                <option>Falso</option>
                            </select>
                        </div>
                        <div class="text-center mt-6">
                            <button class="btn btn-primary mr-1">Guardar pregunta</button>
                        </div>
                    </div>
                </form>
                <!--Formulario verdadero falso-->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-link btn-link-dark ml-auto" (click)="exampleModal.hide();onReset()">Cerrar</button>
            </div>            
        </div>
    </div>
</div>       
