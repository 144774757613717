import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import {environment} from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ClientService {
  baseUrl = environment.apiURL;
  urlClient;
  getTypeLicence;
  changeLicence : string;

  constructor(private httpClient : HttpClient,) {

    this.urlClient = `${this.baseUrl}/api/client`;
    this.getTypeLicence = `${this.baseUrl}/api/licence`;
    this.changeLicence = `${this.baseUrl}/api/client/billing`;
    
  }

  postClients(dataClient:{}){
    return this.httpClient.post(this.urlClient,dataClient)
  }

  getTypeLicences(){
    return this.httpClient.get(this.getTypeLicence)
  }

  getClients(){
    return this.httpClient.get(this.urlClient)
  }

  getClient(id){
    return this.httpClient.get(`${this.urlClient}${id}`);
  }

  updateClient(id:string,  dataUser:{}){
    return this.httpClient.post(`${this.urlClient}/${id}`,dataUser);
  }

  renovateLicence(id:string, dataLicence:{}){
    return this.httpClient.post(`${this.changeLicence}/${id}`,dataLicence);
  }

}