import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import {MustMatch} from './../../../../shared/method/password.validator'

//services 
import {ClientService} from '../../services/client.service';
import {UserService} from '../../../components/services/user.service';
import {pipeService} from '../../services/pipe.service';
import {Router } from '@angular/router'

//model Client
//import { customersLincense } from '../../models/client.model';



@Component({
  selector: 'app-user-new',
  templateUrl: './user-new.component.html'
})
export class UserNewComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  customers: {};
  dataTypeDocument :{};
  dataCountries :{};


  constructor(private router : Router,private servClient : ClientService ,private formBuilder : FormBuilder, private getServicesUser :  UserService , 
    private servPipe : pipeService,private pipeServ : pipeService) { }


  ngOnInit() {

    //licencias de cliente
    this.servClient.getClients()
    .subscribe(res =>{
      this.customers = res,
      console.log(res)
    })

    //servicio de pipe typeDocument
    this.servPipe.getDocumentType()
    .subscribe(res=>{
      this.dataTypeDocument = res,
      console.log(res)
    })

    this.pipeServ.getContry()
      .subscribe(res => {
      this.dataCountries = res;
      console.log(res)
      },
      err => console.log(err)
    )
    this.registerForm = this.formBuilder.group({
        firstname: ['', Validators.required],//username
        lastname: ['', Validators.required],//lastName
        phone: ['', Validators.required],
        country: ['', Validators.required],//country
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        client_id: ['', Validators.required],//client
        typeIdenficationCumber: ['', Validators.required],
        document_number: ['', Validators.required],
        confirmPassword: ['', Validators.required],
        checkbox_webAdminAcess: [''],
        input_webAdminAcess: ['',],
        checkbox_movileAdminAcess: [''],
        input_MobileAdminAcess: ['', ],
        status: 0,
        url_photo: 0
    }, {
        validator: MustMatch('password', 'confirmPassword')
    });
}

//Enviar por POST los dato del usuario
postRegisterUser(datauser:{}){
  this.getServicesUser.postCliente(datauser)
  .subscribe(
    res => {
      console.log(res)
    },
    err => console.log(err)
  )
}

get f() { return this.registerForm.controls; }

onSubmit() {
  this.submitted = true;

  //deténgase aquí si el formulario no es válido
  if (this.registerForm.invalid) {
      return;
  }

  //Elimino los campos de mi objeto
  delete this.registerForm.value['confirmPassword']
  delete this.registerForm.value['acceptTerms']

  this.postRegisterUser(this.registerForm.value)
  console.log(this.registerForm.value)
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
  this.router.navigate(['/home-default/user-list']));
}

onReset() {
  this.submitted = false;
  this.registerForm.reset();
  this.router.navigate(['/home-default']);
}

}
