import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import {environment} from '../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class pipeService {

  baseUrl = environment.apiURL;
  typeDocument : string;
  country : string;
  typeActivity : string;
  module : string;

  constructor(private httpPipe : HttpClient) {
    this.typeDocument = `${this.baseUrl}/api/document_type`;
    this.country = `${this.baseUrl}/api/country`;
    this.typeActivity = `${this.baseUrl}/api/type_activity`;
    this.module = `${this.baseUrl}/api/modules/category/`;
  }

  getDocumentType(){
    return this.httpPipe.get(this.typeDocument);
  }

  getContry(){
    return this.httpPipe.get(this.country);
  }

  getActivityType(){
    return this.httpPipe.get(this.typeActivity);
  }

  getModule(id){    
    return this.httpPipe.get(`${this.module}${id}`);
  }
}
