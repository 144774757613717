import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { Location } from '@angular/common'

//services
import {BankService} from '../../services/bank-questions.service'
import {pipeService} from '../../services/pipe.service';
import {ClientService} from '../../services/client.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-bank-edit',
  templateUrl: './bank-edit.component.html',
  styleUrls: ['./bank-edit.component.scss']
})
export class BankEditComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  customers:{};
  bank:any;
  getActivit:{};
  idBank:string;
  dataActivitiesType :{};

  constructor(private router : Router, private activRouter: ActivatedRoute ,private servClient : ClientService ,private formBuilder : FormBuilder, 
    private servicesBank : BankService, private servPipe : pipeService,private pipeServ : pipeService,private  location: Location) {
      
      this.activRouter.params.subscribe(res =>{
        this.idBank = res['id'];
      })
    }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],//username
      description: ['', Validators.required],//description
    });

    this.pipeServ.getActivityType()
      .subscribe(res => {
      this.dataActivitiesType = res;
      console.log(res)
      },
      err => console.log(err)
    )

    this.servicesBank.getBank(this.idBank)
      .subscribe(res =>{console.log(res)
        var resultEdit = res
        resultEdit.forEach(getBank => {          
          console.log('consuu',getBank)
          this.bank = getBank;
        });              
        this.editBank(this.bank)
    });
  }
  //LLena los campos para poder editar
  editBank(bankEdit:any){
    console.log('cons',bankEdit);
    this.registerForm.patchValue({
      name: bankEdit.name,
      description: bankEdit.description
    })
  } 

  //Enviar por POST los dato del usuario
  editPostBank(dataBank:{}){
    this.servicesBank.editPostBank(this.idBank,dataBank)
    .subscribe(
      res => {
        console.log(res)
      },
      err => console.log(err)
    )
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    //deténgase aquí si el formulario no es válido
    if (this.registerForm.invalid) {
        return;
    }

    this.editPostBank(this.registerForm.value)
    console.log(this.registerForm.value)
    
    this.location.back();
    //this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    //this.router.navigate(['/home-default/banco-actividades'])); 
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.location.back();
    //this.router.navigate(['/home-default']);
  }
}
