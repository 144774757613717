<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-add-user" titleHeading='Crear usuario'></app-page-title>
                </div>                    
            </div>
            <div class="row ml-0 mr-0">
                <div class="col-xl-12 pt-5">
                    <div class="text-center mb-5">
                        <div class="avatar-icon-wrapper rounded-circle mx-auto">
                            <div class="d-block p-0 avatar-icon-wrapper rounded-circle m-0 border-3 border-first">
                                <div class="rounded-circle border-3 border-white overflow-hidden">
                                    <img alt="..." style="width: 120px;" class="img-fluid" src="assets/images/avatars/unnamed.png"/>
                                </div>
                            </div>
                        </div>
                        <p>Subir una imagen
                            <button class="btn d-40 p-0 btn-pill m-2 btn-second" type="button">
                                <span class="btn-wrapper--icon">
                                    <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                                </span>
                            </button>
                        </p>
                    </div>
                </div>
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="p-5">
                    <div class="form-row">
                        <!--name-->
                        <div class="form-group col-6">
                            <label>Nombre </label>
                            <input type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
                            <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                <div *ngIf="f.firstname.errors.required">Este campo esta vacio</div>
                            </div>
                        </div>
                        <!--lastname-->
                        <div class="form-group col-6">
                        <label>Apellido</label>
                        <input type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" />
                        <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                            <div *ngIf="f.lastname.errors.required">Este campo esta vacio</div>
                        </div>
                        </div>
                        <!--counytry-->
                        <div class="form-group col-6">
                            <label>Pais</label>
                            <select class="form-control" formControlName="country" [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                            <option value="">Selecionar</option>
                            <option [value]="country.id" *ngFor="let country of dataCountries">{{country.name}}</option>
                            </select>
                            <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                                <div *ngIf="f.country.errors.required">Esta campo esta vacio</div>
                            </div>
                        </div>
                        <!--phone-->
                        <div class="form-group col-6">
                            <label>Teléfono</label>
                            <input type="text" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                            <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                <div *ngIf="f.phone.errors.required">Este campo esta vacio</div>
                            </div>
                        </div>
                        <!--client_id-->
                        <div class="form-group col-6">
                        <label>Cliente</label>
                        <select class="form-control" formControlName="client_id" [ngClass]="{ 'is-invalid': submitted && f.client_id.errors }">
                            <option value="" >Seleccionar</option>
                            <option [value]="client.id" *ngFor="let client of customers">{{client.Client}}</option>
                        </select>
                        <div *ngIf="submitted && f.client_id.errors" class="invalid-feedback">
                            <div *ngIf="f.client_id.errors.required">Este campo esta vacio</div>
                        </div>
                        </div>
                        <!--typeIdenficationCumber-->
                        <div class="form-group col-6">
                        <label>Tipo de documento</label>
                        <select class="form-control" formControlName="typeIdenficationCumber" [ngClass]="{ 'is-invalid': submitted && f.typeIdenficationCumber.errors }">
                            <option value="" >Seleccionar</option>
                            <option [value]="document.id" *ngFor="let document of dataTypeDocument">{{document.name}}</option>
                        </select>
                        <div *ngIf="submitted && f.typeIdenficationCumber.errors" class="invalid-feedback">
                            <div *ngIf="f.typeIdenficationCumber.errors.required">Este campo esta vacio</div>
                        </div>
                        </div>
                    <!--document_number-->
                    <div class="form-group col-6">
                        <label>Número de identificación</label>
                        <input type="text" formControlName="document_number" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.document_number.errors }" />
                        <div *ngIf="submitted && f.document_number.errors" class="invalid-feedback">
                            <div *ngIf="f.document_number.errors.required">Este campo esta vacio</div>
                        </div>
                    </div>
                    <!--email-->
                    <div class="form-group col-6">
                        <label>Email</label>
                        <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Este campo esta vacio</div>
                            <div *ngIf="f.email.errors.email">No es valido el correo</div>
                        </div>
                    </div>
                    </div>
                    <!--password-->
                    <div class="form-row">
                        <div class="form-group col-6">
                            <label>Contraseña</label>
                            <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Este campo esta vacio</div>
                                <div *ngIf="f.password.errors.minlength">La contraseña debe tener al menos 6 caracteres</div>
                            </div>
                        </div>
                        <!--confirPassword-->
                        <div class="form-group col-6">  
                            <label>Confirmar contraseña</label>
                            <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                <div *ngIf="f.confirmPassword.errors.required">Este campo esta vacio</div>
                                <div *ngIf="f.confirmPassword.errors.mustMatch">La contraseña deben coincidir con la anterior</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <!--checkbox_webAdminAcess-->
                        <div class="form-group col-6">
                        <div class="form-check">
                            <br>
                            <input type="checkbox" formControlName="checkbox_webAdminAcess" id="checkbox_webAdminAcess" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.checkbox_webAdminAcess.errors }" />
                            <label for="checkbox_webAdminAcess" class="form-check-label">Acceso Admin Web</label>
                            <div *ngIf="submitted && f.checkbox_webAdminAcess.errors" class="invalid-feedback">Accept Ts & Cs is required</div>
                        </div>
                        </div>
                        <!--input_webAdminAcess-->
                        <div class="form-group  col-6">
                        <label>Perfil Del Administrador</label>
                        <input type="text" formControlName="input_webAdminAcess" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.input_webAdminAcess.errors }" />
                        <div *ngIf="submitted && f.input_webAdminAcess.errors" class="invalid-feedback">
                            <div *ngIf="f.input_webAdminAcess.errors.required">Este campo esta vacio</div>
                        </div>
                        </div>
                        <!--checkbox_movileAdminAcess-->
                        <div class="form-group col-6">
                        <div class="form-check">
                            <br>
                            <input type="checkbox" formControlName="checkbox_movileAdminAcess" id="checkbox_movileAdminAcess" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.checkbox_movileAdminAcess.errors }" />
                            <label for="checkbox_movileAdminAcess" class="form-check-label">Acceso Ennlace Box Móvil</label>
                            <div *ngIf="submitted && f.checkbox_movileAdminAcess.errors" class="invalid-feedback">Accept Ts & Cs is required</div>
                        </div>
                        </div>
                        <!--input_MobileAdminAcess-->
                        <div class="form-group col-6">
                        <label>Perfil Móvil</label>
                        <input type="text" formControlName="input_MobileAdminAcess" class="form-control" [ngClass]="{'is-invalid': submitted && f.input_MobileAdminAcess.errors}" />
                        <div *ngIf="submitted && f.input_MobileAdminAcess.errors" class="invalid-feedback">
                            <div *ngIf="f.input_MobileAdminAcess.errors.required">Este campo esta vacio</div>
                        </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-primary mr-1">Guardar</button>
                        <button class="btn btn-secondary" type="reset" (click)="onReset()">Volver</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

