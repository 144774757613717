import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'

@Component({
  selector: 'app-activities-evaluate',
  templateUrl: './activities-evaluate.component.html',
  styleUrls: ['./activities-evaluate.component.scss']
})
export class ActivitiesEvaluateComponent implements OnInit {

  constructor(private location : Location) { }

  ngOnInit() {
    
  }
  onReset() {
    this.location.back();
  }
}
