import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from "../../../theme-options";

@Component({
  selector: 'app-home-default',
  templateUrl: './home-default.component.html'
})
export class HomeDefaultComponent  {

  constructor(public globals: ThemeOptions) { }

  ngOnInit() {
  }

}
