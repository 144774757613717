import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment'
import {HttpClient} from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  baseUrl = environment.apiURL;
  urlCategories : string;
  urlCategory :  string;
  urlActivities : string;
  urlActivityNew : string;
  urlModules : string;
  urlModule : string;
  urlModulesNew : string;
  urlModulesEdit: string;
  urlModulesDelete: string;
  urlActivitiesDelete: string;
  urlOrderActivity: string;
  urlDinamicRute: string;
  urlDinamicEdit: string;
  urlDinamicDelete: string;

  constructor(private http : HttpClient) {
    this.urlCategories = `${this.baseUrl}api/category`;
    this.urlCategory = `${this.baseUrl}api/category/delete`;
    this.urlActivities = `${this.baseUrl}api/category/activities`;
    this.urlActivitiesDelete = `${this.baseUrl}api/category/activity/delete`;
    this.urlActivityNew = `${this.baseUrl}api/category/activity`;
    this.urlModules = `${this.baseUrl}api/modules/category`;
    this.urlModule = `${this.baseUrl}api/category/module`;
    this.urlModulesNew = `${this.baseUrl}api/module/category`;
    this.urlModulesEdit = `${this.baseUrl}api/category/module`;
    this.urlModulesDelete = `${this.baseUrl}api/category/module/delete`;
    this.urlOrderActivity = `${this.baseUrl}api/order/category/activity`;
    this.urlDinamicRute = `${this.baseUrl}api/category/activity/dynamic`;
    this.urlDinamicEdit = `${this.baseUrl}api/category/activity/dynamic/edit`;
    this.urlDinamicDelete = `${this.baseUrl}api/category/activity/dynamic/delete`;
  }

  getCategories(){
   return this.http.get(this.urlCategories);
  }

  getActivities(id){    
    return this.http.get<any[]>(`${this.urlActivities}/${id}`);
  }

  getModules(id){    
    return this.http.get<any[]>(`${this.urlModules}/${id}`);
  }

  getModule(id){    
    return this.http.get<any[]>(`${this.urlModule}/${id}`);  
  }

  postModules(id, dataModule:{}){
    return this.http.post(`${this.urlModulesNew}/${id}`, dataModule);
  }

  editPostModule(id : string , dataModule:{}){
    return this.http.post(`${this.urlModulesEdit}/${id}`, dataModule);
  }

  postCategory(dataCategory:{}, file ){
    return this.http.post(this.urlCategories, dataCategory , file);
  }

  getCategory(id){
    return this.http.get(`${this.urlCategories}/${id}`);
  }

  editPostCategory(id : string , dataCategory:{}, file ){
    return this.http.post(`${this.urlCategories}/${id}`, dataCategory , file);
  }

  deletcategory(id){
    return this.http.get(`${this.urlCategory}/${id}`)
  }

  deletactivity(id){
    return this.http.get(`${this.urlActivities}/${id}`)
  }

  deletactivityRute(id){
    return this.http.get(`${this.urlActivitiesDelete}/${id}`)
  }

  deletModule(id){
    return this.http.get(`${this.urlModulesDelete}/${id}`)
  }

  postActivity(id : string ,dataActivity:{}, file){
    return this.http.post(`${this.urlActivityNew}/${id}`, dataActivity , file); 
  }
  
  postOrderActivity(dataOrder:{}){
    return this.http.post(this.urlOrderActivity, dataOrder);
  }

  postDinamicRute(id : string ,dataActivity:{}){
    return this.http.post(`${this.urlDinamicRute}/${id}`, dataActivity);
  }

  editPostDinamicRute(id : string , dataDinamic:{}){
    return this.http.post(`${this.urlDinamicEdit}/${id}`, dataDinamic);
  }

  deletDynamicRute(id){
    return this.http.get(`${this.urlDinamicDelete}/${id}`)
  }
}
