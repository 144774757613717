import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router'
import {enrollService} from '../../services/enroll.service';



@Component({
  selector: 'app-user-categories',
  templateUrl: './user-categories.component.html'
})
export class UserCategoriesComponent implements OnInit {
  idUserCategory:string;
  dataEnrrolUSer:{};
  total : any[];

  constructor(private actvRouter : ActivatedRoute, private enrollServ : enrollService) { 
    this.actvRouter.params.subscribe(res =>{ this.idUserCategory = res['id']})
  }

  ngOnInit() {
      this.enrollServ.getEnrollUserCategory(this.idUserCategory)
      .subscribe((res: any[])=> {
        this.dataEnrrolUSer = res,
        this.total = res;
        console.log(res)
      })
  }

  hayRegistros() {
    return this.total.length>0;              
  }
  
  Delet(id){
    this.enrollServ.deleteEnroll(id)
    .subscribe(res=>{
      console.log(res)
      alert('Se ha eliminado con exito.');
      location. reload(true);
    },
    err => console.log(err)
    )
  }

}
