<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-note2"  titleHeading='Listado de preguntas'></app-page-title>
                </div>
                <div class="card-header--actions">
                    <a class="btn btn-sm btn-primary text-white" tooltip="Crear pregunta">
                        <fa-icon [icon]="['fas', 'plus']"></fa-icon> Crear pregunta
                    </a>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table table-hover table-striped mb-0" *ngIf="hayRegistros(); else sinregistros">
                        <thead class="thead-light">
                        <tr>
                          <th style="width: 80vw;">Nombre pregunta</th>
                          <th style="width: 20vw;">Tipo</th>
                          <th>Opciones</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let bank of Banks;">
                                <td class="pl-4">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <a [routerLink]="" class="font-weight-bold text-black" title="...">{{bank.question}}</a>
                                        </div>
                                    </div>
                                </td> 
                                <td>
                                    <span class="h-auto py-0">{{bank.type_question}}</span>
                                </td>                                
                                <td class="text-center">
                                    <div class="btn-group btn-group-sm" dropdown placement="bottom right" container="body">
                                        <button class="btn btn-sm px-2 py-0 dropdown-toggle no-caret btn-primary"
                                                dropdownToggle type="button">
                                            <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
                                        </button>
                                        <div *dropdownMenu class="dropdown-menu dropdown-menu-xl p-0 dropdown-menu-right">
                                            <ul class="nav nav-neutral-primary nav-pills flex-column pt-2 pb-3">
                                                <li class="nav-item nav-item--header px-3">
                                                    <span class="text-capitalize text-black font-size-md font-weight-bold">Mis acciones</span>
                                                </li>
                                                <li class="dropdown-divider"></li>                                                
                                                <li class="nav-item px-3">
                                                    <a [routerLink]="['../edit',bank.bank_question_id]" class="nav-link active">
                                                        <div class="nav-link-icon">
                                                            <i-feather name="edit-3"></i-feather>
                                                        </div>
                                                        <span>Editar pregunta y opciones de respuesta</span>
                                                    </a>
                                                </li>                                              
                                                <li class="dropdown-divider"></li>
                                                <li class="nav-item">
                                                    <a type="submit" (click)="Delet(bank)" class="nav-link text-danger mx-3">
                                                        <div class="nav-link-icon pe-7s-close im"></div>
                                                        <span>Eliminar</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ng-template #sinregistros><h5 class="m-5">No hay preguntas en este banco</h5></ng-template>
                </div>
                <div class="divider"></div> 
                <div class="divider"></div>
                <div class="p-3 d-flex justify-content-center" *ngIf="hayRegistros()">
                    <pagination  [totalItems]="30"
                                class="pagination-icons pagination-primary"></pagination>
                </div>
            </div>
        </div>
    </div>
</div>

