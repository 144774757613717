import { Component, OnInit } from '@angular/core';
import {BankService} from '../../services/bank-questions.service'
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.scss']
})

export class QuestionListComponent implements OnInit {

  Banks:{};  
  total : any[];
  opcion:boolean=false;
  multiple:boolean=false;
  fv:boolean=false;
  texto:boolean=false;
  toggle = true;
  toggle1 = true;
  toggle2 = true;
  toggle3 = true;
  toggleN = false;
  toggleN1 = false;
  toggleN2 = false;
  toggleN3 = false;
  idBank:string;

  constructor(private servicesBank : BankService,private activRouter: ActivatedRoute) { 
    this.activRouter.params.subscribe(res =>{
      this.idBank = res['id'];
    })
  }

  ngOnInit() { 
    this.servicesBank.getQuestions(this.idBank)
    .subscribe((res: any[]) =>{
      this.Banks = res,
      this.total = res; 
      console.log(res);
    })
  }

  hayRegistros() { 
    return this.total.length>0;              
  }

  opcionFunction(){
    this.opcion=true;
    this.multiple=false;
    this.fv=false;
    this.texto=false; 
    this.toggle = false; 
    this.toggle1 = true; 
    this.toggle2 = true; 
    this.toggle3 = true; 
    this.toggleN = true;
    this.toggleN1 = false;
    this.toggleN2 = false;
    this.toggleN3 = false;   
  }

  multipleFunction(){  
    this.opcion=false;
    this.multiple=true;
    this.fv=false;
    this.texto=false;
    this.toggle = true;
    this.toggle1 = false; 
    this.toggle2 = true; 
    this.toggle3 = true; 
    this.toggleN = false;
    this.toggleN1 = true;
    this.toggleN2 = false;
    this.toggleN3 = false;
  }

  fvFunction(){
    this.opcion=false;
    this.multiple=false;
    this.fv=true;
    this.texto=false;
    this.toggle = true; 
    this.toggle1 = true; 
    this.toggle2 = false; 
    this.toggle3 = true;
    this.toggleN = false;
    this.toggleN1 = false;
    this.toggleN2 = true;
    this.toggleN3 = false;
  }

  textoFunction(){
    this.opcion=false;
    this.multiple=false;
    this.fv=false;
    this.texto=true;
    this.toggle = true; 
    this.toggle1 = true; 
    this.toggle2 = true; 
    this.toggle3 = false;
    this.toggleN = false;
    this.toggleN1 = false;
    this.toggleN2 = false;
    this.toggleN3 = true;
  }

  limpiarFunction(){
    this.opcion=false;
    this.multiple=false;
    this.fv=false;
    this.texto=false;
  }

  Delet(bank){ 
    this.servicesBank.deletBank(bank.id)
    .subscribe(res=>{
      console.log(res);
      alert('Se ha eliminado con exito');
      location. reload(true);
    },
    err=>console.log(err));
  }

}
