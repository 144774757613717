import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import {environment} from '../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseUrl = environment.apiURL;
  urlUsers : string;
  urluser: string;
  urluserDelete: string;

  constructor(private httpClient : HttpClient) {
    this.urlUsers = `${this.baseUrl}/api/users`;
    this.urluser = `${this.baseUrl}/api/users/`;
    this.urluserDelete = `${this.baseUrl}/api/users/delete/`;

  }

  getCostumer(){
    return this.httpClient.get(`${this.urlUsers}`)
  }

  postCliente(dataUser:{}){
    return this.httpClient.post(`${this.urlUsers}`, dataUser)
  }

  getUsers(id){
    return this.httpClient.get(`${this.urluser}${id}`);
  }

  updateUser(id:string,  dataUser:{}){
    return this.httpClient.post(`${this.urluser}${id}`,dataUser);
  }
  deletcategory(id){
    return this.httpClient.get(`${this.urluserDelete}${id}`)
  }

}
