<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-users" titleHeading='Usuarios matriculados'></app-page-title>
                </div>
                <div class="card-header--actions">
                    <a (click)="exampleModal.show()" class="btn btn-sm btn-primary text-white" tooltip="Matricular estudiante">
                        <fa-icon [icon]="['fas', 'plus']" style="color:#ffff"></fa-icon>  Matricular estudiante
                    </a>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table table-hover table-striped mb-0" *ngIf="hayRegistros(); else sinregistros">
                        <thead class="thead-light">
                        <tr>
                            <th>Nombre</th>
                            <th>Correo</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Opciones</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let enrol of dataEnrrolUSer">
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-icon-wrapper mr-2">
                                            <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/>
                                            </div>
                                        </div>
                                        <div>
                                            <a [routerLink]="" class="font-weight-bold text-black" title="...">{{enrol.firstname}} &nbsp; {{enrol.lastname}}</a>
                                            <!--<span class="text-black-50 d-block">Frontend Developer</span>-->
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span class="badge badge-success h-auto py-0 px-3">{{enrol.email}}</span>
                                </td>
                                <td class="text-center">
                                    <span class="badge  h-auto py-0 px-3 {{enrol.status == 0 ? 'badge-danger' : 'badge-success'}}">{{enrol.status == 0 ? 'INACTIVO' : 'ACTIVO'}}</span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group btn-group-sm" dropdown placement="bottom right" container="body">
                                        <button class="btn btn-sm px-2 py-0 dropdown-toggle no-caret btn-primary"
                                                dropdownToggle type="button">
                                            <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
                                        </button>
                                        <div *dropdownMenu class="dropdown-menu dropdown-menu-xl p-0 dropdown-menu-right">
                                            <ul class="nav nav-neutral-primary nav-pills flex-column pt-2 pb-3">
                                                <li class="nav-item nav-item--header px-3">
                                                    <span class="text-capitalize text-black font-size-md font-weight-bold">Mis acciones</span>
                                                </li>
                                                <li class="dropdown-divider"></li>
                                                <li class="nav-item">
                                                    <a (click)="Delet(enrol.enrollment_id)" class="nav-link text-danger mx-3">
                                                        <div class="nav-link-icon">
                                                            <fa-icon [icon]="['fas', 'times']"></fa-icon>
                                                        </div>
                                                        <span>Eliminar</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td> 
                            </tr>
                        </tbody>
                    </table>
                    <ng-template #sinregistros><h5 class="m-5">No hay usuarios matriculados para esta ruta</h5></ng-template>
                </div>
                <div class="divider"></div>
                <div class="divider"></div>
                <div class="p-3 d-flex justify-content-center" *ngIf="hayRegistros()">
                    <pagination  [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Modal-->
<div bsModal #exampleModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Matricular estudiante</h6>
                <button type="button" class="close" aria-label="Close" (click)="exampleModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-user-new-category></app-user-new-category>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-link btn-link-dark" (click)="exampleModal.hide()">Cerrar</button>
                <!--<button type="button" class="btn btn-primary ml-auto" (click)="exampleModal.hide()">Matricular</button>-->
            </div>
        </div>
    </div>
</div>
