import { Component, OnInit } from '@angular/core';
//servivios
import { ClientService } from "../../services/client.service";
import {pipeService} from '../../services/pipe.service'
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute , Router} from '@angular/router';
import * as moment from 'moment'

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html'
})
export class EditComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  bsInlineValue = new Date();
  maxDate = new Date();
  bsRangeValue: Date[];
  bsValue = new Date();
  typeOfLicenses : {}
  dataCliente:{}
  DateStartLicenses:any;
  cities :{};
  idClient:any;
  dataTypeDocuments:{}
  dataCountries : {}

  constructor(private actvRouter : ActivatedRoute,private router :Router ,private getServicesClient : ClientService, 
    private formBuilder: FormBuilder , private servPipe: pipeService) {

    this.actvRouter.params.subscribe(res =>{ this.idClient = res['id']})

  }

  ngOnInit() {

    //servicio de lincenses
    this.getServicesClient.getTypeLicences()
    .subscribe(
      res => {
        this.typeOfLicenses = res;
      },
      err => console.log(err)
    )

    //servivio de country
    this.servPipe.getContry().subscribe(
      res =>{this.cities = res
      })

      // Falta este servicio
    /*
      this.getServicesClient.getClient(
      this.idClient).subscribe(res =>{
        console.log(res);
      })
    */

   this.servPipe.getDocumentType()
   .subscribe(res=>{
     this.dataTypeDocuments  = res;
     console.log(res)
   },
   err => console.log(err)
   )
   this.servPipe.getContry()
   .subscribe(res =>{ this.dataCountries = res})


    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      document_type_id: ['', Validators.required],
      document_number: ['', Validators.required],
      contact_email: ['', Validators.required],
      contact_telephone: ['', Validators.required],
      contact_name: ['', Validators.required],
      country_id: ['', Validators.required],
      
    });
}



get f() { return this.registerForm.controls; }

//Update datos del cliente
updateClienteMain( dataCliente:{}){
  this.getServicesClient.updateClient(this.idClient, dataCliente)
  .subscribe(
    res => {
      console.log(res)
    },
    err => console.log(err)
  )
}  


onSubmit() {
  this.submitted = true;

  //deténgase aquí si el formulario no es válido
  if (this.registerForm.invalid) {
    return;
  }

  console.log(this.registerForm.value);
  this.updateClienteMain(this.registerForm.value);

  }

onReset() {
      this.submitted = false;
      this.registerForm.reset();
      this.router.navigate(['/home-default']);
  }

}
