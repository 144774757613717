<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-way"  titleHeading='Rutas'></app-page-title>
                </div>
                <div class="card-header--actions">
                    <a routerLink="new-category" class="btn btn-sm btn-primary" tooltip="Crear ruta">
                        <fa-icon [icon]="['fas', 'plus']"></fa-icon> Crear ruta
                    </a>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="row p-4">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6" *ngFor="let category of Categories;">
                        <div class="card mb-5">
                            <img alt="..." class="card-img-top" *ngFor="let img of category.images[0]" src="https://ennlacebox.spira.co/{{img.url_image}}"/><!--src="assets/images/stock-photos/stock-1.jpg"-->
                            <div class="card-body">
                                <h5 class="card-title font-weight-bold font-size-lg">{{category.name}}</h5> 
                                <p class="card-text"></p>
                                <div class="btn-group btn-group-sm" style="float: right!important" dropdown placement="bottom right" container="body">
                                        <button class="btn btn-sm px-2 py-1 dropdown-toggle no-caret btn-primary" dropdownToggle type="button">
                                            <i-feather name="settings"></i-feather>
                                        </button>
                                        <div *dropdownMenu class="dropdown-menu dropdown-menu-xl p-0 dropdown-menu-right">
                                            <ul class="nav nav-neutral-primary nav-pills flex-column pt-2 pb-3">
                                                <li class="nav-item nav-item--header px-3">
                                                    <span class="text-capitalize text-black font-size-md font-weight-bold">Mis acciones</span>
                                                </li>
                                                <li class="dropdown-divider"></li>
                                                <li class="nav-item px-3">
                                                    <a [routerLink]="['edit-category',category.category_id]" class="nav-link active">
                                                        <div class="nav-link-icon">
                                                            <!--<i class="ion-ios-user-edit font-size-xxl"></i>-->
                                                            <i-feather name="edit-3"></i-feather>
                                                        </div>
                                                        <span>Editar</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item px-3">
                                                    <a [routerLink]="['user-categories' , category.category_id]" class="nav-link">
                                                        <div class="nav-link-icon">
                                                            <i-feather name="users"></i-feather>
                                                        </div>
                                                        <span>Usuarios matriculados</span>
                                                    </a>
                                                </li>
                                                <!--<li class="nav-item px-3">
                                                    <a [routerLink]="['course-categories' , category.id]" class="nav-link">
                                                        <div class="nav-link-icon">
                                                            <i-feather name="book" class="font-size-xxl line-height-1"></i-feather>
                                                        </div>
                                                        <span>Cursos</span>
                                                    </a>
                                                </li>-->
                                                <li class="nav-item px-3">
                                                    <a [routerLink]="['activities' , category.category_id]" class="nav-link">
                                                        <div class="nav-link-icon pe-7s-note im"></div>
                                                        <span>Actividades de la ruta</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item px-3">
                                                    <a [routerLink]="['modules' , category.category_id]" class="nav-link">
                                                        <div class="nav-link-icon">
                                                            <i-feather name="grid"></i-feather>
                                                        </div>
                                                        <span>Módulos de la ruta</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item px-3">
                                                    <a  [routerLink]="['route' , category.category_id]" class="nav-link">
                                                        <div class="nav-link-icon pe-7s-way im"></div>
                                                        <span>Configurar ruta completa</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item px-3">
                                                    <a  [routerLink]="['route-dynamic' , category.category_id]" class="nav-link">
                                                        <div class="nav-link-icon pe-7s-way im"></div>
                                                        <span>Configurar ruta dinámica</span>
                                                    </a>
                                                </li>
                                                <li class="dropdown-divider"></li>
                                                <li class="nav-item">
                                                    <a type="submit" (click)="Delet(category)" class="nav-link text-danger mx-3">
                                                        <div class="nav-link-icon">
                                                            <fa-icon [icon]="['fas', 'times']"></fa-icon>
                                                        </div>
                                                        <span>Eliminar</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>                            
                </div>
                <!--<div class="table-responsive">
                    <table class="table table-hover table-striped mb-0">
                        <thead class="thead-light">
                        <tr>
                          <th style="width: 20vw;">Ruta</th>
                          <th>Descripción</th>
                          <th>Opciones</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let category of Categories;">
                                <td class="pl-4">
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-icon-wrapper mr-2">
                                            <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/>
                                            </div>
                                        </div>
                                        <div>
                                            <a [routerLink]="" class="font-weight-bold text-black" title="...">{{category.name}}</a>
                                            <span class="text-black-50 d-block">Frontend Developer</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span class="h-auto py-0" style="width: 60vw;">{{category.description }}</span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group btn-group-sm" dropdown placement="bottom right" container="body">
                                        <button class="btn btn-sm px-2 py-0 dropdown-toggle no-caret btn-primary"
                                                dropdownToggle type="button">
                                            <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
                                        </button>
                                        <div *dropdownMenu class="dropdown-menu dropdown-menu-xl p-0 dropdown-menu-right">
                                            <ul class="nav nav-neutral-primary nav-pills flex-column pt-2 pb-3">
                                                <li class="nav-item nav-item--header px-3">
                                                    <span class="text-capitalize text-black font-size-md font-weight-bold">Mis acciones</span>
                                                </li>
                                                <li class="dropdown-divider"></li>
                                                <li class="nav-item px-3">
                                                    <a [routerLink]="['edit-category',category.id]" class="nav-link active">
                                                        <div class="nav-link-icon">
                                                            <i class="ion-ios-user-edit font-size-xxl"></i>
                                                            <i-feather name="edit-3"></i-feather>
                                                        </div>
                                                        <span>Editar</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item px-3">
                                                    <a [routerLink]="['user-categories' , category.id]" class="nav-link">
                                                        <div class="nav-link-icon">
                                                            <i-feather name="users"></i-feather>
                                                        </div>
                                                        <span>Usuarios matriculados</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item px-3">
                                                    <a [routerLink]="['course-categories' , category.id]" class="nav-link">
                                                        <div class="nav-link-icon">
                                                            <i-feather name="book" class="font-size-xxl line-height-1"></i-feather>
                                                        </div>
                                                        <span>Cursos</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item px-3">
                                                    <a [routerLink]="['activities' , category.id]" class="nav-link">
                                                        <div class="nav-link-icon pe-7s-note im"></div>
                                                        <span>Actividades de la ruta</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item px-3">
                                                    <a [routerLink]="['modules' , category.id]" class="nav-link">
                                                        <div class="nav-link-icon">
                                                            <i-feather name="grid"></i-feather>
                                                        </div>
                                                        <span>Módulos de la ruta</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item px-3">
                                                    <a  [routerLink]="['route' , category.id]" class="nav-link">
                                                        <div class="nav-link-icon pe-7s-way im"></div>
                                                        <span>Configurar ruta completa</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item px-3">
                                                    <a  [routerLink]="['route-dynamic' , category.id]" class="nav-link">
                                                        <div class="nav-link-icon pe-7s-way im"></div>
                                                        <span>Configurar ruta dinámica</span>
                                                    </a>
                                                </li>
                                                <li class="dropdown-divider"></li>
                                                <li class="nav-item">
                                                    <a type="submit" (click)="Delet(category)" class="nav-link text-danger mx-3">
                                                        <div class="nav-link-icon">
                                                            <fa-icon [icon]="['fas', 'times']"></fa-icon>
                                                        </div>
                                                        <span>Eliminar</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>-->
                <div class="divider"></div>
                <div class="divider"></div>
                <!--<div class="p-3 d-flex justify-content-center">
                    <pagination  [totalItems]="30"
                                class="pagination-icons pagination-primary"></pagination>
                </div>-->
            </div>
        </div>
    </div>
</div>
