import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { Location } from '@angular/common'
import {ActivatedRoute, Router} from '@angular/router'


import {CategoriesService} from '../../services/categories.service'
import {ActivitiesService} from '../../services/banco-activities.service';

@Component({
  selector: 'app-banco-actividades-assignment',
  templateUrl: './banco-actividades-assignment.component.html',
  styleUrls: ['./banco-actividades-assignment.component.scss']
})
export class BancoActividadesAssignmentComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  categories:{};
  dataModules :{};
  idActivity:string;
  idRute : string;

  constructor(private servicesCategory : CategoriesService,private servicesModule : CategoriesService,
    private activRouter: ActivatedRoute,private formBuilder : FormBuilder,private router : Router,
    private getServicesActivity :  ActivitiesService, private  location: Location) { 
    this.activRouter.params.subscribe(res =>{
      this.idActivity = res['id'];
    })
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      //category_id: ['', Validators.required],//rute
      pilar_id: ['', Validators.required],//pilar
      module_id: ['', Validators.required],//module
      activity_id: ['', Validators.required],//module
    });

    this.servicesCategory.getCategories()
    .subscribe(res =>{
      this.categories = res,
      console.log(res);
    });    
  }

  //Select dependiente
  ruteSelected(value:string){
    this.idRute = value;
    this.servicesModule.getModules(this.idRute)
    .subscribe(res =>{
      this.dataModules = res,
      console.log(res);
    })
    console.log('Valor ruta' ,this.idActivity)
    this.assignmentActivity(this.idActivity)
  }

  assignmentActivity(bancoActividadesAssignment){
    console.log('cons',bancoActividadesAssignment);
    this.registerForm.patchValue({
      activity_id: bancoActividadesAssignment
    })
  }

  //Enviar por POST los dato de la actividad
  postRegisterActivity(dataActivity:{}){
    this.getServicesActivity.postActivityAssignment(this.idRute,dataActivity)
    .subscribe(
      res => {
        console.log(res)
      },
      err => console.log(err)
    )
  }

  get f() { return this.registerForm.controls;}

  onSubmit() {
    this.submitted = true;
  
    //deténgase aquí si el formulario no es válido
    if (this.registerForm.invalid) {
        return;
    }
  
    this.postRegisterActivity(this.registerForm.value)
    console.log(this.registerForm.value)
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/home-default/category/activities/',this.idRute]));
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.location.back();
  }

}
