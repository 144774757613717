import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { Location } from '@angular/common'
import {ActivatedRoute, Router} from '@angular/router';

//services 
import {ClientService} from '../../services/client.service';
import {CategoriesService} from '../../services/categories.service';
import {UserService} from '../../../components/services/user.service';
import {pipeService} from '../../services/pipe.service';

@Component({
  selector: 'app-module-edit-categories',
  templateUrl: './module-edit-categories.component.html',
  styleUrls: ['./module-edit-categories.component.scss']
})
export class ModuleEditCategoriesComponent implements OnInit {

    registerForm: FormGroup;
    submitted = false;
    customers: {};   
    idModule:string;
    module:any;
    getMod:{};
  
    constructor(private router : Router, private activRouter: ActivatedRoute,private servClient : ClientService ,private formBuilder : FormBuilder, private getServicesUser :  UserService , 
      private servPipe : pipeService,private pipeServ : pipeService,private  location: Location,private servCategory :  CategoriesService) {
        this.activRouter.params.subscribe(res =>{
          this.idModule = res['id'];
        })
       }
  
  
    ngOnInit() {  
      
      this.registerForm = this.formBuilder.group({
          name: ['', Validators.required],//name          
          description: ['', Validators.required], //description
      });
      this.servCategory.getModule(this.idModule)
      .subscribe(res =>{console.log(res)
        var resultEdit = res
        resultEdit.forEach(getMod => {          
          console.log('consuu',getMod)
          this.module = getMod;
        });              
        this.editModule(this.module)
      });      
  }    
  //LLena los campos para poder editar
  editModule(modulesEdit:any){
    console.log('cons',modulesEdit);
    this.registerForm.patchValue({
      name: modulesEdit.name,
      description: modulesEdit.description
    })
  }
  
  //Enviar por POST los dato del usuario
  editPostModule(dataModule:{}){
    this.servCategory.editPostModule(this.idModule,dataModule)
    .subscribe(
      res => {
        console.log(res)
      },
      err => console.log(err)
    )
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    //deténgase aquí si el formulario no es válido
    if (this.registerForm.invalid) {
        return;
    }
    this.editPostModule(this.registerForm.value)
    console.log(this.registerForm.value)
    this.location.back();
    //this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    //this.router.navigate(['/home-default/banco-actividades'])); 
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.location.back();
    //this.router.navigate(['/home-default']);
  }
}
