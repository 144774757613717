<!--The content below is only a placeholder and can be replaced.-->
<div id="chart">
    <div class="card-header--title" >
        <app-page-title iIconDescription="pe-7s-users" titleHeading='Dashboard'>
          
        </app-page-title>
    </div>
    <!-- <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis" [dataLabels]="chartOptions.dataLabels" [grid]="chartOptions.grid" [stroke]="chartOptions.stroke" [title]="chartOptions.title"></apx-chart> -->
</div>

<div class="container">
    <div class="row">
      <div class="col ancho_seccion" >
        <div onmouseover="contador()" class="usuario-total">
            <h4 class="text-center">Usuarios Activos</h4>
            <!-- <div class="counter" data-target="2500">80</div> -->
            <div id="chart_pie">
                <apx-chart
                [series]="chartUs.series"
                [chart]="chartUs.chart"
                [plotOptions]="chartUs.plotOptions"
                [labels]="chartUs.labels"
                [stroke]="chartUs.stroke"
                [fill]="chartUs.fill"
              ></apx-chart>
            </div>

        </div>
      </div>
      <div class="col ancho_seccion" >
        <div class="card-header--title usuario-total">
            <h4 class="text-center">Total de Usuarios por Ruta Finalizada</h4>
            <div id="chart_pie">
                <apx-chart [series]="chartOptions_2.series" [chart]="chartOptions_2.chart" [labels]="chartOptions_2.labels"></apx-chart>
            </div>
        </div>
        
      </div>
    </div>
  </div>




<div id="chart" class="usuario-total">
    <br>
    <h4 class="text-center">Ranking de Usuarios</h4>
    <br>
    <apx-chart [series]="chartOptions_bar.series" [chart]="chartOptions_bar.chart" [dataLabels]="chartOptions_bar.dataLabels" [plotOptions]="chartOptions_bar.plotOptions" [yaxis]="chartOptions_bar.yaxis" [xaxis]="chartOptions_bar.xaxis" [fill]="chartOptions_bar.fill"
        ></apx-chart>
</div>

<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-users" titleHeading='Reporte de Usuarios'></app-page-title>
                </div>
                <div class="card-header--actions">
                    <!-- <a routerLink="user-new" class="btn btn-sm btn-primary" tooltip="Crear usuario">
                        <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                    </a> -->
                    <input type="text" class="form-control" name="filterPost" placeholder="Buscar..." [(ngModel)]="filterPost">
                    <button class="centre btn mc btn_descarga" (click)="downloadExcel()"><span class="material-icons-outlined">
                        Descargar Excel<i class="fas fa-download"></i>
                        </span></button>
                    
                   
                </div>
            </div>
            

            <div class="card-body p-0">
                <div class="table-responsive" >    
                    <table id="dtBasicExample" class="table table-striped table-bordered " cellspacing="0" width="100%">
                        <thead class="thead-light">
                            <tr>
                                <th>Nombre</th>
                                <th>Apellido</th>
                                <th>Email</th>
                                <th>Nombre de la Ruta</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let userstate of userState  | filter:filterPost" >
                                <td>
                                    {{userstate.firstname}}
                                </td>
                                <td>
                                    {{userstate.lastname}}
                                </td>
                                <td>
                                    {{userstate.email}}
                                </td>
                                <td>
                                    {{userstate.name}}
                                </td>
                                <td >
                                    {{userstate.status}}
                                </td>
                            </tr>
                           
                        </tbody>
                        
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<script>
    $(document).ready(function () {
  $('#dtBasicExample').DataTable();
  $('.dataTables_length').addClass('bs-select');
});
</script>