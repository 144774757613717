<div class="row">
  <div class="col-xl-12">
      <div class="card card-box mb-5">
          <div class="card-header pr-2">
              <div class="card-header--title">
                  <app-page-title iIconDescription="pe-7s-user" titleHeading='Editar usuario'></app-page-title>
              </div>                    
          </div>
          <div class="row ml-0 mr-0">
            <div class="col-xl-12 pt-5">
                <div class="text-center mb-5">
                    <div class="avatar-icon-wrapper rounded-circle mx-auto">
                        <div class="d-block p-0 avatar-icon-wrapper rounded-circle m-0 border-3 border-first">
                            <div class="rounded-circle border-3 border-white overflow-hidden">
                                <img alt="..." style="width: 120px;" class="img-fluid" src="assets/images/avatars/unnamed.png"/>
                            </div>
                        </div>
                    </div>
                    <p>Subir una imagen
                        <button class="btn d-40 p-0 btn-pill m-2 btn-second" type="button">
                            <span class="btn-wrapper--icon">
                                <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                            </span>
                        </button>
                    </p>
                </div>
            </div>
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="p-5">
                <div class="form-row">
                    <!--name-->
                    <div class="form-group col-6">
                        <label>Nombre </label>
                        <input type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
                        <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                            <div *ngIf="f.firstname.errors.required">Esta campo esta vacio</div>
                        </div>
                    </div>
                    <!--lastname-->
                    <div class="form-group col-6">
                      <label>Apellido</label>
                      <input type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" />
                      <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                          <div *ngIf="f.lastname.errors.required">Esta campo esta vacio</div>
                      </div>
                  </div> 
                  <!--document_number-->
                  <div class="form-group col-6">
                    <label>Número de identificación</label>
                    <input type="text" formControlName="document_number" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.document_number.errors }" />
                    <div *ngIf="submitted && f.document_number.errors" class="invalid-feedback">
                        <div *ngIf="f.document_number.errors.required">Esta campo esta vacio</div>
                    </div>
                  </div>
                  <!--phone-->
                  <div class="form-group col-6">
                      <label>Telefono</label>
                      <input type="text" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                      <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                          <div *ngIf="f.phone.errors.required">Esta campo esta vacio</div>
                      </div>
                  </div>
                  <!--email-->
                  <div class="form-group col-12">
                      <label>Email</label>
                      <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">Esta campo esta vacio</div>
                          <div *ngIf="f.email.errors.email">No es valido el correo</div>
                      </div>
                  </div>
                </div>                
                <div class="text-center">
                    <button class="btn btn-primary mr-1">Guardar cambios</button>
                    <button class="btn btn-secondary" type="reset" (click)="onReset()">Volver</button>
                </div>
            </form>
          </div>
        </div>
    </div>
</div>
