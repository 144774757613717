import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  baseUrl = environment.apiURL;
  urlCourseCotegory: string;
  urlCourse: string;

  constructor(private http : HttpClient,) {
    this.urlCourseCotegory = `${this.baseUrl}api/category/course`;
    this.urlCourse = `${this.baseUrl}api/course`;
    }

    //ver cursos por categoria
  getCoursesCotegory(idCategory){
    return this.http.get(`${this.urlCourseCotegory}s/${idCategory}`)
  }

  deleteCourse(idCourse){
    return this.http.get(`${this.urlCourseCotegory}/delete/${idCourse}`);
  }

  postDataCourse(idCategory , dataCourse:{}, file:any){
    return  this.http.post(`${this.urlCourseCotegory}/${idCategory}`, dataCourse , file);
  }
}
