<div class="app-main">

    <div class="app-content p-0">
        <div class="app-content--inner d-flex align-items-center">
            <div class="flex-grow-1 w-100 d-flex align-items-center">
                <div class="bg-composed-wrapper--content py-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-5 d-none d-lg-flex align-items-center">
                                <img alt="..." class="w-100 mx-auto d-block img-fluid" src="assets/images/logo_ennlacebox.png">
                            </div>
                            <div class="col-lg-7 col-sm-12 d-flex align-items-center">
                                <div class="pl-0 pl-md-5 content-login">
                                    <div class="text-black mt-3">
                                        <span class="text-left text-sm-center">
                                            <h1 class="display-3 text-lg-left text-center mb-3 font-weight-bold">
                                              Iniciar sesión
                                            </h1>
                                            <p class="font-size-lg text-lg-left text-center mb-0 text-black-50"></p>
                                        </span>

                                        <div>
                                            <form [formGroup]="loginForm" (ngSubmit)="onSubmitLogin()">
                                                <div class="form-group">
                                                    <label>Usuario</label>
                                                    <input type="text" formControlName="document_number" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.document_number.errors }">
                                                    <div *ngIf="submitted && f.document_number.errors" class="invalid-feedback">
                                                        <div *ngIf="f.document_number.errors.required">Esta cambo esta vacio</div>
                                                    </div>
                                                </div>
                                                <div class="form-group mb-4">
                                                    <div class="d-flex justify-content-between">
                                                        <label>Contraseña</label>
                                                    </div>
                                                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                        <div *ngIf="f.password.errors.required">Esta cambo esta vacio</div>
                                                    </div>
                                                </div>

                                                <button class="btn btn-lg btn-second btn-block">Iniciar sesión</button>
                                            </form>
                                        </div>
                                        <!--<div class="text-center pt-4 text-black-50">Don't have an account? <a [routerLink]="['/pages-register']">Create an Account</a></div>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>