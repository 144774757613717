<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-note" titleHeading='Editar actividad'></app-page-title>
                </div>                    
            </div>
            <div class="row ml-0 mr-0">
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="p-5 col-xl-12">
                    <div class="form-row">
                        <!--name activity-->
                        <div class="form-group col-6">
                            <label>Nombre actividad</label>
                            <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Este campo esta vacio</div>
                            </div>
                        </div>           
            
                        <!--tipo actividad-->
                        <div class="form-group col-6">
                            <label>Tipo actividad</label>
                            <select class="form-control" formControlName="type_activity_id" [ngClass]="{ 'is-invalid': submitted && f.type_activity_id.errors }">
                                <option value="" >Seleccionar</option>
                                <option [value]="type.id" *ngFor="let type of dataActivitiesType">{{type.name}}</option>
                            </select>
                            <div *ngIf="submitted && f.type_activity_id.errors" class="invalid-feedback">
                                <div *ngIf="f.type_activity_id.errors.required">Este campo esta vacio</div>
                            </div>
                        </div>      
                        
                        <!--client_id-->
                        <div class="form-group col-6">
                            <label>Cliente</label>
                            <select class="form-control" formControlName="client_id" [ngClass]="{ 'is-invalid': submitted && f.client_id.errors }">
                                <option value="">Seleccionar</option>
                                <option [value]="client.id" *ngFor="let client of customers">{{client.Client}}</option>
                            </select>
                            <div *ngIf="submitted && f.client_id.errors" class="invalid-feedback">
                                <div *ngIf="f.client_id.errors.required">Esta campo esta vacio</div>
                            </div>
                        </div>
                            
                        <!--descripcion-->
                        <div class="form-group col-12">
                          <label>Descripción</label>
                          <textarea formControlName="description" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                          <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                              <div *ngIf="f.description.errors.required">Este campo esta vacio</div>
                          </div>
                        </div>
            
                        <div class="col-6 form-group">
                            <label>¿Cómo subirá el material de la actividad?</label>
                            <div>
                                <div class="custom-radio custom-control mb-3 custom-control-inline">
                                    <input #mySelect (change)='onOptionsSelected(mySelect.value)' class="custom-control-input" id="exampleCustomRadio" name="recurso" type="radio" value="1">
                                    <label class="custom-control-label" for="exampleCustomRadio">URL</label>
                                </div>
                                <div class="custom-radio custom-control mb-3 custom-control-inline">
                                    <input #mySelect1 (change)='onOptionsSelected1(mySelect1.value)' class="custom-control-input" id="exampleCustomRadio2" name="recurso" type="radio" value="2">
                                    <label class="custom-control-label" for="exampleCustomRadio2">Archivo desde mi equipo</label>
                                </div>                    
                            </div>
                        </div>
            
                        <div class="form-group col-6" [hidden]="verSeleccion1 != '2'">
                            <label for="exampleFile">Archivo</label>
                            <input class="form-control-file" id="recurso" formControlName="url" type="file">
                            <small class="form-text text-muted">
                                Suba el archivo de la actividad
                            </small>
                        </div>
            
                        <!--URL-->
                        <div class="form-group col-6" [hidden]="verSeleccion != '1'">
                            <label>URL</label>
                            <input type="text" formControlName="url" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.url.errors }" />
                            <div *ngIf="submitted && f.url.errors" class="invalid-feedback">
                                <div *ngIf="f.url.errors.required">Este campo esta vacio</div>
                            </div>
                        </div>
                    </div>        
            
                    <div class="text-center mt-6">
                        <button class="btn btn-primary mr-1">Guardar cambios</button>
                        <button class="btn btn-secondary" type="reset" (click)="onReset()">Volver</button>
                    </div>
                </form>                   
            </div>
        </div>
    </div>
</div>
