<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-note" titleHeading='Crear ruta'></app-page-title>
                </div>                    
            </div>
            <div class="row ml-0 mr-0">                
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="p-5 col-xl-12" enctype="multipart/form-data">
                    <div class="col-xl-12">
                        <div class="text-center mb-5">
                            <div class="avatar-icon-wrapper rounded-circle mx-auto">
                                <div class="d-block p-0 avatar-icon-wrapper rounded-circle m-0 border-3 border-first">
                                    <div class="rounded-circle border-3 border-white overflow-hidden">
                                        <img alt="..." style="width: 120px;" class="img-fluid" src="assets/images/avatars/unnamed.png"/>
                                    </div>
                                </div>
                            </div>
                            <p>Subir una imagen</p>                       
                            <div class="image-upload">
                                <label for="file-input" class="btn d-40 p-0 btn-pill m-2 btn btn-second">
                                    <span class="btn-wrapper--icon">
                                        <fa-icon [icon]="['fas','plus']"></fa-icon>
                                    </span>
                                </label>     
                                <input id="file-input" formControlName="url_image" (change)="onFileSelected($event.target.files)" type="file" style="display: none;" [ngClass]="{ 'is-invalid': submitted && f.url_image.errors }"/>
                                <div *ngIf="submitted && f.url_image.errors" class="invalid-feedback">
                                    <div *ngIf="f.url_image.errors.required">Suba una imagen para la ruta</div>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <div class="form-row">
                        <!--name-->
                        <div class="form-group col-6">
                            <label>Nombre de la ruta</label>
                            <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Esta campo esta vacio</div>
                            </div>
                        </div>
                    
                        <!--client_id-->
                        <div class="form-group col-6">
                            <label>Cliente</label>
                            <select class="form-control" formControlName="client_id" [ngClass]="{ 'is-invalid': submitted && f.client_id.errors }">
                                <option value="">Seleccionar</option>
                                <option [value]="client.id" *ngFor="let client of customers">{{client.Client}}</option>
                                </select>
                                <div *ngIf="submitted && f.client_id.errors" class="invalid-feedback">
                                    <div *ngIf="f.client_id.errors.required">Esta campo esta vacio</div>
                                </div>
                            </div>                            
                            <!--description-->
                            <div class="form-group col-12">
                                <label>Descripcion</label>
                                <!---->
                                <textarea class="form-control" formControlName="description" id="exampleText" name="text"[ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                                <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                    <div *ngIf="f.description.errors.required">Esta campo esta vacio</div>
                                </div>
                            </div>
                        
                        </div>
                        <!--id actividad                        
                        <div class="form-group col-6">
                            <input type="hidden" formControlName="url_image" class="form-control"/>                            
                        </div>-->
                        <div class="text-center">
                            <button class="btn btn-primary mr-1">Crear ruta</button>
                        <button class="btn btn-secondary" type="reset" (click)="onReset()">Volver</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
