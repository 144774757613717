import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {CategoriesService} from '../../services/categories.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html'
})
export class EditCategoryComponent implements OnInit {
  idCategory : string;
  registerForm: FormGroup;
  submitted = false;
  customers:{};
  selectedFile:File = null;

  constructor( private  location: Location, private actvRouter : ActivatedRoute, private servCateg : CategoriesService, private formBuilder : FormBuilder) { 
    this.actvRouter.params.subscribe(res =>{ this.idCategory = res['id']})
    console.log(this.actvRouter);
  }

  ngOnInit() {
      this.servCateg.getCategory(this.idCategory)
      .subscribe(res=>{
        console.log(res)
      })

      this.registerForm = this.formBuilder.group({
        //url_image: ['', Validators.required], 
        name: ['', Validators.required],
        description: ['', Validators.required],
        //client_id: ['', Validators.required],
        //course_order_enabled: ['', Validators.required],  
        //acceptTerms: [false, Validators.requiredTrue],
        //status: 0,
        //url_photo: 0
    });

    this.servCateg.getCategory(this.idCategory)
    .subscribe(res =>{ console.log('name',res[0])
      let getCategory = {
        id:res[0]['id'],
        category_id:res[0]['category_id'],
        name: res[0]['name'],
        description: res[0]['description'],
        course_order_enabled: res[0]['course_order_enabled'],
        created_at: res[0]['created_at'],
      }

      this.editCategory(getCategory)

    })
  }

  editCategory(getCategory : any){
    this.registerForm.patchValue({
      id: getCategory.id,
      category_id: getCategory.category_id,
      name: getCategory.name,
      description: getCategory.description,
      course_order_enabled: getCategory.course_order_enabled,
      created_at: getCategory.created_at
    })
  }

  get f() { return this.registerForm.controls; }

  postCategoryMain(dataCategorie: {} , file){
    this.servCateg.editPostCategory( this.idCategory, dataCategorie , file)
    .subscribe(res => {
      console.log(res)
    })
  }
  

  onFileSelected(event){
    this.selectedFile = <File>event.target.files[0];
  }

  onSubmit() {
    this.submitted = true;

    //deténgase aquí si el formulario no es válido
    if (this.registerForm.invalid) {
        return; 
    }

    
    const file = new FormData();
    file.append('imagen', this.selectedFile, this.selectedFile.name);
    console.log('file content', this.selectedFile);
    console.log(this.selectedFile.name);
    this.postCategoryMain(this.registerForm.value, file)
    this.location.back();
    //console.log(this.registerForm.value, this.selectedFile);
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

}
