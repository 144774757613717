<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-user" titleHeading='Editar cliente'></app-page-title>
                </div>                    
            </div>
            <div class="row ml-0 mr-0">
                <div class="col-xl-12 pt-5">
                    <div class="text-center mb-5">
                        <div class="avatar-icon-wrapper rounded-circle mx-auto">
                            <div class="d-block p-0 avatar-icon-wrapper rounded-circle m-0 border-3 border-first">
                                <div class="rounded-circle border-3 border-white overflow-hidden">
                                    <img alt="..." style="width: 120px;" class="img-fluid" src="assets/images/avatars/unnamed.png"/>
                                </div>
                            </div>
                        </div>
                        <p>Subir una imagen
                            <button class="btn d-40 p-0 btn-pill m-2 btn-second" type="button">
                                <span class="btn-wrapper--icon">
                                    <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                                </span>
                            </button>
                        </p>
                    </div>
                </div>
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="p-5">
                    <div class="form-row">
                        <!--name-->
                        <div class="form-group col-6">
                            <label>Nombre de Cliente</label>
                            <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Esta campo esta vacio</div>
                            </div>
                        </div>
                        <!-- document_type_id -->
                        <div class="form-group col-6">
                        <label>Tipo de documento</label>
                        <select class="form-control" formControlName="document_type_id" [ngClass]="{ 'is-invalid': submitted && f.document_type_id.errors }">
                            <option value="">Seleccione</option>
                            <option [value]="document.id" *ngFor="let document of dataTypeDocuments">{{document.name}}</option>
                        </select>
                        <div *ngIf="submitted && f.document_type_id.errors" class="invalid-feedback">
                            <div *ngIf="f.document_type_id.errors.required">Esta campo esta vacio</div>
                        </div>
                        </div>
                        <!-- document_number -->
                        <div class="form-group col-6">
                        <label>Numero de identificacion</label>
                        <input type="text" formControlName="document_number" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.document_number.errors }" />
                        <div *ngIf="submitted && f.document_number.errors" class="invalid-feedback">
                            <div *ngIf="f.document_number.errors.required">Esta campo esta vacio</div>
                        </div>
                        </div>
                        <!-- contact_email -->
                        <div class="form-group col-6">
                        <label>Correo Contacto</label>
                        <input type="text" formControlName="contact_email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.contact_email.errors }" />
                        <div *ngIf="submitted && f.contact_email.errors" class="invalid-feedback">
                            <div *ngIf="f.contact_email.errors.required">Esta campo esta vacio</div>
                        </div>
                        </div>
                        <!-- contact_telephone -->
                        <div class="form-group col-6">
                        <label>Telefono de Contacto</label>
                        <input type="text" formControlName="contact_telephone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.contact_telephone.errors }" />
                        <div *ngIf="submitted && f.contact_telephone.errors" class="invalid-feedback">
                            <div *ngIf="f.contact_telephone.errors.required">Esta campo esta vacio</div>
                        </div>
                        </div>
                        <!-- contact_name -->
                        <div class="form-group col-6">
                        <label>Persona contacto</label>
                        <input type="text" formControlName="contact_name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.contact_name.errors }" />
                        <div *ngIf="submitted && f.contact_name.errors" class="invalid-feedback">
                            <div *ngIf="f.contact_name.errors.required">Esta campo esta vacio</div>
                        </div>
                        </div>
                        <!-- country_id -->
                        <div class="form-group col-6">
                        <label>Pais</label>
                        <select class="form-control" formControlName="country_id" [ngClass]="{ 'is-invalid': submitted && f.country_id.errors }">
                            <option value="" id="">Selecionar</option>
                            <option [value]="country.id" id="" *ngFor="let country of dataCountries">{{country.name}}</option>
                        </select>
                        <div *ngIf="submitted && f.document_type_id.errors" class="invalid-feedback">
                            <div *ngIf="f.document_type_id.errors.required">Esta campo esta vacio</div>
                        </div>
                        </div>
                        
                    </div>
                    <div class="text-center">
                        <button class="btn btn-primary mr-1">Guardar</button>
                        <button class="btn btn-secondary" type="reset" (click)="onReset()">Volver</button>
                    </div>
                </form>                
            </div>
        </div>
    </div>
</div>
