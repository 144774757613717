import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

import { ClientService } from "../../services/client.service";
import {pipeService} from '../../services/pipe.service';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import * as moment from 'moment'


@Component({
  selector: 'app-new',
  templateUrl: './new.component.html'
})
export class NewComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  bsInlineValue = new Date();
  maxDate = new Date();
  bsRangeValue: Date[];
  bsValue = new Date();
  typeOfLicenses:{};
  dataCliente:{};
  DateStartLicenses:any;
  dataTypeDocuments : {};
  dataCountries : {};

  constructor(private  pipeServ : pipeService ,private getServicesClient : ClientService, private formBuilder: FormBuilder , private router :Router) {

  }

  ngOnInit() {
    //api type linceses
    this.getServicesClient.getTypeLicences()
    .subscribe(
      res => {
        this.typeOfLicenses = res;
      },
      err => console.log(err)
    )

    this.pipeServ.getDocumentType()
      .subscribe(res=>{
        this.dataTypeDocuments  = res;
        console.log(res)
      },
      err => console.log(err)
      )

      this.pipeServ.getContry()
        .subscribe(res => {
          this.dataCountries = res;
          console.log(res)
        },
        err => console.log(err)
        )

    this.registerForm = this.formBuilder.group({
        name: ['', Validators.required],
        document_type_id: ['', Validators.required],
        document_number: ['', Validators.required],
        contact_email: ['', Validators.required],
        contact_telephone: ['', Validators.required],
        contact_name: ['', Validators.required],
        country_id: ['', Validators.required],
    });
}

get f() { return this.registerForm.controls; }

//Enviar por POST los datos del Clienete
postRegisterClient(dataClient:{}){
  this.getServicesClient.postClients(dataClient)
  .subscribe(res => console.log(res),
  err => console.log(err))
}

onSubmit() {
  this.submitted = true;

  //deténgase aquí si el formulario no es válido
  if (this.registerForm.invalid) {
    return;
  }

  this.dataCliente = this.registerForm.value;

  console.log(this.registerForm.value);

  //Body de formulario
  interface Datacliente{
    name : string,
    licence_type_id :  number,
    startLicense : Date
  }

  let dateCliente : Datacliente = this.registerForm.value;

  let sumMonth = dateCliente.licence_type_id * 3;

  let datesa = moment(dateCliente.startLicense).format("YYYY-MM-DD")
  let datesa2 = moment(dateCliente.startLicense).add(sumMonth,"month").format("YYYY-MM-DD")
  dateCliente['start_date'] = datesa;
  dateCliente['end_date'] = datesa2;

  this.postRegisterClient(dateCliente)

  }

onReset() {
      this.submitted = false;
      this.registerForm.reset();
      this.router.navigate(['/home-default']);
  }

}
