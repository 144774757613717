import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { Location } from '@angular/common'

//services 
import {ClientService} from '../../services/client.service';
import {CategoriesService} from '../../services/categories.service';
import {UserService} from '../../../components/services/user.service';
import {pipeService} from '../../services/pipe.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-module-new-categories',
  templateUrl: './module-new-categories.component.html',
  styleUrls: ['./module-new-categories.component.scss']
})
export class ModuleNewCategoriesComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  customers: {};
  idRuta:string;  
    
  
  constructor(private router : Router, private activRouter: ActivatedRoute ,private servClient : ClientService ,private servCategory :  CategoriesService,private formBuilder : FormBuilder, private getServicesUser :  UserService , 
    private servPipe : pipeService,private pipeServ : pipeService,private  location: Location) {
      this.activRouter.params.subscribe(res =>{
        this.idRuta = res['id'];
      })
  }  
  
  ngOnInit() {
    this.servClient.getClients()
    .subscribe(res=>{
      this.customers = res
      console.log(res)
    })
    //valida formulario
    this.registerForm = this.formBuilder.group({
        name: ['', Validators.required],//name          
        description: ['', Validators.required], //description
        //client_id: ['', Validators.required], //description
    });      
  }
  //Enviar por POST los dato del modulo
  postRegisterModule(datauser:{}){
    this.servCategory.postModules(this.idRuta,datauser)    
    .subscribe(
      res => {
        console.log(this.idRuta)
        console.log(res)
      },
      err => console.log(err)
    )
  }
  
  get f() { return this.registerForm.controls;}
  
  onSubmit() {
    this.submitted = true;
  
    //deténgase aquí si el formulario no es válido
    if (this.registerForm.invalid) {
        return;
    }

    this.postRegisterModule(this.registerForm.value)
    console.log(this.registerForm.value)
    this.location.back();
  }
  
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.location.back();
  }
}
