import {Component,OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoriesService} from '../../services/categories.service'
import {Location} from '@angular/common'
import {FormBuilder, FormGroup, Validators} from '@angular/forms'

@Component({
  selector: 'app-route-dynamic',
  templateUrl: './route-dynamic.component.html',
  styleUrls: ['./route-dynamic.component.scss']
})
export class RouteDynamicComponent implements OnInit {

  Categories:{};
  Activities:{};
  activity:any;
  getActivit:{};
  getConfig:{};
  idActivity:string;
  dataActivities :{};
  dataConfig :{};
  dataList :any;
  exampleModal:any;  
  registerForm: FormGroup;
  submitted = false;
  registerFormEdit: FormGroup;
  submittedEdit = false;
  idActivityCategory: string;
  formDinamic:Boolean = false;
  formEdit: Boolean = false;
  notice:Boolean = false;
  warning:Boolean = false;
  delete:Boolean = false;
  warningerror: string;
  statuserror: string;
  loader:Boolean = false;
  max: string;
  min: string;
  low: string;
  medium: string;
  high: string;
  dynamic_id: string;

  constructor(private servCategory :  CategoriesService,private formBuilder : FormBuilder,private router : Router,private  location: Location,private activRouter: ActivatedRoute, private servicesCategory : CategoriesService) { 
      this.activRouter.params.subscribe(res =>{
      this.idActivity = res['id'];      
    })
  }

  ngOnInit() {  
    
    this.servicesCategory.getActivities(this.idActivity)
      .subscribe(res => {
      this.dataActivities = res;
      var resultEdit = res
      resultEdit.forEach(getActivit => {          
          console.log('consuu',getActivit)
          this.activity = getActivit;
          console.log('ActRuta',getActivit.category_activity_id);
      }); 
      console.log(res)
      this.dataList = this.dataActivities;
      console.log('tot',this.dataList.length);      
      },
      err => console.log(err)
    )    
    //valida formulario create
    this.registerForm = this.formBuilder.group({
      min_average_score: ['', Validators.required],      
      max_average_score: ['', Validators.required],
      low_activity_id: ['', Validators.required],
      medium_activity_id: ['', Validators.required],
      high_activity_id: ['', Validators.required],    
    });
    //valida formulario edit
    this.registerFormEdit = this.formBuilder.group({
      min_average_score: ['', Validators.required],      
      max_average_score: ['', Validators.required],
      low_activity_id: ['', Validators.required],
      medium_activity_id: ['', Validators.required],
      high_activity_id: ['', Validators.required],    
    });
  }

  activityCategory(value:string){
    this.idActivityCategory = value;
    this.formDinamic = true; 
    console.log('Resultado',this.idActivityCategory) 
    this.notice = false; 
    this.warning = false;
    this.delete = false; 
  }
  activityCategoryF(){    
    this.submitted = false;
    this.registerForm.reset();
    this.formDinamic = false;  
    this.notice = false;
    this.warning = false;
    this.delete = false;
  }
  //Enviar por POST los dato del modulo
  postRegisterDinamic(datauser:{}){
    this.loader = true;
    this.servCategory.postDinamicRute(this.idActivityCategory,datauser)    
    .subscribe(
      res => {        
        this.loader = false;
        console.log(this.idActivityCategory)
        console.log('Respuesta',(res as any).id)
        this.min = (res as any).min_average_score;
        this.max = (res as any).max_average_score;
        this.low = (res as any).low_activity_id;
        this.medium = (res as any).medium_activity_id;
        this.high = (res as any).high_activity_id;
        this.dynamic_id = (res as any).id;
        this.notice = true;
      },
      err => {
        this.loader = false;
        this.warning = true; 
        this.warningerror = err.statusText;
        this.statuserror = err.status;
        console.log('Error resultado',err)       
      },     
    )
  }
  
  get f() { return this.registerForm.controls;}
  
  onSubmit() {
    this.submitted = true;  
    //deténgase aquí si el formulario no es válido
    if (this.registerForm.invalid) {
        return;
    }
    this.postRegisterDinamic(this.registerForm.value)
    console.log(this.registerForm.value)
    this.formDinamic = false;    
  } 
  
  dynamicEdit(){
    this.formEdit = true; 
    this.notice = false;
    //LLena los campos para poder editar
    this.registerFormEdit.patchValue({
      min_average_score: this.min,
      max_average_score:this.max,
      low_activity_id: this.low,
      medium_activity_id: this.medium,
      high_activity_id: this.high
    })
  } 

  //Enviar por POST los datos de la configuración a editar
  editPostDinamicRute(dataDinamic:{}){
    this.servCategory.editPostDinamicRute(this.dynamic_id,dataDinamic)
    .subscribe(
      res => {
        console.log(res)
        this.min = (res as any).min_average_score;
        this.max = (res as any).max_average_score;
        this.low = (res as any).low_activity_id;
        this.medium = (res as any).medium_activity_id;
        this.high = (res as any).high_activity_id;
        this.dynamic_id = (res as any).id;
        this.loader = false;
        this.notice = true;
      },
      err => {
        console.log(err)
        this.loader = false;
      }
    )
  }

  get fe() { return this.registerFormEdit.controls; }

  onSubmitEdit() {
    this.submittedEdit = true;
    //deténgase aquí si el formulario no es válido
    if (this.registerFormEdit.invalid) {
        return;
    }
    this.editPostDinamicRute(this.registerFormEdit.value)
    console.log(this.registerFormEdit.value)
    this.formEdit = false; 
    this.loader = true;
  }

  dynamicDelete(rute){
    this.loader = true;
    this.servicesCategory.deletDynamicRute(rute) 
    .subscribe(res=>{
      this.loader = false;
      this.notice = false;
      this.delete = true;
    },
    err=>{
        this.loader = false;
        this.delete = false;
        this.warning = true; 
        this.warningerror = err.statusText;
        this.statuserror = err.status;
        console.log('Error resultado',err)       
      }
    );
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.location.back();
  }     
}
