<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-way" titleHeading='Configurar ruta completa'></app-page-title>
                </div>                    
            </div>
            <alert class="alerts-alternate ml-5 mr-5 mt-4" dismissible="true" type="success" *ngIf="notice">
                <div class="d-flex align-items-center align-content-start">
                      <span class="font-size-lg d-block d-40 mr-3 text-center bg-success text-white rounded-sm">
                          <fa-icon [icon]="['far', 'object-group']"></fa-icon>
                      </span>
                    <span>
                        <strong class="d-block">Notificación!</strong> Ruta actualizada correctamente!
                    </span> 
                </div>
            </alert>
            <alert class="alerts-alternate  ml-5 mr-5 mt-4" dismissible="true" type="danger" *ngIf="warning">
                <div class="d-flex align-items-center align-content-start">
                      <span class="font-size-lg d-block d-40 mr-3 text-center bg-danger text-white rounded-sm">
                          <fa-icon [icon]="['far', 'keyboard']"></fa-icon>
                      </span>
                  <span>
                          <strong class="d-block">Danger!</strong> La ruta no se pudo actualizar!
                      </span>
                </div>
            </alert>
            <div class="row ml-0 mr-0">
                <h4 class="ml-4 mt-3 col-xl-12">Actividades de la ruta</h4>
                <div class="card-body p-0 col-xl-6">
                    <div cdkDropList class="example-list col-sm-12" (cdkDropListDropped)="drop($event)">
                        <div class="example-box" *ngFor="let movie of dataList; let i = index" cdkDrag>{{ i + 1 }}: {{movie.type_activity}} - {{movie.name}} <span class="pe-7s-repeat font-weight-bold rot s-i"></span></div>
                    </div>                   
                </div>
                <div class="col-xl-4 offset-xl-1 mt-3 text-center">
                    <div class="card card-box-alt card-border-top border-first mb-5 pb-4">
                        <h3 class="font-size-lg font-weight-bold mt-5 mb-4">Importante!</h3>
                        <p class="card-text px-4 mb-4">
                            Arrastre las actividades y ordénelas como quiera que sean presentadas.
                        </p> 
                        <p class="card-text px-4 mb-4">
                            Recuerde que está configuración es para una ruta completa. 
                        </p>                       
                    </div>
                    <button class="btn btn-primary mr-1 mb-5" (click)="onReset()">Volver</button>
                </div>                
            </div>
        </div>
    </div>
</div>