import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { Location } from '@angular/common'

//services 
import {ActivitiesService} from '../../services/banco-activities.service';
import {pipeService} from '../../services/pipe.service';
import {ClientService} from '../../services/client.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-banco-actividades-new',
  templateUrl: './banco-actividades-new.component.html',
  styleUrls: ['./banco-actividades-new.component.scss']
})
export class BancoActividadesNewComponent implements OnInit {

    registerForm: FormGroup;
    submitted = false;
    customers: {};
    dataActivitiesType :{};
    verSeleccion: string = '';
    verSeleccion1: string = ''; 
    idActivity:string;  
    selectedFile:File = null;
    
  
    constructor(private router : Router,private activRouter: ActivatedRoute ,private formBuilder : FormBuilder, private getServicesActivity :  ActivitiesService , 
      private servPipe : pipeService,private pipeServ : pipeService, private  location: Location, private servClient : ClientService) { 
  
      this.activRouter.params.subscribe(res =>{
        this.idActivity = res['id'];
      })
     }

    ngOnInit() {

      this.pipeServ.getActivityType()
        .subscribe(res => {
        this.dataActivitiesType = res;
        console.log(res)
        },
        err => console.log(err)
      )

      this.servClient.getClients()
      .subscribe(res=>{
        this.customers = res
        console.log(res)
      })

      this.registerForm = this.formBuilder.group({
          name: ['', Validators.required],//name
          description: ['', Validators.required], //description
          client_id: ['', Validators.required],//cliente
          type_activity_id: ['', Validators.required],//tipo pregunta
          url_resource: ['', Validators.required],//url recurso
          //pilar_id: ['', Validators.required],//pilar
      });
      
    }
    //habilitar campos según tipo de actividad
    onOptionsSelected(valorseleccionado:string){
      this.verSeleccion1 = '';
      this.verSeleccion = valorseleccionado;
      console.log("the selected value is " + valorseleccionado);
    } 
    
    onOptionsSelected1(valorseleccionado1:string){
      this.verSeleccion = '';
      this.verSeleccion1 = valorseleccionado1;
      console.log("the selected value is " + valorseleccionado1);
    }
    //Enviar por POST los dato del usuario
    postRegisterActivity(datauser:{}){
      this.getServicesActivity.postActivity(datauser)
      .subscribe(
        res => {
          console.log(res)
          this.router.navigate(['/home-default/banco-actividades'])
          .then(() => {
            window.location.reload();
          })
        },
        err => console.log(err)
      )
    }
  
    get f() { return this.registerForm.controls;}

    onFileSelected(files: FileList){
      this.selectedFile = files.item(0);
    }
  
    onSubmit() {
      this.submitted = true;
    
      //deténgase aquí si el formulario no es válido
      if (this.registerForm.invalid) {
          return;
      }

      var form = new FormData();
      form.append("name", this.registerForm.value.name);
      form.append("description", this.registerForm.value.description);
      form.append("client_id", this.registerForm.value.client_id);
      form.append("type_activity_id", this.registerForm.value.type_activity_id);
      form.append("url_resource", this.selectedFile, this.registerForm.value.url_resource);

      console.log('file contents', this.selectedFile);
      console.log('name',this.registerForm.value.name);
      console.log('description',this.registerForm.value.description);
      console.log('client_id',this.registerForm.value.client_id);
      console.log('url_image',this.registerForm.value.url_resource);
      console.log('yyyy',this.selectedFile.name);
    
      this.postRegisterActivity(form)
      console.log(this.registerForm.value)
      //this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      //this.router.navigate(['/home-default/banco-actividades']));      
    }
    
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.location.back();
  }

}
