<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
                <div class="card-header pr-2">
                    <div class="card-header--title">
                        <app-page-title iIconDescription="pe-7s-note2"  titleHeading='Evaluar actividad'></app-page-title>
                    </div>
                    <div class="card-header--actions">
                        <a (click)="onReset()" class="btn btn-sm btn-primary text-white" tooltip="Volver">
                            <div class="nav-link-icon pe-7s-back"></div>  Volver
                        </a>
                    </div>
                </div>
                <div class="card-body p-0">
                <div class="p-5">
                    <h3 class="font-size-lg font-weight-bold">Actividad</h3> 
                    <p class="text-black-50 mt-2">Nombre de la actividad a evaluar</p>
                    <h3 class="font-size-lg font-weight-bold">Pregunta</h3>
                    <p class="text-black-50 mt-2">Texto de la pregunta a evaluar</p>
                    <h3 class="font-size-lg font-weight-bold">Respuesta</h3>
                    <p class="text-black-50 mt-2">Respuesta completa del usuario en caso que sea pregunta de taxto</p>
                    <a [routerLink]="" class="btn font-size-lg btn-sm mb-4 btn-second text-white" tooltip="Derscargar archivo subido por el usuario">
                        <div class="nav-link-icon pe-7s-cloud-download"></div>  Descargar entrega
                    </a>

                    <div class="col-xl-6 p-0">
                        <div class="card card-box-alt card-border-top border-success mb-5 pb-4" style="text-align:left">
                            <h3 class="font-size-lg font-weight-bold mt-5 mb-4 pl-4">Calificación</h3>
                            <div class="position-relative form-group pl-4 pr-4">
                                <label for="exampleText">Observaciones</label>
                                <textarea class="form-control" id="exampleText" name="text"></textarea>
                            </div>
                            <div class="input-group col-6 pl-4 pr-4 mb-4" role="group">
                                <div class="input-group-prepend"><span class="input-group-text">Nota</span></div>
                                <input class="form-control" placeholder="0.00" step="1" type="number">
                            </div>
                            <div class="text-center mt-6">
                                <button class="btn btn-primary mr-1">Calificar</button>
                            </div>
                        </div>
                    </div>
                </div>               
            </div>
        </div>
    </div>
</div>
