import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { Location } from '@angular/common'

//services 
import {ActivitiesService} from '../../services/banco-activities.service';
import {pipeService} from '../../services/pipe.service';
import {ClientService} from '../../services/client.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-banco-actividades-edit',
  templateUrl: './banco-actividades-edit.component.html',
  styleUrls: ['./banco-actividades-edit.component.scss']
})
export class BancoActividadesEditComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  customers:{};
  activity:any;
  getActivit:{};
  idActivity:string;
  dataActivitiesType :{};
  verSeleccion: string = '';
  verSeleccion1: string = '';


  constructor( private router : Router, private activRouter: ActivatedRoute ,private servClient : ClientService ,private formBuilder : FormBuilder, 
      private getServicesActivity :  ActivitiesService, private servPipe : pipeService,private pipeServ : pipeService,private  location: Location) {

      this.activRouter.params.subscribe(res =>{
        this.idActivity = res['id'];
      })
  }


  ngOnInit() {
    
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],//username
      description: ['', Validators.required],//lastName
      client_id: ['', Validators.required],//client
      type_activity_id: ['', Validators.required],//tipo pregunta
      //url: ['', Validators.required],//url recurso
      //pilar_id: ['', Validators.required],//pilar
    });

    this.pipeServ.getActivityType()
      .subscribe(res => {
      this.dataActivitiesType = res;
      console.log(res)
      },
      err => console.log(err)
    )
    
    //servicio cliente
    this.servClient.getClients()
      .subscribe(res =>{
      this.customers = res
      console.log(this.customers)
    });

    this.getServicesActivity.getActivity(this.idActivity)
      .subscribe(res =>{console.log(res)
        var resultEdit = res
        resultEdit.forEach(getActivit => {          
          console.log('consuu',getActivit)
          this.activity = getActivit;
        });              
        this.editActivity(this.activity)
    });  
  }
  
  //LLena los campos para poder editar
  editActivity(bancoActividadesEdit:any){
    console.log('cons',bancoActividadesEdit);
    this.registerForm.patchValue({
      name: bancoActividadesEdit.name,
      description: bancoActividadesEdit.description,
      type_activity_id: bancoActividadesEdit.type_activity_id,
      client_id: bancoActividadesEdit.client_id
    })
  }
  //habilitar campos según tipo de actividad
  onOptionsSelected(valorseleccionado:string){
    this.verSeleccion1 = '';
    this.verSeleccion = valorseleccionado;
    console.log("the selected value is " + valorseleccionado);
  } 

  onOptionsSelected1(valorseleccionado1:string){
    this.verSeleccion = '';
    this.verSeleccion1 = valorseleccionado1;
    console.log("the selected value is " + valorseleccionado1);
  }

  //Enviar por POST los dato del usuario
  editPostActivity(dataActivity:{}){
    this.getServicesActivity.editPostActivity(this.idActivity,dataActivity)
    .subscribe(
      res => {
        console.log(res)
      },
      err => console.log(err)
    )
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

  //deténgase aquí si el formulario no es válido
  if (this.registerForm.invalid) {
      return;
  }

  this.editPostActivity(this.registerForm.value)
  console.log(this.registerForm.value)

  this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
  this.router.navigate(['/home-default/banco-actividades'])); 
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.location.back();
    //this.router.navigate(['/home-default']);
  }

}
