import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { Location } from '@angular/common'

//services 
import {BankService} from '../../services/bank-questions.service'
import {pipeService} from '../../services/pipe.service';
import {ClientService} from '../../services/client.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-bank-new',
  templateUrl: './bank-new.component.html',
  styleUrls: ['./bank-new.component.scss']
})
export class BankNewComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  customers: {};
  dataActivitiesType :{};
  verSeleccion: string = '';
  verSeleccion1: string = ''; 
  idActivity:string;

  constructor(private router : Router,private activRouter: ActivatedRoute ,private formBuilder : FormBuilder, private servicesBank : BankService, 
    private servPipe : pipeService,private pipeServ : pipeService, private  location: Location, private servClient : ClientService) { }

  ngOnInit() {
    this.pipeServ.getActivityType()
      .subscribe(res => {
      this.dataActivitiesType = res;
      console.log(res)
      },
      err => console.log(err)
    )
    this.servClient.getClients()
    .subscribe(res=>{
      this.customers = res
      console.log(res)
    })
    this.registerForm = this.formBuilder.group({
        name: ['', Validators.required],//name
        description: ['', Validators.required], //description
        client_id: ['', Validators.required],//cliente
        //type_activity_id: ['', Validators.required],//tipo pregunta
        //url: ['', Validators.required],//url recurso
        //pilar_id: ['', Validators.required],//pilar
    });
  }
  //Enviar por POST los dato del usuario
  postRegisterBank(datauser:{}){
    this.servicesBank.postBank(datauser)
    .subscribe(
      res => {
        console.log(res)
      },
      err => console.log(err)
    )
  }

  get f() { return this.registerForm.controls;}

  onSubmit() {
    this.submitted = true;

    //deténgase aquí si el formulario no es válido
    if (this.registerForm.invalid) {
        return;
    }

    this.postRegisterBank(this.registerForm.value)
    console.log(this.registerForm.value)
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate(['/home-default/bank']));
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.location.back();
  }
}
