import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment'
import {HttpClient} from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class BankService {
  baseUrl = environment.apiURL;
  urlBank : string;
  urlBanks :  string;
  urlQuestions: string;

  constructor(private http : HttpClient) {
    this.urlBank = `${this.baseUrl}api/bank`;
    this.urlQuestions = `${this.baseUrl}/api/bank/question`;
    this.urlBanks = `${this.baseUrl}api/bank/delete`;
  }

  getBanks(){
   return this.http.get(this.urlBank);
  }

  postBank(dataBank:{}){
    return this.http.post(this.urlBank, dataBank);
  }

  getBank(id){
    return this.http.get<any[]>(`${this.urlBank}/${id}`);
  }

  editPostBank(id : string , dataBank:{}){
    return this.http.post(`${this.urlBank}/${id}`, dataBank);
  }

  deletBank(id){
    return this.http.get(`${this.urlBanks}/${id}`)
  }

  getQuestions(id){
    return this.http.get(`${this.urlQuestions}/${id}`);
   }
}