import { Component, OnInit } from '@angular/core';
import { ClientService} from '../../services/client.service';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html'
})
export class ListComponent implements OnInit {
  
  rotate = true;
  clients;



  constructor( private userServices : ClientService ) { }
  
  ngOnInit() {
    this.userServices.getClients()
      .subscribe(
        res => {
          this.clients = res;
          console.log(this.clients)
        },
        err => console.log(err)
      )
  }
  
}
