import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../components/services/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { Location } from '@angular/common'
import {MustMatch} from './../../../../shared/method/password.validator'
import {ClientService} from '../../services/client.service';
import {ActivatedRoute, Router} from '@angular/router';

import {pipeService} from '../../services/pipe.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html'
})
export class UserEditComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  customers:{};
  user:any;
  getUser:{};
  idUser:string;
  dataTypeDocument :{};


  constructor( private router : Router, private activRouter: ActivatedRoute ,private servClient : ClientService ,private formBuilder : FormBuilder, 
    private getServicesUser :  UserService, private servPipe : pipeService,private  location: Location) {

    this.activRouter.params.subscribe(res =>{
      this.idUser = res['id'];
    })
   }


  ngOnInit() {

    
    //servicio de pipe typeDocument
    this.servPipe.getDocumentType()
    .subscribe(res=>{
      this.dataTypeDocument = res,
      console.log(res)
    })

    this.registerForm = this.formBuilder.group({
      firstname: ['', Validators.required],//username
      lastname: ['', Validators.required],//lastName
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      //client_id: ['', Validators.required],//client
      //typeIdenficationCumber: ['', Validators.required],
      document_number: ['', Validators.required],
      //checkbox_webAdminAcess: [''],
      //input_webAdminAcess: ['',],
      //checkbox_movileAdminAcess: [''],
      //input_MobileAdminAcess: ['', ],
      status: 0,
      url_photo: 0
    });
    
    this.servClient.getClients()
    .subscribe(res =>{
      this.customers = res
      console.log(this.customers)
    });

    this.getServicesUser.getUsers(this.idUser)
    .subscribe(res =>{ console.log(res)
      let getUser = {
        id:res['id'],
        document_number: res['document_number'],
        firstname: res['firstname'],
        lastname: res['lastname'],
        email: res['email'],
        phone: res['phone'],
      }

      this.user = getUser;

      this.editUser(this.user)
    });
  }
  
  //LLena los campos para poder editar
  editUser(userEdit:any){
    console.log('cons',userEdit);
    this.registerForm.patchValue({
      document_number: userEdit.document_number,
      firstname: userEdit.firstname,
      lastname: userEdit.lastname,
      email: userEdit.email,
      phone: userEdit.phone
    })
  }
  
//Enviar por POST los dato del usuario
updateUser(datauser:{}){
  this.getServicesUser.updateUser(this.idUser,datauser)
  .subscribe(
    res => {
      console.log(res)
    },
    err => console.log(err)
  )
}

get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

  //deténgase aquí si el formulario no es válido
  if (this.registerForm.invalid) {
      return;
  }


  this.updateUser(this.registerForm.value)
  console.log(this.registerForm.value)

  this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
  this.router.navigate(['/home-default/user-list']));
 
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.location.back();
    //this.router.navigate(['/home-default']);
  }

}
