import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment'
import {HttpClient} from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  baseUrl = environment.apiURL;
  urlActivities : string;
  urlActivity :  string;
  urlActivityAssignment : string;

  constructor(private http : HttpClient) {
    this.urlActivities = `${this.baseUrl}api/activity`;
    this.urlActivity = `${this.baseUrl}api/activity/delete`;
    this.urlActivityAssignment= `${this.baseUrl}api/category/activity/assignment`;
  }

  getActivities(){
   return this.http.get(this.urlActivities);
  }

  postActivity(dataActivity:{}){
    return this.http.post(this.urlActivities, dataActivity);
  }

  postActivityAssignment(id : string , dataActivity:{}){
    return this.http.post(`${this.urlActivityAssignment}/${id}`, dataActivity);
  }

  getActivity(id){
    return this.http.get<any[]>(`${this.urlActivities}/${id}`);
  }

  editPostActivity(id : string , dataActivity:{}){
    return this.http.post(`${this.urlActivities}/${id}`, dataActivity);
  }

  deletActivity(id){
    return this.http.get(`${this.urlActivity}/${id}`)
  }

}