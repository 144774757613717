import { CommonModule  } from '@angular/common';
import { HttpClientModule} from '@angular/common/http';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {ThemeOptions} from './theme-options';


// Widgets

// NGX Bootstrap Core



// NGX Bootstrap Buttons

import { ButtonsModule } from 'ngx-bootstrap';

// NGX Bootstrap Collapse

import { CollapseModule } from 'ngx-bootstrap';

// NGX Bootstrap Timepicker

import { TimepickerModule } from 'ngx-bootstrap';

// NGX Bootstrap Typeahead

import { TypeaheadModule } from 'ngx-bootstrap';

// NGX Bootstrap Carousel

import { CarouselModule } from 'ngx-bootstrap';

// NGX Bootstrap Dropdown

import { BsDropdownModule } from 'ngx-bootstrap';

// NGX Bootstrap Datepicker

import { BsDatepickerModule } from 'ngx-bootstrap';

// NGX Bootstrap Modal

import { ModalModule } from 'ngx-bootstrap';

// NGX Bootstrap Pagination

import { PaginationModule } from 'ngx-bootstrap';

// NGX Bootstrap Progress bar

import { ProgressbarModule } from 'ngx-bootstrap';

// NGX Bootstrap Tabs

import { TabsModule } from 'ngx-bootstrap';

// NGX Bootstrap Popover

import { PopoverModule } from 'ngx-bootstrap';

// NGX Bootstrap Tooltip

import { TooltipModule } from 'ngx-bootstrap';

// NGX Bootstrap Accordion

import { AccordionModule } from 'ngx-bootstrap';

// NGX Bootstrap Alert

import { AlertModule } from 'ngx-bootstrap';

// Bootstrap Core



// ApexCharts for Angular

import { NgApexchartsModule } from 'ng-apexcharts';

// Apex Charts



// Perfect Scrollbar

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
wheelPropagation: false
};

// Ngx UI Switch

import { UiSwitchModule } from 'ngx-ui-switch';

// NG2 File Upload

import { FileUploadModule } from 'ng2-file-upload';

// NGX Dropzone for Angular

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
// Change this to your upload POST address:
url: 'https://httpbin.org/post',
maxFilesize: 50,
acceptedFiles: 'image/*'
};

// Formly Core

import {FormlyModule} from '@ngx-formly/core';

// Formly Bootstrap

import {FormlyBootstrapModule} from '@ngx-formly/bootstrap';

// Archwizard

import { ArchwizardModule } from 'angular-archwizard';

// AngularEditor

import {AngularEditorModule} from '@kolkov/angular-editor';

// Angular Circle Progress

import {NgCircleProgressModule} from 'ng-circle-progress';

// Angular Gauge Wrapper

import {GaugeModule} from 'angular-gauge';

// Angular Circle Progress

import {NgxGaugeModule} from 'ngx-gauge';

// Angular Ladda2 Buttons Loader

import {LaddaModule} from 'angular2-ladda';

// Angular FullWidth Calendar

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// Date-fns



// NGX Slick Carousel

import { SlickCarouselModule } from 'ngx-slick-carousel';

// jQuery



// Slick Carousel



// NG2 Charts

//import { ChartsModule } from 'ng2-charts';

// Chart.js Annotations



// Chart.js Datalabels



// Chart.js



// NGX Context Menu

import { ContextMenuModule } from 'ngx-contextmenu';

// Angular Component Development Kit



// Angular CountUp

import { CountUpModule } from 'countup.js-angular2';

// Dragula for Angular

import { DragulaModule } from 'ng2-dragula';

// NG2 Table

import { Ng2TableModule } from 'ng2-table';

// NG2 Smart Table

import { Ng2SmartTableModule } from 'ng2-smart-table';

// NG2 Completer

import { Ng2CompleterModule } from 'ng2-completer';

// Angular Colorpicker

import { ColorPickerModule } from 'ngx-color-picker';

// NGX Clipboard

import { ClipboardModule } from 'ngx-clipboard';

// NG Select

import { NgSelectModule } from '@ng-select/ng-select';

// NGX Autosize

import { AutosizeModule } from 'ngx-autosize';

// Angular noUiSlider

import { NouisliderModule } from 'ng2-nouislider';

// noUiSlider



// Angular Flags Icons

import { NgxFlagIconCssModule } from 'ngx-flag-icon-css';

// SVG Flags Icons



// Angular Feather Icons
// Documentacion https://github.com/michaelbazos/angular-feather
import { FeatherModule } from 'angular-feather';
import { Calendar, Activity, Bell, Settings, Search, Grid, Users, LifeBuoy, CloudDrizzle, Coffee, Box, Briefcase, Layers, Printer, Book, BookOpen, Edit3 } from 'angular-feather/icons';
const icons = {
   Calendar,
   Activity,
   Bell,
   Settings,
   Search,
   Grid,
   Users,
   LifeBuoy,
   CloudDrizzle,
   Coffee,
   Box,
   Briefcase,
   Layers,
   Printer,
   Book,
   BookOpen,
   Edit3
};

// FontAwesome Regular SVG Icons

import { faSquare, faCheckCircle, faTimesCircle, faDotCircle, faThumbsUp, faComments, faFolderOpen, faTrashAlt, faFileImage, faFileArchive, faLifeRing, faCommentDots, faFolder, faKeyboard, faCalendarAlt, faEnvelope, faAddressCard, faMap, faObjectGroup, faImages, faUser, faLightbulb, faGem, faClock, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar } from '@fortawesome/free-regular-svg-icons';

// FontAwesome Solid SVG Icons

import { faChevronRight, faSitemap, faPrint, faMapMarkerAlt, faTachometerAlt, faAlignCenter, faExternalLinkAlt, faShareSquare, faInfoCircle, faSync, faQuoteRight, faStarHalfAlt, faShapes, faCarBattery, faTable, faCubes, faPager,  faCameraRetro, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faUnlockAlt, faDownload, faAward, faPlayCircle, faReply, faUpload, faBars, faEllipsisV, faSave, faSlidersH, faCaretRight, faChevronUp, faPlus, faLemon, faChevronLeft, faTimes, faChevronDown, faFilm, faSearch, faEllipsisH, faCog, faArrowsAltH, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLink } from '@fortawesome/free-solid-svg-icons';

// FontAwesome Brand SVG Icons

import { faFacebook, faTwitter, faAngular, faVuejs, faReact, faHtml5, faGoogle, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub  } from '@fortawesome/free-brands-svg-icons';

// Angular FontAwesome Icons

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

// Angular FontAwesome Icons Core



// Ionicons



// Pe7 Icons



// Socicons Icons



// NG Spin Kit

import { NgSpinKitModule } from 'ng-spin-kit';

// NGX Skeleton Loader

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// Angular Progressbar Core

import { NgProgressModule } from '@ngx-progressbar/core';

// Angular Progressbar router module

import { NgProgressRouterModule } from '@ngx-progressbar/router';

// NGX Spinner

import { NgxSpinnerModule } from 'ngx-spinner';

// Angular Google Maps

import { AgmCoreModule } from '@agm/core';

// Angular SweetAlerts2 Notifications

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

// SweetAlerts2 Notifications



// Angular Notifier

import { NotifierModule, NotifierOptions } from 'angular-notifier';
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'uifort',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
     easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
     easing: 'ease'
    },
    overlap: 150
  }
};

// NGX Pagination for Angular

import { NgxPaginationModule } from 'ngx-pagination';

// NGX Ratings for Angular

import { BarRatingModule } from 'ngx-bar-rating';

// Angular Infinite Scroll

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// Angular Elegant Trends Graphs

import { TrendModule } from 'ngx-trend';

// Angular Tree Component

import { TreeModule } from 'angular-tree-component';

// UUID



// Angular Tree Grid

import { AngularTreeGridModule } from 'angular-tree-grid';

// NGX Joyride

import { JoyrideModule } from 'ngx-joyride';

// Hamburgers navigation buttons



// NGX Image Cropper

import { ImageCropperModule } from 'ngx-image-cropper';

// Dual listbox

import { AngularDualListBoxModule } from 'angular-dual-listbox';

// Input Mask

import { TextMaskModule } from 'angular2-text-mask';

// Angular Leaflet maps

import { LeafletModule } from '@asymmetrik/ngx-leaflet';

// Leaflet Maps

import { tileLayer, latLng } from 'leaflet';

// Layouts

import { LeftSidebarComponent } from './layout-blueprints/left-sidebar/left-sidebar.component';
import { CollapsedSidebarComponent } from './layout-blueprints/collapsed-sidebar/collapsed-sidebar.component';
import { MinimalLayoutComponent } from './layout-blueprints/minimal-layout/minimal-layout.component';
import { PresentationLayoutComponent } from './layout-blueprints/presentation-layout/presentation-layout.component';

// Layout components

import { HeaderComponent } from './layout-components/header/header.component';
import { HeaderDotsComponent } from './layout-components/header-dots/header-dots.component';
import { HeaderDrawerComponent } from './layout-components/header-drawer/header-drawer.component';
import { HeaderUserboxComponent } from './layout-components/header-userbox/header-userbox.component';
import { HeaderSearchComponent } from './layout-components/header-search/header-search.component';
import { HeaderMenuComponent } from './layout-components/header-menu/header-menu.component';
import { SidebarCollapsedComponent } from './layout-components/sidebar-collapsed/sidebar-collapsed.component';
import { SidebarComponent } from './layout-components/sidebar/sidebar.component';
import { SidebarHeaderComponent } from './layout-components/sidebar-header/sidebar-header.component';
import { SidebarUserboxComponent } from './layout-components/sidebar-userbox/sidebar-userbox.component';
import { SidebarMenuComponent } from './layout-components/sidebar-menu/sidebar-menu.component';
import { SidebarFooterComponent } from './layout-components/sidebar-footer/sidebar-footer.component';
import { PageTitleComponent } from './layout-components/page-title/page-title.component';
import { FooterComponent } from './layout-components/footer/footer.component';
import { ThemeConfiguratorComponent } from './layout-components/theme-configurator/theme-configurator.component';
import { PromoSectionComponent } from './layout-components/promo-section/promo-section.component';
import { ExampleWrapperSimpleComponent } from './layout-components/example-wrapper-simple/example-wrapper-simple.component';
import { ExampleWrapperSeamlessComponent } from './layout-components/example-wrapper-seamless/example-wrapper-seamless.component';
import { ExampleWrapperContainerComponent } from './layout-components/example-wrapper-container/example-wrapper-container.component';

//pages
import { LoginComponent } from './content/auth/login/login.component';
import { IndexComponent } from './content/index/index.component';
import { ClientComponent } from './content/components/client/client.component';
import { HomeDefaultComponent } from './content/components/home-default/home-default.component';
import { ListComponent } from './content/components/client/list/list.component';
import { NewComponent } from './content/components/client/new/new.component';
import { SettingComponent } from './content/components/client/setting/setting.component';
import { UserComponent} from './content/components/user/user.component';
import { UserNewComponent } from './content/components/user/user-new/user-new.component';
import { UserListComponent } from './content/components/user/user-list/user-list.component';
import { EditComponent } from './content/components/client/edit/edit.component';
import { ClientCategorieComponent } from './content/components/client/client-categorie/client-categorie.component';
import { CategoriesComponent } from './content/components/categories/categories.component';
import { NewCategoriesComponent } from './content/components/categories/new-categories/new-categories.component';
import { UserEditComponent } from './content/components/user/user-edit/user-edit.component';
import { EditCategoryComponent } from './content/components/categories/edit-category/edit-category.component';
import { UserCategoriesComponent } from './content/components/categories/user-categories/user-categories.component';
import { UserNewCategoryComponent } from './content/components/categories/user-categories/user-new-category/user-new-category.component';
import { CourseCategoryComponent } from './content/components/categories/course-category/course-category.component';
import { CourseNewCategoryComponent } from './content/components/categories/course-category/course-new-category/course-new-category.component';
import { CourseEditCategoryComponent } from './content/components/categories/course-category/course-edit-category/course-edit-category.component';
import { ActivitiesRouteCategoriesComponent } from './content/components/categories/activities-route-categories/activities-route-categories.component';
import { ActivitiesCategoriesComponent } from './content/components/categories/activities-categories/activities-categories.component';
import { ModuleEditCategoriesComponent } from './content/components/categories/module-edit-categories/module-edit-categories.component';
import { ModuleNewCategoriesComponent } from './content/components/categories/module-new-categories/module-new-categories.component';
import { ModuleCategoriesComponent } from './content/components/categories/module-categories/module-categories.component';
import { ActivitiesEditCategoriesComponent } from './content/components/categories/activities-edit-categories/activities-edit-categories.component';
import { ActivitiesNewCategoriesComponent } from './content/components/categories/activities-new-categories/activities-new-categories.component';
import { EnrollmentUserComponent } from './content/components/enrollments/enrollment-user/enrollment-user.component';
import { EnrollmentNewUserComponent } from './content/components/enrollments/enrollment-new-user/enrollment-new-user.component';


import {DragDropModule} from '@angular/cdk/drag-drop';
import { BancoActividadesListComponent } from './content/components/banco-actividades/banco-actividades-list/banco-actividades-list.component';
import { BancoActividadesNewComponent } from './content/components/banco-actividades/banco-actividades-new/banco-actividades-new.component';
import { BancoActividadesEditComponent } from './content/components/banco-actividades/banco-actividades-edit/banco-actividades-edit.component';
import { EvaluationConfigComponent } from './content/components/categories/evaluation-config/evaluation-config.component';
import { RouteDynamicComponent } from './content/components/categories/route-dynamic/route-dynamic.component';
import { BankListComponent } from './content/components/bank-question/bank-list/bank-list.component';
import { BankEditComponent } from './content/components/bank-question/bank-edit/bank-edit.component';
import { BankNewComponent } from './content/components/bank-question/bank-new/bank-new.component';
import { QuestionListComponent } from './content/components/bank-question/question-list/question-list.component';
import { QuestionEditComponent } from './content/components/bank-question/question-edit/question-edit.component';
import { QuestionNewComponent } from './content/components/bank-question/question-new/question-new.component';
import { ActivitiesEvaluateListComponent } from './content/components/categories/activities-evaluate-list/activities-evaluate-list.component';
import { ActivitiesEvaluateComponent } from './content/components/categories/activities-evaluate/activities-evaluate.component';
import { BancoActividadesAssignmentComponent } from './content/components/banco-actividades/banco-actividades-assignment/banco-actividades-assignment.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FilterPipe } from './pipes/filter.pipe';



@NgModule({
  declarations: [
    AppComponent,
    
// Layout components
   
    HeaderComponent,
    HeaderDotsComponent,
    HeaderDrawerComponent,
    HeaderUserboxComponent,
    HeaderSearchComponent,
    HeaderMenuComponent,
    SidebarCollapsedComponent,
    SidebarComponent,
    SidebarHeaderComponent,
    SidebarUserboxComponent,
    SidebarMenuComponent,
    SidebarFooterComponent,
    PageTitleComponent,
    FooterComponent,
    ThemeConfiguratorComponent,
    PromoSectionComponent,
    ExampleWrapperSimpleComponent,
    ExampleWrapperSeamlessComponent,
    ExampleWrapperContainerComponent,

    // Layouts
    LeftSidebarComponent,
    CollapsedSidebarComponent,
    MinimalLayoutComponent,
    PresentationLayoutComponent,

    //Home
    LoginComponent,
    IndexComponent,
    ClientComponent,
    HomeDefaultComponent,
    ListComponent,
    NewComponent,
    SettingComponent,
    UserComponent,
    UserNewComponent,
    UserListComponent,
    EditComponent,
    ClientCategorieComponent,
    CategoriesComponent,
    NewCategoriesComponent,
    UserEditComponent,
    EditCategoryComponent,
    UserCategoriesComponent,
    UserNewCategoryComponent,
    CourseCategoryComponent,
    CourseNewCategoryComponent,
    CourseEditCategoryComponent,
    ActivitiesRouteCategoriesComponent,
    ActivitiesCategoriesComponent,
    ModuleEditCategoriesComponent,
    ModuleNewCategoriesComponent,
    ModuleCategoriesComponent,
    ActivitiesEditCategoriesComponent,
    ActivitiesNewCategoriesComponent,
    EnrollmentUserComponent,
    EnrollmentNewUserComponent,
    BancoActividadesListComponent,
    BancoActividadesNewComponent,
    BancoActividadesEditComponent,
    EvaluationConfigComponent,
    RouteDynamicComponent,
    BankListComponent,
    BankEditComponent,
    BankNewComponent,
    QuestionListComponent,
    QuestionEditComponent,
    QuestionNewComponent,
    ActivitiesEvaluateListComponent,
    ActivitiesEvaluateComponent,
    BancoActividadesAssignmentComponent,
    DashboardComponent,
    FilterPipe
  ],
  imports: [
    DragDropModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    TimepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    NgApexchartsModule,
    PerfectScrollbarModule,
    UiSwitchModule,
    FileUploadModule,
    DropzoneModule,
    FormlyModule.forRoot(),
    FormlyBootstrapModule,
    ArchwizardModule,
    AngularEditorModule,
    NgCircleProgressModule.forRoot(),
    GaugeModule.forRoot(),
    NgxGaugeModule,
    LaddaModule,
    CalendarModule.forRoot({provide: DateAdapter, useFactory: adapterFactory }),
    SlickCarouselModule,
    //ChartsModule,
    ContextMenuModule.forRoot(),
    CountUpModule,
    DragulaModule.forRoot(),
    Ng2TableModule,
    Ng2SmartTableModule,
    Ng2CompleterModule,
    ColorPickerModule,
    ClipboardModule,
    NgSelectModule,
    AutosizeModule,
    NouisliderModule,
    NgxFlagIconCssModule,
    FeatherModule.pick(icons),
    FontAwesomeModule,
    NgSpinKitModule,
    NgxSkeletonLoaderModule,
    NgProgressModule,
    NgProgressRouterModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({apiKey: 'AIzaSyCoVRykl39EigHTQ0wnI0ISVGR3zpV4dDM'}),
    SweetAlert2Module.forRoot(),
    NotifierModule.withConfig(customNotifierOptions),
    NgxPaginationModule,
    BarRatingModule,
    InfiniteScrollModule,
    TrendModule,
    TreeModule.forRoot(),
    AngularTreeGridModule,
    JoyrideModule.forRoot(),
    ImageCropperModule,
    AngularDualListBoxModule,
    TextMaskModule,
    LeafletModule.forRoot(),
  ],
  providers: [
    {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
},
    {
    provide: DROPZONE_CONFIG,
    useValue: DEFAULT_DROPZONE_CONFIG
},
    ThemeOptions
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
   library.addIcons(faFacebook, faPrint, faAlignCenter, faMapMarkerAlt, faTachometerAlt, faExternalLinkAlt, faShareSquare, faSitemap, faInfoCircle, faLifeRing, faTwitter, faQuoteRight, faStarHalfAlt, faSync, faShapes, faCarBattery, faTable, faCubes, faPager,  faAngular, faVuejs, faReact, faHtml5, faCheckCircle, faTimesCircle, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faThumbsUp, faCameraRetro, faUnlockAlt, faDownload, faUpload, faReply, faGoogle, faFileImage, faFolderOpen, faBars, faTrashAlt, faSave, faPlayCircle, faEllipsisV, faEllipsisH, faSlidersH, faFileArchive, faAward, faCaretRight, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub, faPlus, faFolder, faTimes, faEnvelope, faAddressCard, faMap, faCalendarAlt, faImages, faFilm, faClock, faSearch, faChevronRight, faChevronUp, faChevronLeft, faChevronDown, faLink, faLightbulb, faGem, faCog, faDotCircle, faArrowsAltH, faComments, faCommentDots, faKeyboard, faObjectGroup, faUser, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLemon);
}
}
