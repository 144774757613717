import { Component, OnInit } from '@angular/core';
import { UserService} from '../../services/user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit {

  rotate = true;
  Users;



  constructor( private userServices : UserService ) {

  }

  ngOnInit() {
    this.userServices.getCostumer()
      .subscribe(
        res => {
          this.Users = res;
          console.log(this.Users)
        },
        err => console.log(err)
      )
  }
  Delet(user){
    this.userServices.deletcategory(user.id)
    .subscribe(res=>{
      console.log(res);
      alert('Se ha eliminado con exit..');
      location. reload(true);
    },
    err=>console.log(err));
  }

}
