<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-album" titleHeading='Renovar licencia'></app-page-title>
                </div>                    
            </div>
            <div class="row ml-0 mr-0">
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="col-xl-12 p-5">
                    <div class="form-row">
                        <!-- licence_type_id -->
                        <div class="form-group col-6">
                            <label>Tipo de licencia</label>
                            <select class="form-control" formControlName="licence_type_id" [ngClass]="{ 'is-invalid': submitted && f.licence_type_id.errors }">
                            <option value="" id="">Selecionar</option>
                            <option [value]="typeOfLicense.id" *ngFor="let typeOfLicense of typeOfLicenses">{{typeOfLicense.name}}</option>
                            </select>
                            <div *ngIf="submitted && f.licence_type_id.errors" class="invalid-feedback">
                                <div *ngIf="f.licence_type_id.errors.required">Esta campo esta vacio</div>
                            </div>
                        </div>
                        <!--startLicense-->
                        <div class="form-group col-6">
                            <label>Fecha Inicio</label>
                            <input #dp="bsDatepicker" formControlName="startLicense" [bsConfig]="{ containerClass: 'theme-second', isAnimated: false, dateInputFormat: 'DD-MM-YYYY' }"
                            bsDatepicker class="form-control" placeholder="Seleccione fecha"  [ngClass]="{ 'is-invalid': submitted && f.startLicense.errors }"  type="text">
                            <div *ngIf="submitted && f.startLicense.errors" class="invalid-feedback">
                                <div *ngIf="f.startLicense.errors.required">Esta campo esta vacio</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group form-check">
                        <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />
                        <label for="acceptTerms" class="form-check-label">Accept Terms & Conditions</label>
                        <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Accept Ts & Cs is required</div>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-primary mr-1">Renovar</button>
                        <button class="btn btn-secondary" type="reset" (click)="onReset()">Volver</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
