import { Component, OnInit } from '@angular/core';
import {CategoriesService} from '../../services/categories.service'
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-activities-categories',
  templateUrl: './activities-categories.component.html',
  styleUrls: ['./activities-categories.component.scss']
})
export class ActivitiesCategoriesComponent implements OnInit {

  Categories:{};
  Activities:{};
  idActivity:string;
  dataActivities :{};
  total : any[];

  constructor(private router : Router,private activRouter: ActivatedRoute, private servicesCategory : CategoriesService) { 
  
    this.activRouter.params.subscribe(res =>{
      this.idActivity = res['id'];
    })
  }

  ngOnInit() {
    
    this.servicesCategory.getActivities(this.idActivity)
      .subscribe((res: any[]) => {
      this.dataActivities = res;
      this.total = res;
      console.log(res)
      },
      err => console.log(err)
    )
  }

  hayRegistros() {
    return this.total.length>0;              
  }
  
  Delete(activity){
    this.servicesCategory.deletactivityRute(activity.category_activity_id)
    .subscribe(res=>{
      console.log(res);
      alert('Se ha retirado con exito la actividad de la ruta.');
      location. reload(true);
    },
    err=>console.log(err));
  }
}

