<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-users" titleHeading='Usuarios'></app-page-title>
                </div>
                <div class="card-header--actions">
                    <a routerLink="user-new" class="btn btn-sm btn-primary" tooltip="Crear usuario">
                        <fa-icon [icon]="['fas', 'plus']"></fa-icon>  Crear usuario
                    </a>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                    <table class="table table-hover table-striped mb-0">
                        <thead class="thead-light">
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                <th>Email</th>
                                <th>Teléfono</th>
                                <th class="text-center">Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of Users;">
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-icon-wrapper mr-2">
                                            <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/>
                                            </div>
                                        </div>                                    
                                    </div>
                                </td>
                                <td>
                                    <span class="font-weight-bold text-black">{{user.firstname}}&nbsp;{{user.lastname}}</span>
                                </td>
                                <td>
                                    <span>{{user.email}}</span>
                                </td>
                                <td>
                                    <span>{{user.phone}}</span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group btn-group-sm" dropdown placement="bottom right" container="body">
                                        <button class="btn btn-sm px-2 py-0 dropdown-toggle no-caret btn-primary"
                                                dropdownToggle type="button">
                                            <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
                                        </button>
                                        <div *dropdownMenu class="dropdown-menu dropdown-menu-xl p-0 dropdown-menu-right">
                                            <ul class="nav nav-neutral-primary nav-pills flex-column pt-2 pb-3">
                                                <li class="nav-item nav-item--header px-3">
                                                    <span class="text-capitalize text-black font-size-md font-weight-bold">Mis acciones</span>
                                                </li>
                                                <li class="dropdown-divider"></li>
                                                <li class="nav-item px-3">
                                                    <a [routerLink]="['user-edit', user.id]" class="nav-link active">
                                                        <div class="nav-link-icon">
                                                            <!--<i class="ion-ios-user-edit font-size-xxl"></i>-->
                                                            <fa-icon [icon]="['far', 'user-circle']"></fa-icon>
                                                        </div>
                                                        <span>Editar</span>
                                                    </a>
                                                </li>
                                                <li class="dropdown-divider"></li>
                                                <li class="nav-item">
                                                    <a type="submit" (click)="Delet(user)" class="nav-link text-danger mx-3">
                                                        <div class="nav-link-icon">
                                                            <fa-icon [icon]="['fas', 'times']"></fa-icon>
                                                        </div>
                                                        <span>Inactivar</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="divider"></div>
                <div class="divider"></div>
                <div class="p-3 d-flex justify-content-center">
                    <pagination [rotate]="rotate" [totalItems]="30"
                                class="pagination-icons pagination-primary"></pagination>
                </div>
            </div>
        </div>
    </div>
</div>

