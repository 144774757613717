import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators } from '@angular/forms'
import {ClientService} from '../../services/client.service';
import {CategoriesService} from '../../services/categories.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common'



@Component({
  selector: 'app-new-categories',
  templateUrl: './new-categories.component.html'
})
export class NewCategoriesComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  customers:{};
  selectedFile:File = null;
  selectedFiles:{};
  img:any;

  constructor(private  location: Location,private router : Router ,private servCategory :  CategoriesService, private servClient : ClientService, private formBuilder : FormBuilder) { }


  ngOnInit() {

    this.servClient.getClients()
      .subscribe(res=>{
        this.customers = res
        console.log(res)
    })

    this.registerForm = this.formBuilder.group({
        url_image: ['', Validators.required], 
        name: ['', Validators.required],
        description: ['', Validators.required],
        client_id: ['', Validators.required]
    });
}


  //Enviar por POST los datos del usuario
  postCategoriesMain(dataCategorie: {} , file){
    console.log('Datos', dataCategorie);
    this.servCategory.postCategory(dataCategorie , file)
    .subscribe(res => {
      console.log(res)
    })
  }


  get f() {return this.registerForm.controls;}

  onFileSelected(files: FileList){
    this.selectedFile = files.item(0);
  }

  onSubmit() {
    this.submitted = true;

    //deténgase aquí si el formulario no es válido
    if (this.registerForm.invalid) {
        return; 
    }

    var formData = new FormData();
    //formData.append('url_image', this.selectedFile, this.selectedFile.name);
    formData.append("name", this.registerForm.value.name);
    formData.append("description", this.registerForm.value.description);
    formData.append("client_id", this.registerForm.value.client_id);
    formData.append("url_image", this.selectedFile, this.registerForm.value.url_image);
    console.log('file contents', this.selectedFile);
    console.log('name',this.registerForm.value.name);
    console.log('description',this.registerForm.value.description);
    console.log('client_id',this.registerForm.value.client_id);
    console.log('url_image',this.registerForm.value.url_image);
    console.log(this.selectedFile.name);
    this.postCategoriesMain(formData, formData)
    this.location.back();
    //console.log(this.registerForm.value, this.selectedFile);    
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.location.back();
  }
}

