<div class="row">
    <div class="col-xl-12">
        <div class="card card-box mb-5">
            <div class="card-header pr-2">
                <div class="card-header--title">
                    <app-page-title iIconDescription="pe-7s-paper-plane" titleHeading='Asignar actividad a ruta'></app-page-title>
                </div>                    
            </div>
            <div class="row ml-0 mr-0">
                <!--ruta-->
                <div class="form-group col-12 pl-5 pt-5 pr-5">
                    <label>Ruta</label>
                    <select class="form-control" #ruteSelect (change)='ruteSelected(ruteSelect.value)'>
                        <option value="" selected>Seleccione ruta</option>
                        <option [value]="rute.id" *ngFor="let rute of categories">{{rute.name}}</option>
                    </select>
                </div>
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="pl-5 pr-5 pb-5 col-xl-12">
                    <div class="form-row mb-4">
                        
                        <!--pilar-->
                        <div class="form-group col-6">
                            <label>Pilar</label>
                            <select class="form-control" formControlName="pilar_id" [ngClass]="{ 'is-invalid': submitted && f.pilar_id.errors }">
                                <option value="" selected>Seleccione pilar</option>
                                <option value="1" >Aprende</option>
                                <option value="2" >Aplica</option>
                                <option value="3" >Comparte</option>
                            </select>
                            <div *ngIf="submitted && f.pilar_id.errors" class="invalid-feedback">
                                <div *ngIf="f.pilar_id.errors.required">Esta campo esta vacio</div>
                            </div>
                        </div>
                            
                        <!--module-->
                        <div class="form-group col-6">
                            <label>Módulo</label>
                            <select class="form-control" formControlName="module_id" [ngClass]="{ 'is-invalid': submitted && f.module_id.errors }">
                                <option value="" selected>Seleccione módulo</option>
                                <option [value]="module.id" *ngFor="let module of dataModules">{{module.name}}</option>
                            </select>
                            <div *ngIf="submitted && f.module_id.errors" class="invalid-feedback">
                                <div *ngIf="f.module_id.errors.required">Esta campo esta vacio</div>
                            </div>
                        </div> 
                        
                        <!--id actividad-->                        
                        <div class="form-group col-6">
                            <input type="hidden" formControlName="activity_id" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.activity_id.errors }" />
                            <div *ngIf="submitted && f.activity_id.errors" class="invalid-feedback">
                                <div *ngIf="f.activity_id.errors.required">Este campo esta vacio</div>
                            </div>
                        </div>

                    </div>
                    <div class="text-center mt-6">
                        <button class="btn btn-primary mr-1">Asignar</button>
                        <button class="btn btn-secondary" type="reset" (click)="onReset()">Volver</button>
                    </div>
                </form>                   
            </div>
        </div>
    </div>
</div>
