import { Injectable } from '@angular/core';

interface MenuItem {
    title: string;
    type: string;
    badge?: {
        class: string;
        text: string;
    };
    link?: string;
    active?: boolean;
    icon?: string;
    submenus?: MenuItem[];
}

@Injectable({
    providedIn: 'root'
})
export class SidebarMenuService {
    menus: MenuItem[] = [
  {
    "title": "Menu",
    "type": "header"
  },
  {
    "title": "Clientes",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-portfolio\"> </i>",
    "submenus": [
      {
        "title": "Ver clientes",
        "type": "simple",
        "link": "/home-default/list"
      },
    ]
  },
  {
    "title": "Usuarios",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-users\"> </i>",
    "submenus": [
      {
        "title": "Ver usuarios",
        "type": "simple",
        "link": "/home-default/user-list"
      },
    ]
  },  
  {
    "title": "Rutas",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-way\"></i>",
    "badge": {
      "class": "badge badge-success",
      "text": "12"
    },
    "submenus": [
      {
        "title": "Ver rutas",
        "type": "simple",
        "link": "/home-default/category"
      }
    ]
  },
  {
    "title": "Banco de actividades",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-box1\"></i>",    
    "submenus": [
      {
        "title": "Ver listado de actividades",
        "type": "simple",
        "link": "/home-default/banco-actividades"
      }
    ]
  },
  {
    "title": "Bancos de preguntas",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-box1\"></i>",    
    "submenus": [
      {
        "title": "Ver listado de bancos",
        "type": "simple",
        "link": "/home-default/bank"
      }
    ]
  }
  ,
  {
    "title": "Reportes",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-box1\"></i>",    
    "submenus": [
      {
        "title": "Dashboard",
        "type": "simple",
        "link": "/home-default/dashboard"
      }
    ]
  }
  
];

    constructor() { }

    getMenuList() {
        return this.menus;
    }

    getMenuItemByUrl(aMenus: MenuItem[], aUrl: string): MenuItem {
        for (const theMenu of aMenus) {
            if (theMenu.link && theMenu.link === aUrl) {
                return theMenu;
            }

            if (theMenu.submenus && theMenu.submenus.length > 0) {
                const foundItem = this.getMenuItemByUrl(theMenu.submenus, aUrl);
                if (foundItem) {
                    return foundItem;
                }
            }
        }

        return undefined;
    }

    toggleMenuItem(aMenus: MenuItem[], aCurrentMenu: MenuItem): MenuItem[] {
        return aMenus.map((aMenu: MenuItem) => {
            if (aMenu === aCurrentMenu) {
                aMenu.active = !aMenu.active;
            } else {
                aMenu.active = false;
            }

            if (aMenu.submenus && aMenu.submenus.length > 0) {
                aMenu.submenus = this.toggleMenuItem(aMenu.submenus, aCurrentMenu);

                if (aMenu.submenus.find(aChild => aChild.active)) {
                    aMenu.active = true;
                }
            }

            return aMenu;
        });
    }
}
