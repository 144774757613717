import { Component, OnInit } from '@angular/core';
import {UserService} from '../../app/content/components/services/user.service';
import {DashboardService} from '../../app/content/components/services/dashboard.service';
import {ClientService} from '../../app/content/components/services/client.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';


import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid,
  ApexNonAxisChartSeries,
  ApexResponsive,  
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStates,
  ApexFill,
  
} from "ng-apexcharts";
import { createDate } from 'ngx-bootstrap/chronos/create/date-from-array';
import { ButtonsModule } from 'ngx-bootstrap';

export type ChartOptions_bar = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  title: ApexTitleSubtitle;
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};

export type ChartU = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};

export type ChartUs = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  stroke: ApexStroke;
};

export type ChartOptions_2 = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};
export interface rankingUsers {
  puntos: string;
  name: string;
  
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  
  items = [];
  pageOfItems: Array<any>;
  public chartOptions: Partial<ChartOptions>;
  public chartU: Partial<ChartU>;
  public chartUs: Partial<ChartUs>;
  public chartOptions_2: Partial<ChartOptions_2>;
  
  public chartOptions_bar: Partial<ChartOptions_bar>;
  public listaUsers:any;
  public rankingUsers:any;
  public name:any;
  public data:any;
  public repetidos:Number;
  public userState:any;
  public userFinish:any;
  public UserCreated:any;
  public usuarios:any;
  public innerText:any;
  
  constructor(private _users :UserService, private _rankigUser :DashboardService, private _userState :DashboardService,private _userfinish :DashboardService,
    private _UserCreated :DashboardService ) {
  //const filterpost = '';
  //this.userState.status ='1';
   }
   

   filterPost = '';
  ngOnInit() {
    

    this._users.getCostumer()
    .subscribe(
      res => {
        this.listaUsers = res;
        const resultado= this.listaUsers;
        console.log(resultado)
      },
      err => console.log(err)
    )

    //Consultas de Usuarios general o creacion  
    this._UserCreated.getUserCreated()
    .subscribe(
      res => {
        this.UserCreated = res;
        
        const usuario=this.UserCreated.map(UserCreated => UserCreated.id);
        console.log(usuario)
        const resultado= usuario.length;
        console.log(resultado)
        this.chartUs = {
          series: [resultado],
          
          chart: {
            height:240,
            type: "radialBar",
           // toolbar: {
          //    show: true
          //  }
          },
          plotOptions: {
            radialBar: {
              startAngle: 0,
              endAngle: 480,
              hollow: {
                margin: 0,
                size: "66%",
                background: "#fff",
                image: undefined,
                position: "front",
              
              },
              track: {
                background: "#fff",
                strokeWidth: "67%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.35
                }
              },
    
              dataLabels: {
                //show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: "#888",
                  fontSize: "16px"
                },
                value: {
                  formatter: function(val) {
                    return parseInt(val.toString(), 10).toString();
                  },
                  color: "#111",
                  fontSize: "35px",
                  show: true
                }
              }
            }
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: ["#ABE5A1"],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          
          labels: ["Usuarios"]
          
        };

      },
      err => console.log(err)
      
    
    )
   
    //Ranking de usuarios
    this._rankigUser.getRankingUser()
    .subscribe(
      res =>  {
        this.rankingUsers = res;
          const nombres= this.rankingUsers.map(rankingUsers => rankingUsers.name);
          const valores= this.rankingUsers.map(rankingUsers => rankingUsers.puntos);
          console.log(nombres);
          console.log(valores);
          console.log(this.rankingUsers)
        this.chartOptions = {
          series: [
            {
              name: "Desktops",
              //data: [10, 41, 35, 51, 49, 62, 69, 91, 148,158]
              data:valores
            }
          ],
          chart: {
            height: 350,
            type: "line",
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: "straight"
          },
          title: {
            text: "Ranking de usuarios en la plataforma",
            align: "center"
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5
            }
          },
          xaxis: {
            categories: nombres,
          }
        };
    
        this.chartOptions_bar = {
          series: [
            {
              name: "Ranking de usuarios en la plataforma",
             // data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
             data:valores
            }
          ],
          chart: {
            height: 350,
            type: "bar"
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: "buttom" // top, center, bottom
              }
            }
          },
          dataLabels: {
            enabled: true,
            //formatter: function(val) {
             // return val + "%";
            //},
            offsetY: -20,
            style: {
              fontSize: "12px",
              colors: ["#304758"]
            }
          },
    
          xaxis: {
            categories: nombres,
            position: "buttom",
            
            labels: {
              offsetY: 0
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: "gradient",
                gradient: {
                  colorFrom: "#D8E3F0",
                  colorTo: "#BED1E6",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5
                }
              }
            },
            tooltip: {
              enabled: true,
              offsetY: -35
            }
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "horizontal",
              shadeIntensity: 0.25,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [50, 0, 100, 100]
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            labels: {
             // show: false,
              //formatter: function(val) {
              //  return val + "%";
              //}
            }
          },
          title: {
            text: "Tercera grafica de muestra Ennlace",
            floating: 0,
            offsetY: 320,
            align: "center",
            style: {
              color: "#444"
            }
          }
        };
      }
    )
    //Total de usuarios por rutas terminadas 
    this._userfinish.getUserFinish()
    .subscribe(
      res =>  {
        this.userFinish = res;
          const nombresf= this.userFinish.map(userFinish => userFinish.name);
          const result = nombresf.reduce((acc,item)=>{
            if(!acc.includes(item)){
              acc.push(item);
            }
            return acc;
          },[])
        const valoresf= this.userFinish.map(userFinish => userFinish.category_id);
        var repetidos = [];
          valoresf.forEach(function(numero){
            repetidos[numero] = (repetidos[numero] || 0) + 1;
          });
          var usuariosRepetidos =[];
            repetidos.forEach(function(numero){
              usuariosRepetidos.push(numero)
            });
            console.log(usuariosRepetidos);
            console.log(nombresf);
            console.log(valoresf);
            console.log(result);
            console.log(this.userFinish)
        this.chartOptions_2 = {
          //series: [2,3,1,5],
          series: usuariosRepetidos,
          chart: {
            width: 450,
            type: "pie"
            
          },
          labels: result,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        };

    }
    )

    
    //Estado de rutas de usuario
    this._userState.getUserState()
    .subscribe(
      res =>  {
        this.userState = res;
        //this.userState.status'Por iniciar';
        var estado = 'Por Iniciar';
        var valueestado = 0;
        var estado2 = 'En Curso';
        var valueestado2 = 1;
        var estado3 = 'Finalizado';
        var valueestado3 = 2;
        this.userState.map(function(dato){
          if(dato.status == valueestado){
            dato.status = estado;
          }
          if(dato.status == valueestado2){
            dato.status = estado2;
          }
          if(dato.status == valueestado3){
            dato.status = estado3;
          }
          return dato;
        });
        var  count = {};
        const statusn= this.userState.map(userState => userState.status);
        statusn.forEach(function(i) { count[i] = (count[i]||0) + 1;});
        console.log(count);
        console.log(this.userState)
        
        


      }
      

      
     
    )

  }
  downloadExcel() {
    //create new excel work book
    let workbook = new Workbook();
    //add name to sheet
    let worksheet = workbook.addWorksheet("Employee Data");
    //add column name
    let header=["Category_id","id_ruta","Estado de ruta","id","apellido","Nombre","Email","Nombre de la Ruta"]
    let headerRow = worksheet.addRow(header);
    for (let x1 of this.userState)
    {
      let x2=Object.keys(x1);
      let temp=[]
      for(let y of x2)
      {
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }let fname="Estado-de-usuarios"

    //add data and file name and download

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname+'-'+new Date().valueOf()+'.xlsx');
      });

  }

  

}

