import {Component} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoriesService} from '../../services/categories.service'
import { Location } from '@angular/common'


@Component({
  selector: 'app-activities-route-categories',
  templateUrl: './activities-route-categories.component.html',
  styleUrls: ['./activities-route-categories.component.scss']
})
export class ActivitiesRouteCategoriesComponent{
  

  Categories:{};
  Activities:{};
  activity:any;
  getActivit:{};
  idActivity:string;
  dataActivities :{};
  dataList :any;
  exampleModal:any; 
  notice:Boolean = false;
  warning:Boolean = false;
  orderActivity:any; 

  constructor(private router : Router,private  location: Location,private activRouter: ActivatedRoute, private servicesCategory : CategoriesService) { 
  
    this.activRouter.params.subscribe(res =>{
      this.idActivity = res['id'];
      
    })
  }

  ngOnInit() {
    
    
    this.servicesCategory.getActivities(this.idActivity)
      .subscribe(res => {
      this.dataActivities = res;
      var resultEdit = res
        resultEdit.forEach(getActivit => {          
          console.log('consuu',getActivit)
          this.activity = getActivit;
      }); 
      console.log(res)
      this.editRoute(res)
      this.dataList = this.dataActivities;
      console.log('tot',this.dataList.length);      
      },
      err => console.log(err)
    )
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dataList, event.previousIndex, event.currentIndex);    
    this.notice = false;
    this.warning = false;
    let orden: any = [];
    var numbers = this.dataList;
    var formData = new FormData();
    for (var _i = 0; _i < numbers.length; _i++) {
        var num = this.dataList[_i].category_activity_id;        
        var act: string ="activity["+_i+"]";     
        orden.push(num);
        formData.append(act, orden[_i]);        
    }
    this.postRegisterActivity(formData) 
    console.log('Orden',orden[0]); 
    console.log('camy',formData.getAll('activity[3]'))
  }
  postRegisterActivity(Activity:{}){ 
    console.log('Dte',Activity)
    this.servicesCategory.postOrderActivity(Activity)    
    .subscribe(
      res => {
        this.notice = true;
        console.log('OK',res)
      },
      err => this.warning = true,
    )
  }
  editRoute(bancoActividadesEdit:any){
    console.log('cam',bancoActividadesEdit); 
  }
  onReset() {
    this.location.back();
  }    
}

