<div class="app-sidebar--userbox">
    <div class="card-tr-actions">
        <div class="btn-group card-tr-actions" dropdown placement="bottom right">
            <button class="btn btn-link p-0 border-0 text-white-50 dropdown-toggle no-caret"
                    dropdownToggle
                    type="button">
                <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
            </button>
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right text-nowrap overflow-hidden px-2 pt-2 pb-0">
                <ul class="list-group list-group-flush text-left bg-transparent">
                    <li class="list-group-item rounded-top p-2">
                        <div class="align-box-row align-items-center">
                            <div class="avatar-icon-wrapper avatar-icon-md">
                                <div class="avatar-icon rounded-circle"><img
                                        alt="..."
                                        src="assets/images/avatars/avatar2.jpg"/></div>
                            </div>
                            <div class="pl-3">
                                <span class="pb-1 d-block">Emma Taylor</span>
                                <span class="badge badge-success">Active</span>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item bg-transparent d-flex justify-content-center">
                        <div class="d-flex py-2 align-items-center">
                            <div class="pr-3">
                                <fa-icon [icon]="['far', 'user']" class="font-size-xxl text-success"></fa-icon>
                            </div>
                            <div class="line-height-sm">
                                <b class="font-size-lg">14,596</b>
                                <span class="text-black-50 d-block">reports</span>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item rounded-bottom p-3 text-center">
                        <a [routerLink]="" class="btn btn-sm mx-2 btn-facebook" tooltip="Facebook">
                            <span class="btn-wrapper--icon">
                                <fa-icon [icon]="['fab', 'facebook']"></fa-icon>
                            </span>
                        </a>
                        <a [routerLink]="" class="btn btn-sm mx-2 btn-twitter" tooltip="Twitter">
                            <span class="btn-wrapper--icon">
                                <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="avatar-icon-wrapper avatar-icon-md">
        <span class="badge badge-circle badge-danger">Offline</span>
        <div class="avatar-icon rounded-circle"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
    </div>
    <div class="my-3 userbox-details">
        Emma Taylor
        <small class="d-block text-white-50">(emma.taylor@uifort.com)</small>
    </div>
    <a class="btn btn-sm btn-userbox" routerLink="/pages-profile">View profile</a>
</div>
