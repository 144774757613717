<div class="card card-box mb-5">
    <div class="card-header">
        <div class="card-header--title font-size-md font-weight-bold py-2">
            {{sectionHeading}}
        </div>
    </div>
    <div class="card-body">
        <ng-content></ng-content>
    </div>
</div>
