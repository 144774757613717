import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../components/services/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { Location } from '@angular/common'
import {MustMatch} from './../../../../shared/method/password.validator'
import {ClientService} from '../../services/client.service';
import {ActivatedRoute, Router} from '@angular/router';

import {pipeService} from '../../services/pipe.service';

@Component({
  selector: 'app-enrollment-new-user',
  templateUrl: './enrollment-new-user.component.html',
  styleUrls: ['./enrollment-new-user.component.scss']
})
export class EnrollmentNewUserComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  customers: {};
  dataTypeDocument :{};

  constructor(private router : Router,private servClient : ClientService ,private formBuilder : FormBuilder, private getServicesUser :  UserService , 
  private servPipe : pipeService,private  location: Location) { }

  ngOnInit() {
    //licencias de cliente
    this.servClient.getClients()
    .subscribe(res =>{
      this.customers = res,
      console.log(res)
    })

    //servicio de pipe typeDocument
    this.servPipe.getDocumentType()
    .subscribe(res=>{
      this.dataTypeDocument = res,
      console.log(res)
    })
  }
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

  //deténgase aquí si el formulario no es válido
  if (this.registerForm.invalid) {
      return;
  }


  
  console.log(this.registerForm.value)
  }
  onReset() {
    this.submitted = false;
    this.location.back();
    //this.router.navigate(['/home-default']);
  }

}
