import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import {environment} from '../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  baseUrl = environment.apiURL;
  urlRankingUser : string;
  urlUserCreated: string;
  urlUserState: string;
  urlUserFinish: string;

  constructor(private httpClient : HttpClient) {
    this.urlRankingUser = `${this.baseUrl}/api/rankinguser`;
    this.urlUserCreated = `${this.baseUrl}/api/usercreated`;
    this.urlUserState = `${this.baseUrl}/api/userstate`;
    this.urlUserFinish = `${this.baseUrl}/api/userfinish`;

  }

  getRankingUser(){
    return this.httpClient.get(`${this.urlRankingUser}`)
  }
  getUserCreated (){
    return this.httpClient.get(`${this.urlUserCreated}`)
  }
  getUserState(){
    return this.httpClient.get(`${this.urlUserState}`)
  }
  getUserFinish(){
    return this.httpClient.get(`${this.urlUserFinish}`)
  }

}
