import { Component } from '@angular/core';
import {AuthService} from '../../content/auth/services/auth.service'

@Component({
  selector: 'app-header-userbox',
  templateUrl: './header-userbox.component.html'
})
export class HeaderUserboxComponent {private auth : AuthService}


