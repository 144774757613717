import { Component, OnInit } from '@angular/core';
import {ActivitiesService} from '../../services/banco-activities.service'
import { Location } from '@angular/common'

@Component({
  selector: 'app-activities-evaluate-list',
  templateUrl: './activities-evaluate-list.component.html',
  styleUrls: ['./activities-evaluate-list.component.scss']
})
export class ActivitiesEvaluateListComponent implements OnInit {

  Activities:{};
  total : any[];

  constructor(private servicesActivity : ActivitiesService,private location : Location) { }

  ngOnInit() {
    this.servicesActivity.getActivities()
    .subscribe((res: any[]) =>{
      this.Activities = res,
      this.total = res; 
      console.log(res);
    })
  }

  hayRegistros() {
    return this.total.length>0;              
  } 

  Delet(activity){
    this.servicesActivity.deletActivity(activity.id)
    .subscribe(res=>{
      console.log(res);
      alert('Se ha eliminado con exito');
      location. reload(true);
    },
    err=>console.log(err));
  }
  onReset() {
    this.location.back();
  }
}
