import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {enrollService} from '../../../services/enroll.service';
import {userCategory} from '../../../models/enroll.model'

@Component({
  selector: 'app-user-new-category',
  templateUrl: './user-new-category.component.html'
})
export class UserNewCategoryComponent implements OnInit {
  idCategory:string;
  dataUser : {};

  

  constructor( private actvRouter : ActivatedRoute , private userServ : UserService, private servEnroll:enrollService) { 
    this.actvRouter.params.subscribe(res =>{ this.idCategory = res['id']})
    console.log(this.idCategory);
  }

  ngOnInit() {
    this.userServ.getCostumer()
    .subscribe(res=>{
      this.dataUser = res,
      console.log(res)
    })
  }

  enrollModule( id ){

    let iduser ={
      user_id: id
    }
    
    this.servEnroll.postEnrollModule( iduser, this.idCategory )
    .subscribe(res=> {
      console.log(res)
      location. reload(true);
    },
    err=> console.log(err)
    )}

}
