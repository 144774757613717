import { Component, OnInit } from '@angular/core';
import {CategoriesService} from '../../services/categories.service'
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-module-categories',
  templateUrl: './module-categories.component.html',
  styleUrls: ['./module-categories.component.scss']
})
export class ModuleCategoriesComponent implements OnInit {

  Categories:{};
  idRuta:string;
  dataModules :{};
  total : any[];

  constructor( private router : Router,private activRouter: ActivatedRoute, private servicesCategory : CategoriesService) { 

    this.activRouter.params.subscribe(res =>{
      this.idRuta = res['id'];
    })
  }

  ngOnInit() {
    this.servicesCategory.getModules(this.idRuta)
      .subscribe((res: any[]) => {
      this.dataModules = res;
      this.total = res;      
      },
      err => console.log(err)
    )
  }

  hayRegistros() {
    return this.total.length>0;              
  }  

  Delet(module){
    this.servicesCategory.deletModule(module.id) 
    .subscribe(res=>{
      console.log(res);
      alert('Se ha eliminado con exito.');
      location. reload(true);
    },
    err=>console.log(err));
  }
}
